import cn from 'classnames'
import React from 'react'
// import Icon from '../Icon'
import styles from "./SuccessModal.module.sass"

const SuccessModal = ({ click, text }) => {
    return (
        <>
            <hr />
            <h5 className='lh-base'>{text}</h5>
            <button className={cn("button-stroke-red mt-4", styles.button)} onClick={click}>
                <span>OK</span>
            </button>
        </>


    )
}

export default SuccessModal