import React, { createContext, useEffect, useState } from "react";
import cn from "classnames";
import Dropdown from "../../../../Dropdown";
import TextInput from "../../../../TextInput";
import styles from "./Form.module.sass";
import TooltipGlobal from "../../../../TooltipGlobal";

// import images
import plus from "../../ActionArea/icons/plus-icon.svg";
import classNames from "classnames";
import { toast } from "react-hot-toast";
import {
  validateEmail,
  validateIsNumeric,
  validatePassword,
  validatePhoneNumber,
} from "../../../../../Utils/Validations";
import {
  createAccountActoinSaga,
  createUserAccountActoinSaga,
  getAccountsListActoinSaga,
  editAdminUserActoinSaga,
  editUserActoinSaga,
} from "../../../../../store/actions/PanActions";
import { useDispatch } from "react-redux";
import Loader from "../../../../loader/Loader";
import moment from "moment";
import { State, City } from "country-state-city"
import SearchableDropdown from "../../../../SearchableDropdown";
import Roles from "../../../../../Constants/Roles";

const statusTypes = [
  {
    statustype: "active",
    id: 1,
  },
  {
    statustype: "Inactive",
    id: 2,
  },
];

const accountTypes = [
  {
    acctype: "Txn Using Api",
    id: 1,
  },
  {
    acctype: "Txn Using Portal",
    id: 2,
  },
];

// const validityOption = [
//   { value: 6, label: "6 Months" },
//   { value: 12, label: "12 Months" },
// ];

const Form = ({ data, isEdit, onClose, getList }) => {
  const dispatch = useDispatch();
  const role = sessionStorage.getItem("role");
  const [indStates, setIndStates] = useState([]);
  const [indCities, setIndCities] = useState([]);
  const [sorting, setSorting] = useState({ value: "", label: "" });
  const [sortingUser, setSortingUser] = useState({ value: "", label: "" });
  const [optionList, setOptionList] = useState([]);
  const [optionListUser, setOptionListUser] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const countryCode = "IN"

  const [editUserInput, setEdituserInput] = useState(() => {
    if (role === Roles.SA) {
      const { account_id, admin_id } = data;
      return { account_id, admin_id };
    }
    if (role === Roles.AD) {
      const { user_id } = data;
      return { user_id };
    }
  });
  // console.log(editUserInput, "editUserInput");
  //Check if all required fields are filled
  const validateForm = () => {
    if (!isEdit) {
      switch (role) {
        case "SuperAdmin":
          const adminRequiredFields = [
            "userphone",
            "address",
            "city",
            "state",
            // "country",
            "pincode",
            "accname",
            // "usercredits",
          ];

          const isAllRequiredFieldsFilled = adminRequiredFields.every(
            (field) => createAcc[field] && createAcc[field].trim()
          );
          setIsFormValid(isAllRequiredFieldsFilled);
        case "Admin":
          const userRequiredFields = [
            "userphone",
            "address",
            "city",
            "state",
            // "country",
            "pincode",
            // "usercredits",
          ];

          const isAllRequiredFieldsFilledUser = userRequiredFields.every(
            (field) => createAcc[field] && createAcc[field].trim()
          );
          setIsFormValid(isAllRequiredFieldsFilledUser);
        default:
          return null;
      }
    }
  };

  // DROPDOWN FUNC
  useEffect(() => {
    getUserDropdownList();
  }, []);

  //TO CHECK ISEDIT STATE
  useEffect(() => {
    if (isEdit) {
      setIsFormValid(true);
    }
  }, [isEdit]);

  const getUserDropdownList = () => {
    let ind = State.getStatesOfCountry(countryCode)
    setIndStates(ind)
    let optionListData =
      accountTypes?.map((item) => ({
        value: item?.id,
        label: item?.acctype,
      })) || [];
    let userActiveListData =
      statusTypes?.map((item) => ({
        value: item?.id,
        label: item?.statustype,
      })) || [];
    let singleOptionAccType;
    if (data.account_type) {
      singleOptionAccType = optionListData[data.account_type - 1];
    } else {
      singleOptionAccType = optionListData[0];
    }

    let singleOptionStatusType = userActiveListData[0]
    setSorting(singleOptionAccType);
    setOptionList(optionListData);
    setSortingUser(singleOptionStatusType);
    setOptionListUser(userActiveListData);
    setCreateAcc((prev) => ({
      ...prev,
      acctype: singleOptionAccType.value,
      userstatus: singleOptionStatusType.value,
      // creditValidity: singleOptionValidityType.value,
    }));
  };

  const [createAcc, setCreateAcc] = useState(() => {
    switch (role) {
      case "SuperAdmin":
        return {
          usercredits: 0,
          userstatus: sortingUser.value || "",
          acctype: sorting.value,
          username: data?.admin_name,
          useremail: data?.admin_email,
          userphone: data?.admin_phone,
          address: data?.address,
          state: data?.state,
          city: data?.city,
          // country: data?.country,
          pincode: data?.pincode,
          accname: data?.account_name,
          uservalidity: data?.uservalidity,

          // creditValidity: sortValidity.value || "",
        };
      case "Admin":
        return {
          userstatus: sortingUser.value || "",
          username: data?.user_name,
          useremail: data?.user_email,
          userphone: data?.user_phone,
          address: data?.address,
          state: data?.state,
          city: data?.city,
          // country: data?.country,
          pincode: data?.pincode,
          uservalidity: data?.uservalidity,
        };
      default:
        return null;
    }
  });
  const [createAccErr, setCreateAccErr] = useState(() => {
    switch (role) {
      case "SuperAdmin":
        return {
          usercredits: 0,
          userstatus: sortingUser.value,
          acctype: sorting.value,
          // creditValidity: sortValidity.value,
        };
      case "Admin":
        return {
          userstatus: sortingUser.value,
        };
      default:
        return null;
    }
  });

  function epoch(date) {
    return Date.parse(date);
  }

  const handleChangeAddAccount = (e) => {
    const { value, name } = e.target;
    const datetime = moment().format("YYYY-MM-DD");
    const timestamp = epoch(datetime);
    if (isEdit) {
      setEdituserInput((prev) => ({ ...prev, [name]: value }));
    }
    if (name === "usercredits") {
      setCreateAcc((prevState) => ({ ...prevState, [name]: parseInt(value) }));
    } else if (name === "useremail") {
      const err = validateEmail(value);
      setCreateAccErr((prevState) => ({ ...prevState, [name]: err }));
      setCreateAcc((prevState) => ({ ...prevState, [name]: value }));
      return;
    } else if (name === "userphone") {
      const mobileErr = validatePhoneNumber(value);
      setCreateAccErr((prevState) => ({ ...prevState, [name]: mobileErr }));
      setCreateAcc((prevState) => ({ ...prevState, [name]: value }));
      return;
    } else if (name === "uservalidity") {
      if (epoch(value) < timestamp) {
        const isError = "Past date should not be Selected";
        setCreateAccErr((prevState) => ({ ...prevState, [name]: isError }));
        setCreateAcc((prevState) => ({ ...prevState, [name]: value }));
      } else {
        setCreateAcc((prevState) => ({ ...prevState, [name]: value }));
        setCreateAccErr((prevState) => ({ ...prevState, [name]: "" }));
      }
    } else if (name === "state") {
      let modValue = JSON.parse(value);
      let selectedCities = City.getCitiesOfState(countryCode, modValue?.isoCode)
      setIndCities(selectedCities)
      setCreateAcc((prevState) => ({ ...prevState, [name]: modValue?.name }));
      setEdituserInput((prev) => ({ ...prev, [name]: modValue?.name }));
      return;
    }
    setCreateAcc((prevState) => ({ ...prevState, [name]: value }));
  };

  // DUMMY FUNCTION

  // DropDown Functionality
  //isAccountType = 0 -----> acctype
  //isAccountType = 1 -----> userstatus
  //isAccountType = 2 -----> validity
  const handleChangeStatusAcc = (data, isAccountType = false) => {
    const { value, label } = data;
    let obj = {
      [isAccountType ? "acctype" : "userstatus"]: data.value,
    };
    if (isEdit === true) {
      setEdituserInput((prev) => ({ ...prev, ...obj }));
    }
    isAccountType ? setSorting(data) : setSortingUser(data);
    setCreateAcc((prevState) => ({ ...prevState, ...obj }));
  };

  const handleSubmitAddAccount = () => {
    if (isEdit === false) {
      const payload = { ...createAcc };
      const stringInt = parseInt(payload.usercredits);
      const modelAdmin = {
        username: payload.username,
        useremail: payload.useremail,
        address: payload.address,
        city: payload.city,
        state: payload.state,
        pincode: payload.pincode,
        userphone: payload.userphone,
        accname: payload.accname,
        acctype: payload.acctype,
        userstatus: payload.userstatus,
        usercredits: stringInt,
        uservalidity: payload.uservalidity,
      };
      const modelUser = {
        username: payload.username,
        useremail: payload.useremail,
        address: payload.address,
        city: payload.city,
        state: payload.state,
        pincode: payload.pincode,
        userphone: payload.userphone,
        userstatus: payload.userstatus,
        usercredits: stringInt,
        uservalidity: payload.uservalidity,
      };
      let isTrue = Object.values(modelAdmin).some(
        (v) => v === "" || v === undefined || v === null
      );
      let isTrueUser = Object.values(modelUser).some(
        (v) => v === "" || v === undefined || v === null
      );
      let isErrTrue = Object.values(createAccErr).some(
        (v) => v === "" || v === undefined || v === null
      );
      if (
        role === Roles.SA ? isTrue || !isErrTrue : isTrueUser || !isErrTrue
      ) {
        toast.error("please check all the inputs");
      } else {
        switch (role) {
          case "SuperAdmin":
            return dispatch(
              createAccountActoinSaga({
                model: modelAdmin,
                callback: getcreateAccountData,
              })
            );
          case "Admin":
            return dispatch(
              createUserAccountActoinSaga({
                model: modelUser,
                callback: getcreateUserAccountData,
              })
            );
          default:
            return null;
        }
      }
    }
    if (isEdit === true) {
      let model = editUserInput;
      switch (role) {
        case "SuperAdmin":
          return dispatch(
            editAdminUserActoinSaga({
              model,
              callback: () => {
                getList();
                onClose();
              },
            })
          );
        case "Admin":
          return dispatch(
            editUserActoinSaga({
              model,
              callback: () => {
                getList();
                onClose();
              },
            })
          );
        default:
          return null;
      }
    }
  };

  const getcreateAccountData = (data) => {
    onClose();
    getList();
  };
  const getcreateUserAccountData = (data) => {
    onClose();
    getList();
  };

  return (
    <>
      <Loader />
      <div
        className={`${styles.container}${role === Roles.SA ? " " + styles.scroll : " " + styles.scrolladmin
          }`}
      >
        <div
          className={`${role === Roles.SA ? styles.row : styles.rowAdmin}`}
        >
          <div
            className={`${role === Roles.SA ? styles.col : styles.colAdmin
              }`}
          >
            <div className={styles.rowField}>
              <TextInput
                className={styles.field}
                label={
                  role === Roles.SA ? (
                    <>
                      Admin Name<span className={styles.asteriskRed}>*</span>
                    </>
                  ) : (
                    <>
                      User Name<span className={styles.asteriskRed}>*</span>
                    </>
                  )
                }
                name="username"
                placeholder={role === Roles.SA
                  ? "Enter Admin Name"
                  : "Enter User Name"}
                value={
                  role === Roles.SA
                    ? createAcc?.username
                    : createAcc?.username
                }
                type="text"
                // tooltip="This field is required"
                required
                onChange={(e) => {
                  handleChangeAddAccount(e);
                  validateForm();
                }}
              />
            </div>
            <div
              className={styles.rowField}
              style={{ opacity: `${isEdit && "0.5"}` }}
            >
              <TextInput
                className={styles.field}
                label={
                  role === Roles.SA ? (
                    <>
                      Admin Email<span className={styles.asteriskRed}>*</span>
                    </>
                  ) : (
                    <>
                      User Email<span className={styles.asteriskRed}>*</span>
                    </>
                  )
                }
                name="useremail"
                type="text"
                // tooltip="This field is required"
                placeholder="E-mail Address"

                value={
                  role === Roles.SA
                    ? createAcc?.useremail
                    : createAcc?.useremail
                }
                required
                disabled={isEdit && "true"}
                onChange={(e) => {
                  handleChangeAddAccount(e);
                  validateForm();
                }}
              // value={values.accountName || ""}
              />
              <p className={styles.createErr}>{createAccErr.useremail}</p>
            </div>
            {/* <div
                className={styles.rowField}
                style={{ opacity: `${isEdit && "0.5"}` }}
              >
                <TextInput
                  className={styles.field}
                  label={
                  type="text"
                  required
                  onChange={handleChangeAddAccount}
                  disabled={isEdit && "true"}
                  // value={values.accountName || ""}
                />
                <p className={styles.createErr}>{createAccErr.userpwd}</p>
              </div> */}
            <div className={styles.rowField}>
              <TextInput
                className={styles.field}
                label={
                  role === Roles.SA ? (
                    <>
                      Admin Phone<span className={styles.asteriskRed}>*</span>
                    </>
                  ) : (
                    <>
                      User Phone<span className={styles.asteriskRed}>*</span>
                    </>
                  )
                }
                name="userphone"
                type="text"
                // tooltip="This field is required"
                placeholder="Mobile Number"
                value={
                  role === Roles.SA
                    ? createAcc?.userphone
                    : createAcc?.userphone
                }
                maxlength={10}
                required
                // value={values.pinCode ? values.pinCode : ""}
                onChange={(e) => {
                  handleChangeAddAccount(e);
                  validateForm();
                }}
              />
              <p className={styles.createErr}>{createAccErr.userphone}</p>
            </div>
            <div className={styles.rowField}>
              <TextInput
                className={styles.field}
                label={
                  <>
                    Address<span className={styles.asteriskRed}>*</span>
                  </>
                }
                name="address"
                type="text"
                required
                value={createAcc.address}
                placeholder="Enter Address"
                // value={values.address || ""}
                onChange={(e) => {
                  handleChangeAddAccount(e);
                  validateForm();
                }}
              />
            </div>
            <div className={cn(styles.rowField)}>
              <label>
                State<span className={styles.asteriskRed}>*</span>
              </label>
              <select name="state" onChange={handleChangeAddAccount} className={cn(styles.input)}>
                <option value={""}>{isEdit ? createAcc.state : "Select State"}</option>
                {indStates?.length > 0 &&
                  indStates?.map((item, index) => (
                    <option key={index} value={JSON.stringify(item)}>{item.name}</option>
                  ))}
              </select>
              {/* <SearchableDropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                selectedObj={indStates}
                setValue={setIndStates}
                options={stateNames}
              ></SearchableDropdown> */}
            </div>

            <div className={styles.rowField}>
              <label>
                City<span className={styles.asteriskRed}>*</span>
              </label>
              <select name="city" onChange={handleChangeAddAccount} className={styles.input}>
                <option value={""}>{isEdit ? createAcc.city : "Select City"}</option>
                {indCities?.length > 0 &&
                  indCities?.map((item, index) => (
                    <option key={index} value={item.name}>{item.name}</option>
                  ))}
              </select>
            </div>
            {/* <div className={styles.rowField}>
              <TextInput
                className={styles.field}
                label={
                  <>
                    Country<span className={styles.asteriskRed}>*</span>
                  </>
                }
                name="country"
                type="text"
                // tooltip="This field is required"
                placeholder="Country"
                value={createAcc.country}
                required
                // value={values.country ? values.country : ""}
                onChange={(e) => {
                  handleChangeAddAccount(e);
                  validateForm();
                }}
              />
            </div> */}
            <div className={styles.rowField}>
              <TextInput
                className={styles.field}
                label={
                  <>
                    Pin Code<span className={styles.asteriskRed}>*</span>
                  </>
                }
                name="pincode"
                type="text"
                maxlength={6}
                required
                placeholder="Enter Pincode"
                value={createAcc?.pincode}
                onChange={(e) => {
                  handleChangeAddAccount(e);
                  validateForm();
                }}
              />
            </div>
            {role === Roles.SA ? (
              <>
                <div className={styles.rowField}>
                  <TextInput
                    className={styles.field}
                    label={
                      <>
                        Account Name
                        <span className={styles.asteriskRed}>*</span>
                      </>
                    }
                    name="accname"
                    type="text"
                    // tooltip="This field is required"
                    required
                    onChange={(e) => {
                      handleChangeAddAccount(e);
                      validateForm();
                    }}
                    placeholder="Enter Account Name"
                    value={createAcc?.accname}
                  />
                </div>
                <div className={styles.rowField}>
                  <div
                    className={styles.field}
                    style={{ opacity: `${isEdit && "0.5"}` }}
                  >
                    <Dropdown
                      className={styles.field}
                      label={
                        <>
                          Account Type
                          <span className={styles.asteriskRed}>*</span>
                        </>
                      }
                      // tooltip="This field is required"
                      name="acctype"
                      selectedObj={sorting}
                      setValue={(data) => handleChangeStatusAcc(data, true)}
                      options={optionList}
                      isreadOnly={isEdit}
                    />
                  </div>
                </div>
              </>
            ) : null}
            <div
              className={cn(styles.rowField, styles.add_balance)}
              style={{ opacity: `${isEdit && "0.5"}` }}
            >
              {isEdit ? (
                <TextInput
                  className={styles.field}
                  label={
                    <>
                      Validity<span className={styles.asteriskRed}>*</span>
                    </>
                  }
                  name="uservalidity"
                  // type="text"
                  placeholder={moment(createAcc.uservalidity).format("lll")}
                  disabled={isEdit && "true"}
                />
              ) : (
                <TextInput
                  className={styles.field}
                  label={
                    <>
                      Validity<span className={styles.asteriskRed}>*</span>
                    </>
                  }
                  name="uservalidity"
                  type="date"
                  range="min"
                  // tooltip="This field is required"
                  required
                  placeholder="Date"
                  onChange={(e) => {
                    handleChangeAddAccount(e);
                    validateForm();
                  }}
                />
              )}
              <p className={styles.createErr}>{createAccErr.uservalidity}</p>
            </div>
            <div
              className={cn(styles.rowField, styles.add_balance)}
              style={{
                opacity: `${isEdit && "0.5"}`,
                display: `${isEdit && "none"}`,
              }}
            >
              <TextInput
                className={cn(styles.field, styles.removeArrows)}
                label={
                  <>
                    Account Balance<span className={styles.asteriskRed}>*</span>
                  </>
                }
                name="usercredits"
                type="number"
                // tooltip="This field is required"
                value={createAcc.usercredits}
                placeholder="Enter Account Balance"
                required
                readonly={isEdit}
                onChange={(e) => {
                  handleChangeAddAccount(e);
                  validateForm();
                }}
              />
              <button
                onClick={() =>
                  handleChangeAddAccount({
                    target: {
                      name: "usercredits",
                      value: parseInt(createAcc.usercredits) + 10,
                    },
                  })
                }
                data-for={"add"}
                data-tip={"Add Balance"}
              >
                <img src={plus} alt="add" />
              </button>
              <TooltipGlobal id={"add"} place={"bottom"} />
            </div>
            <div className={styles.rowField}>
              <Dropdown
                className={styles.field}
                label={
                  <>
                    Status<span className={styles.asteriskRed}>*</span>
                  </>
                }
                name="userstatus"
                // tooltip="This field is required"
                selectedObj={sortingUser}
                setValue={handleChangeStatusAcc}
                // setValue={setSortingUser}
                options={optionListUser}
              />
            </div>
            <div className={styles.rowFieldremarks}>
              <TextInput
                className={styles.field}
                label="Remarks"
                name="remarks"
                type="text"
                // value={values.walletSettings ? values.walletSettings.remarks : ""}
                placeholder="Enter Remarks"
                onChange={(e) => {
                  handleChangeAddAccount(e);
                  validateForm();
                }}
              />
            </div>
            <div className={styles.buttonContainer}>
              <button
                disabled={!isFormValid}
                className={cn("button", styles.button)}
                onClick={handleSubmitAddAccount}
              >
                {isEdit ? "Update" : "Submit"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Form;
