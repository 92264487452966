import React, { useState } from "react";
import cn from "classnames";
import styles from "../SignIn/./SignInForm.module.sass";
import { use100vh } from "react-div-100vh";
import { Link, useLocation, useNavigate } from "react-router-dom";
import TextInput from "../../TextInput";
import Image from "../../Image";
import { useDispatch } from "react-redux";
// import {
//   ResetActionSaga,
// } from "../../../store/actions/ResetActions";
import { toast } from "react-hot-toast";
import Loader from "../../loader/Loader";
import { resetPasswordActionSaga } from "../../../store/actions/LoginActions";
import Icon from "../../Icon";
import Modal from "../../Modal";
import SuccessModal from "../../SuccessModal/SuccessModal";
import { validateInput } from "../../../Utils/Validations";
// import Modal from "../../Modal";

const SetNewPassword = ({ minHeightpan, className, noimg, navigate }) => {
  const heightWindow = use100vh();
  const dispatch = useDispatch();
  const naviTo = useNavigate();
  const [resetModel, setResetModel] = useState({
    password: "",
    confirmPassword: "",
  });
  const [resetModelErr, setResetModelErr] = useState({
    password: "",
    confirmPassword: "",
  });
  const state = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordone, setShowPasswordone] = useState(false);
  const [onSuccess, setOnSuccess] = useState(false)
  const branding = process.env.REACT_APP_IMAGE

  const handleLogin = (e) => {
    const { value, name } = e.target;
    setResetModel((prevState) => ({ ...prevState, [name]: value }));
    validateInput(name, value, resetModel, setResetModelErr);
  };




  const submitLogin = () => {
    const model = {
      newPassword: resetModel.password,
    };
    let isTrue = Object.values(model).some(
      (v) => v === "" || v === undefined || v === null
    );
    let isErrTrue = Object.values(resetModelErr).every(
      (v) => v === "" || v === undefined || v === null
    );
    if (isTrue || !isErrTrue) {
      toast.error("please check all the inputs");
    } else {
      // return setOnSuccess(true)
      dispatch(resetPasswordActionSaga({ model: model, callback: getDataOfReset, state }))
    }
  };

  const getDataOfReset = (data) => {
    let respData = data?.data
    if (respData.respcode === 200) {
      return setOnSuccess(true)
    }
    else {
      toast.error(respData.respdesc)
    }
  };

  return (
    <>
      <Loader />
      <div
        className={cn(styles.login, className)}
        style={{ minHeight: !minHeightpan ? heightWindow : null }}
      >
        <div className={styles.wrapper}>
          {!noimg && (
            <Link className={styles.logo} to="/">
              <Image
                className={styles.pic}
                src={branding ===  "SYN" ? "/images/logo.png" : "/images/trysakiLogo.png"}
                srcDark={branding ===  "SYN" ? "/images/logo.png" : "/images/trysakiLogo.png"}
                alt="image"
              />
            </Link>
          )}
          <div className={cn("hh2", styles.title1)}>New Password</div>
          <div className={styles.body}>

            <TextInput
              className={styles.field}
              name="password"
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              required
              icon="lock"
              eye="eye"
              onChange={handleLogin}
              setShowPassword={setShowPassword}
              showPassword={showPassword}
            /><div className={styles.copy}>
            </div>
            <p className={resetModelErr.password ? styles.loginModelErr : ""}>{resetModelErr.password}</p>
            <TextInput
              className={styles.field}
              name="confirmpassword"
              type={showPasswordone ? "text" : "password"}
              placeholder="Confirm Password"
              required
              icon="lock"
              eye="eye"
              onChange={handleLogin}
              setShowPasswordone={setShowPasswordone}
              showPasswordone={showPasswordone}
            /><div className={styles.copy}>
            </div>
            <p className={resetModelErr?.confirmpassword ? styles.loginModelErr : ""}>{resetModelErr?.confirmpassword}</p>
            <button
              className={cn("button", styles.button)}
              onClick={submitLogin}
            >
              Confirm
            </button>
            {/* </Link> */}
            {!noimg && (
              <div className={styles.note}>
                Powered by {branding === "SYN" ? "Syntizen" : "Tryaski"} Technologies Pvt Ltd.
              </div>
            )}
          </div>
          {/* <div className={styles.backbtn}>
            <Link
              className={cn("button-stroke button-small", styles.button)}
              to="/"
            >
              <Icon name="arrow-left" size="24" />
              <span>Back</span>
            </Link>
          </div> */}
        </div>
      </div>
      {onSuccess && (
        <>
          <Modal
            outerClassName={styles.emailSuccess}
            visible={onSuccess}
            onClose={() => setOnSuccess(false)}
            heading="Password Updated"
            children={
              <SuccessModal
                click={() => naviTo("/")}
                text="Your password has been changed successfully.
                Use your new password to sign in"
              />
            }
          />
        </>
      )}
    </>
  );
};

export default SetNewPassword;
