import React, { useEffect } from 'react'
import { useDispatch } from "react-redux";
import { reconciliationSummaryAction } from '../../../store/actions/TransactionsActions';
import Transactions from '.';
import { useLocation } from 'react-router-dom';

const Reconciliation = () => {

  return (
    <div>
      <Transactions />
    </div>
  )
}

export default Reconciliation
