import React, { useEffect, useRef, useState } from "react";
import cn from "classnames";
import styles from "./Overview.module.sass";
import TooltipGlodal from "../../../TooltipGlobal";
import Card from "../../../Card";
import Dropdown from "../../../Dropdown";
import Icon from "../../../Icon";
import Balance from "../../../Balance";
import Chart from "./Chart";
import Modal from "../../../Modal";
import { useDispatch } from "react-redux";
import {
  DashboardSuperAdminActionSaga,
} from "../../../../store/actions/DashboardCountActions";
import { addDays, subDays } from "date-fns";
import DatePicker from "react-datepicker";
import { getAccountsListActionSaga, getUsersListActionSaga } from "../../../../store/actions/LoginActions";
import SearchableDropdown from "../../../SearchableDropdown";
import moment from "moment";
import Loader from "../../../../components/loader/Loader"
import Roles from "../../../../Constants/Roles"


const Overview = () => {
  const dispatch = useDispatch();
  const role = sessionStorage.getItem("role");
  const [adminNames, setAdminNames] = useState([])
  const [userNames, setUserNames] = useState([])
  const [sorting, setSorting] = useState({ value: "", label: "" });
  const [sortingUser, setSortingUser] = useState({ value: "", label: "" });
  const [selectDate, setSelectDate] = useState({ value: "", label: "" });
  const [visibleModal, setVisibleModal] = useState(false);
  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;
  const [optionDateName, setOptionDateName] = useState([]);
  const [dashboardData, setDashboardData] = useState([])


  useEffect(() => {
    let dateRangeName =
      dateRangeBtns?.map((item) => ({
        value: item?.dateRange,
        label: item?.name,
      })) || [];
    setSelectDate(dateRangeName[0]);
    setOptionDateName(dateRangeName);
    switch (role) {
      case Roles.SA:
        return dispatch(
          getAccountsListActionSaga({ callback: getAccountsListData })
        );
      case Roles.AD:
        return dispatch(getUsersListActionSaga({ callback: getUsersListData }));
      default:
        return null;
    }
  }, []);


  useEffect(() => {
    if (selectDate?.label === "Custom" && dateRange?.length > 0) {
      let namesObj = role === Roles.SA ? sorting : sortingUser;
      handledashboardCount(namesObj, selectDate);
    } else if (role === Roles.USER) {
      handledashboardCount(selectDate)
    }
    return () => {
      setDashboardData([])
    };
  }, [dateRange]);



  const getAccountsListData = (data) => {
    if (data?.length > 0) {
      let optionListData = data?.map((item) => ({
        value: item?.account_id,
        label: item?.account_name,
        userid: item?.admin_id,
        // value: {
        //   accid: item?.account_id,
        //   userid: item?.admin_id
        // },
      })) || [];
      setAdminNames(optionListData);
      setSorting(optionListData[0]);
      handledashboardCount({});
    } else {
      setAdminNames([]);
      setSorting({});
    }
  };

  const getUsersListData = (data) => {
    if (data?.length > 0) {
      let optionListData = data?.map((item) => ({
        value: item?.user_id,
        label: item?.user_name
      })) || [];
      setUserNames(optionListData);
      setSortingUser(optionListData[0]);
      handledashboardCount({});
    } else {
      setUserNames([]);
    }
  };


  // Date Range functionality
  const dateRangeBtns = [
    {
      name: "Overall",
      dateRange: [
        {
          startDate: "",
          endDate: "",
          key: "selection",
        },
      ],
    },
    {
      name: "Today",
      dateRange: [
        {
          startDate: new Date(),
          endDate: addDays(new Date(), 0),
          key: "selection",
        },
      ],
    },
    {
      name: "Yesterday",
      dateRange: [
        {
          startDate: subDays(new Date(), 1),
          endDate: subDays(new Date(), 0),
          key: "selection",
        },
      ],
    },
    {
      name: "Last 7 days",
      dateRange: [
        {
          startDate: subDays(new Date(), 8),
          endDate: subDays(new Date(), 1),
          key: "selection",
        },
      ],
    },
    {
      name: "Last 30 days",
      dateRange: [
        {
          startDate: subDays(new Date(), 31),
          endDate: subDays(new Date(), 1),
          key: "selection",
        },
      ],
    },
    {
      name: "Custom",
      dateRange: [
        {
          startDate: new Date(),
          endDate: new Date(),
          key: "selection",
        },
      ],
    },
  ];


  const handledashboardCount = (namesObj, dateObj) => { // names, date
    if (dateObj?.label === "Custom" && (!dateRange[0] || !dateRange[1])) {
      return;
    }
    let startDate = "";
    let endDate = "";
    if (dateObj?.label === "Overall") {
      startDate = "";
      endDate = "";
    } else if (dateObj?.label === "Custom") {
      // startDate = moment(dateRange[0]).format("YYYY-MM-DD");
      // endDate = moment(dateRange[1]).format("YYYY-MM-DD");
      startDate = moment(dateRange[1]).format("YYYY-MM-DD");
      endDate = moment(dateRange[0]).format("YYYY-MM-DD");
    } else {
      // startDate = dateObj?.value[0] ? moment(dateObj?.value[0]?.startDate).format("YYYY-MM-DD") || "" : "";
      // endDate = dateObj?.value[0] ? moment(dateObj?.value[0]?.endDate).format("YYYY-MM-DD") || "" : "";
      startDate = dateObj?.value[0] ? moment(dateObj?.value[0]?.endDate).format("YYYY-MM-DD") || "" : "";
      endDate = dateObj?.value[0] ? moment(dateObj?.value[0]?.startDate).format("YYYY-MM-DD") || "" : "";
    }
    const payloadSuperAdmin = {
      "accid": role === Roles.SA ? namesObj?.value || "" : "",
      "userid": role === Roles.SA ? namesObj?.userid || "" : namesObj?.value || "",
      // "startDate": startDate,
      // "endDate": endDate
      "startDate": endDate,
      "endDate": startDate
    };
    dispatch(
      DashboardSuperAdminActionSaga({
        model: payloadSuperAdmin,
        callback: getDashboardCountDataList,
      })
    );
  };

  const getDashboardCountDataList = (data) => {
    setDashboardData(data)
  };



  const handleChangeSortingByNames = (data, val) => {
    if (val === "superAdmin") {
      setSorting(data)
    } else {
      setSortingUser(data)
    }
    handledashboardCount(data, selectDate);
  }

  const handleChangeSortingByDate = (data, val) => {
    setSelectDate(data);
    let namesObj = role === Roles.SA ? sorting : sortingUser;
    handledashboardCount(namesObj, data);
  }


  return (
    <>
      <Loader />
      {/* {(adminNames.length > 0 || userNames.length > 0) && */}
      <Card
        className={cn(styles.card, styles.cus_card)}
        title="Overview"
        classTitle="title-purple"
        head={
          <>
            <div className={styles.dashbrdDrpDwnforDes}>
              {role === Roles.USER ? null : (
                <SearchableDropdown
                  className={styles.dropdown}
                  classDropdownHead={styles.dropdownHead}
                  selectedObj={role === Roles.SA ? sorting : sortingUser}
                  // setValue={role === Roles.SA ? setSorting : setSortingUser}
                  setValue={role === Roles.SA ? (val) => handleChangeSortingByNames(val, 'superAdmin') : (val) => handleChangeSortingByNames(val, 'user')}
                  options={role === Roles.SA ? adminNames : userNames}
                // small
                />
              )}
              <SearchableDropdown
                className={styles.dropdown}
                classDropdownHead={styles.dropdownHead}
                selectedObj={selectDate}
                // setValue={setSelectDate}
                setValue={handleChangeSortingByDate}
                options={optionDateName}
              />
              {selectDate?.label === "Custom" && (
                <DatePicker
                  className={styles.datepick}
                  placeholderText="Click to Select Date Range"
                  dateFormat="MMM dd yyyy"
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  maxDate={new Date()}
                  withPortal
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                />
              )}
            </div>
          </>
        }
      >
        <div className={styles.dashbrdDrpDwn}>
          {role === Roles.USER ? null : (
            <Dropdown
              className={styles.dropdown}
              classDropdownHead={styles.dropdownHead}
              selectedObj={role === Roles.SA ? sorting : sortingUser}
              // setValue={role === Roles.SA ? setSorting : setSortingUser}
              setValue={role === Roles.SA ? (val) => handleChangeSortingByNames(val, 'superAdmin') : (val) => handleChangeSortingByNames(val, 'user')}
              options={role === Roles.SA ? adminNames : userNames}
              small
            />
          )}
          {/* <div>
                <select onChange={(e) => handleSelectOption(e.target.value)}>
                  <option value="today">Today</option>
                  <option value="yesterday">Yesterday</option>
                  <option value="last 7 days">Last 7 days</option>
                  <option value="overall">Overall</option>
                  <option value="custom">Custom</option>
                </select>
                {selectOption === "custom" && (
                  <>
                    <DatePicker
                      selectsRange={true}
                      startDate={startRDate}
                      endDate={endRDate}
                      onChange={(update) => {
                        setDateUpdateRange(update);
                      }}
                      withPortal
                    />
                   
                  </>
                )}
              </div> */}
          <Dropdown
            className={styles.dropdown}
            classDropdownHead={styles.dropdownHead}
            selectedObj={selectDate}
            setValue={handleChangeSortingByDate}
            options={optionDateName}
            small
          />
          {selectDate?.label === "Custom" && (
            <DatePicker
              className={styles.datepick}
              placeholderText="Click to Select Date Range"
              dateFormat="MMM dd yyyy"
              selectsRange={true}
              startDate={startDate}
              endDate={endDate}
              maxDate={new Date()}
              withPortal
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              onChange={(update) => {
                setDateRange(update);
              }}
            />
          )}
        </div>
        <div className={styles.overview}>
          <div className={styles.list}>
            {/* handleDashbord() */}
            {dashboardData.map((x, index) => (
              <div
                className={styles.item}
                key={index}
                style={{ backgroundColor: x.background }}
              >
                <div className={styles.icon}>
                  <Icon name={x.icon} size="24" />{" "}
                </div>
                <div className={styles.line}>
                  <div className={styles.details}>
                    <div className={styles.category}>
                      {x.title}
                      {/* <Tooltip
                        className={styles.tooltip}
                        title={x.tooltipText}
                        icon="info"
                        place="right"
                      /> */}
                    </div>
                    <div className={styles.counter}>{x.counter}</div>
                    <div className={styles.indicator}>
                      {/* {x.value === 0 ? <p className={styles.balance} style={{ fontSize: "10px" }}>No Data Available</p> : */}
                        <>
                          <Balance className={styles.balance} value={x.value} />
                          <span>{selectDate?.label}</span>
                        </>
                      {/* } */}
                    </div>
                  </div>
                  <Chart className={styles.chart} item={x} />
                </div>
              </div>
            ))}
          </div>
        </div>
      </Card>
      {visibleModal && (
        <>
          <div style={{ widht: "400px", height: "400px" }}></div>
          <Modal
            visible={visibleModal}
            onClose={() => setVisibleModal(false)}
            heading="Date Range"
          />
        </>
      )}
      <TooltipGlodal />
      <Loader />
    </>
  );
};

export default Overview;
