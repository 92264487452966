import React, { useState, useRef } from "react";
import cn from "classnames";
import styles from "./ManageAccounts.module.sass";
import Card from "../../../Card";
import SearchTextInput from "../../../SearchTextInput";
import Modal from "../../../Modal";
import ActionArea from "../ActionArea";
import noDataImg from "../../../../assests/Images/svgexport-1.svg";

import Form from "./Form";
// import Table from "./Table";
import DataTable, { createTheme } from "react-data-table-component";
import Icon from "../../../Icon";
import ManageLicenences from "./ManageLicence";
import { useDispatch } from "react-redux";
import {
  deleteAccountListSaga,
  deleteUserListSaga,
  getAccountsListActoinSaga,
  getUsersListActoinSaga,
} from "../../../../store/actions/PanActions";
import { useEffect } from "react";
import NoTableData from "../../../NoTableData";
import Loader from "../../../loader/Loader";
import Roles from "../../../../Constants/Roles";

createTheme("solarized", {
  text: {
    primary: "#6d757d",
    secondary: "#6d757d",
    // mixBlendMode:'difference'
  },
  background: {
    default: "transparent",
  },
});

const ManageAccounts = () => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [visibleModalAdd, setVisibleModalAdd] = useState(false);
  const [visibleModalLock, setVisibleModalLock] = useState(false);
  const [visibleModalEdit, setVisibleModalEdit] = useState(false);
  const [adminList, setAdminList] = useState("");
  const [userList, setUserList] = useState("");
  const [rowData, setRowData] = useState({});
  const [visibleModalDelete, setVisibleModalDelete] = useState(false);
  const [filteredAdminData, setFilteredAdminData] = useState([]);
  const [filteredUserData, setFilteredUserData] = useState([]);
  const [accountTypeMapping, setAccountTypeMapping] = useState({});
  const [accountstatusMapping, setAccountstatusMapping] = useState({});

  const role = sessionStorage.getItem("role");
  const pdfAreaRef = useRef(null);

  const accountSearch = (e) => {
    const searchText = e.target.value;
    setSearch(searchText);
    if (role === Roles.SA) {
      if (searchText) {
        const filteredAdminList = adminList?.filter(
          (admin) =>
            admin?.account_name
              .toLowerCase()
              .includes(searchText.toLowerCase()) ||
            admin.admin_phone.includes(searchText) ||
            admin?.admin_email
              .toLowerCase()
              .includes(searchText.toLowerCase()) ||
            admin?.account_uid.includes(searchText) ||
            accountTypeMapping[admin.account_type]
              .toLowerCase()
              .includes(searchText.toLowerCase()) ||
            accountstatusMapping[admin?.admin_status]
              .toLowerCase()
              .includes(searchText.toLowerCase()) ||
            admin?.city.toLowerCase().includes(searchText.toLowerCase())
        );

        setFilteredAdminData([...filteredAdminList]);
      } else {
        setFilteredAdminData([...adminList]);
      }
    } else {
      if (searchText) {
        const filteredUserList = userList?.filter(
          (user) =>
            user?.user_name.toLowerCase().includes(searchText.toLowerCase()) ||
            user?.user_phone.includes(searchText) ||
            user?.user_email.toLowerCase().includes(searchText.toLowerCase()) ||
            user?.user_uid.includes(searchText) ||
            accountstatusMapping[user?.user_status]
              .toLowerCase()
              .includes(searchText.toLowerCase())
        );
        // console.log(filteredUserList);
        setFilteredUserData([...filteredUserList]);
      } else {
        setFilteredUserData([...userList]);
      }
    }
  };

  const statuses = (status) => {
    if (status === 1) {
      return "Active";
    }
    return "Inactive";
  };

  const columns = {
    Admin: [
      {
        name: "Account ID",
        selector: (row) => row.account_uid,
      },
      {
        name: "Account Name",
        selector: (row) => row.account_name,
      },
      {
        name: "Account Type",
        selector: (row) => (row.account_type === 1 ? "API" : "Portal"),
      },
      {
        name: "City",
        selector: (row) => row.city,
      },
      {
        name: "Mobile",
        selector: (row) => row.admin_phone,
      },
      {
        name: "Email",
        selector: (row) => row.admin_email,
        wrap: true,
      },
      {
        name: "Status",
        selector: (row) => statuses(row.admin_status),
        cell: (row) => (
          <div
            className={cn(
              row.admin_status === 1 ? "status-green" : "status-red"
            )}
          >
            {statuses(row.admin_status)}
          </div>
        ),
      },
      {
        name: "Actions",
        selector: (row) => (
          <>
            <div className={styles.actionBtn}>
              <button
                onClick={() => {
                  setRowData(row);
                  handleEditModal(row);
                }}
                style={{ cursor: "pointer" }}
              >
                <Icon
                  name="edit"
                  className={styles.actionBtn}
                  size={18}
                  fill="#6d757d"
                />
              </button>
              <button
                onClick={() => handleLicence(row)}
                style={{ cursor: "pointer" }}
              >
                <Icon name="lock" size={18} fill="#6d757d" />
              </button>
              <button
                onClick={() => handleDeleteModal(row)}
                style={{ cursor: "pointer" }}
              >
                <Icon name="trash" size={18} fill="#6d757d" />
              </button>
            </div>
          </>
        ),
      },
    ],
    User: [
      {
        name: "User ID",
        selector: (row) => row.user_uid,
      },
      {
        name: "User Name",
        selector: (row) => row.user_name,
      },
      {
        name: "City",
        selector: (row) => row.city,
      },
      {
        name: "Mobile",
        selector: (row) => row.user_phone,
      },
      {
        name: "Email",
        selector: (row) => row.user_email,
        wrap: true,
      },
      {
        name: "Status",
        selector: (row) => statuses(row.user_status),
        cell: (row) => (
          <div
            className={cn(
              row.user_status === 1 ? "status-green" : "status-red"
            )}
          >
            {statuses(row.user_status)}
          </div>
        ),
      },
      {
        name: "Actions",
        selector: (row) => (
          <>
            <div className={styles.actionBtn}>
              <span
                onClick={() => {
                  setRowData(row);
                  handleEditModal(row);
                }}
                style={{ cursor: "pointer" }}
              >
                <Icon name="edit" className={styles.actionBtnAdmin} size={23} />
              </span>
              <span
                onClick={() => {
                  handleDeleteModal(row);
                }}
                style={{ cursor: "pointer" }}
              >
                {" "}
                <Icon name="trash" size={18} />
              </span>
            </div>
          </>
        ),
      },
    ],
  };

  const customStyles = {
    rows: {
      style: {
        minHeight: "60px",
        fontSize: "14px",
        fontWeight: "600",
        fontFamily: "'Inter', sans-serif",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },
    },
    // },
    headCells: {
      style: {
        fontSize: "12px",
        fontFamily: "'Inter', sans-serif",
        color: "#6F767E",
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        justifyContent: "center",
      },
    },
  };

  // createTheme("solarized", {
  //   text: {
  //     primary: "white",
  //   },
  //   background: {
  //     default: "#131313",
  //   },
  //   striped: {
  //     default: "red", // ENTER YOU NEW COLOR HERE
  //   },
  // });

  const onClickAdd = () => {
    setVisibleModalAdd(true);
  };

  const handleLicence = (rowdata) => {
    setVisibleModalLock(!visibleModalLock);
    setRowData(rowdata);
  };

  const handleEditModal = (row) => {
    setVisibleModalEdit(!visibleModalEdit);
    setRowData(row);
  };

  const handleDeleteModal = (rowData) => {
    setVisibleModalDelete(!visibleModalDelete);
    setRowData(rowData);
  };

  const closePopUP = () => {
    setVisibleModalAdd(false);
  };

  const handleHeadings = () => {
    switch (role) {
      case "SuperAdmin":
        return "Manage Accounts";
      case "Admin":
        return "Manage Users";
      default:
        return null;
    }
  };

  const getList = () => {
    switch (role) {
      case "SuperAdmin":
        return dispatch(
          getAccountsListActoinSaga({ callback: getAdminListData })
        );
      case "Admin":
        return dispatch(getUsersListActoinSaga({ callback: getUserListData }));
      default:
        return null;
    }
  };
  useEffect(() => {
    getList();
    setAccountTypeMapping({
      1: "API",
      2: "PORTAL",
    });
    setAccountstatusMapping({
      1: "Active",
      2: "Inactive",
    });
  }, []);

  const getAdminListData = (data) => {
    setAdminList(data?.data?.data);
    setFilteredAdminData(data?.data?.data);
  };

  const getUserListData = (data) => {
    setUserList(data?.data?.data);
    setFilteredUserData(data?.data?.data);
  };

  const DeleteModal = () => {
    const handleDelete = () => {
      switch (role) {
        case "SuperAdmin":
          const adminPayload = {
            account_id: rowData?.account_id,
          };
          return dispatch(
            deleteAccountListSaga({
              payload: adminPayload,
              callback: getList,
            }),
            setVisibleModalDelete(!visibleModalDelete)
          );
        case "Admin":
          const userPayload = {
            user_id: rowData?.user_id,
          };
          return dispatch(
            deleteUserListSaga({
              payload: userPayload,
              callback: getList,
            }),
            setVisibleModalDelete(!visibleModalDelete)
          );
        default:
          return null;
      }
    };

    const showEmail = () => {
      switch (role) {
        case "SuperAdmin":
          return rowData?.account_name;
        case "Admin":
          return rowData?.user_email;
        default:
          return null;
      }
    };

    return (
      <>
        <hr />
        <h4 className={styles.deleteh4}>
          {/* Deleting <span className="fw-bold">{showEmail()}</span> account will
          erase all the information from our Database. This cannot be undone. */}
          If you choose to delete the <span className="fw-bold">{showEmail()}</span> account, please be aware that all the
          information associated with it will be permanently removed from our database and cannot be reversed.
        </h4>
        <p className="mb-3 fs-5 fw-bold text-center">
          Are you sure you want to delete?
        </p>
        <div className={styles.deletediv}>
          <button
            className={cn("button-stroke-red", styles.button)}
            onClick={handleDelete}
          >
            <span>Continue</span>
            <Icon name="trash" size="20" />
          </button>
          <button
            className={cn("button", styles.button)}
            onClick={() => setVisibleModalDelete(!visibleModalDelete)}
          >
            Cancel
            <Icon name="close" size="20" />
          </button>
          {/* <div className={styles.copy}></div> */}
          {/* <p>{changeModelErr.password}</p> */}
        </div>
      </>
    );
  };

  const outSideClick = () => {
    return true;
  };

  return (
    <>
      <Loader />
      <Card
        className={styles.card}
        classCardHead={styles.head}
        title={handleHeadings()}
        classTitle={cn("title-purple", styles.title)}
        head={
          <>
            <SearchTextInput
              className={styles.form}
              value={search}
              placeholder="Search"
              type="text"
              name="search"
              icon="search"
              onChange={accountSearch}
              autoComplete="off"
            />
            <ActionArea
              className={styles.sorting}
              headers={role === Roles.SA ? columns.Admin : columns.User}
              data={role === Roles.SA ? adminList : userList}
              elementRef={pdfAreaRef}
              isAdd={true}
              onClickAdd={onClickAdd}
            />
          </>
        }
      >
        <div ref={pdfAreaRef}>
          {/* <Table headers={headers} data={data} pageSize={5} /> */}
          {adminList?.length > 0 || userList?.length > 0 ? (
            <DataTable
              columns={role === Roles.SA ? columns.Admin : columns.User}
              data={
                role === Roles.SA ? filteredAdminData : filteredUserData
              }
              pagination
              customStyles={customStyles}
              theme="solarized"
            />
          ) : (
            <NoTableData
              image={noDataImg}
              heading="No Records Found"
              semiheading={`There are no ${role === Roles.SA ? "accounts" : "users"
                } found at the moment`}
            />
          )}
        </div>
        <Modal
          outerClassName={styles.outer}
          visible={visibleModalAdd}
          onClose={() => setVisibleModalAdd(false)}
          heading={role === Roles.SA ? "Add Account" : "Add User"}
          outerCloseStop={outSideClick}
        >
          <Form
            data={adminList}
            isEdit={false}
            onClose={closePopUP}
            getList={getList}
          />
        </Modal>
        <Modal
          // outerClassName={styles.cus_outer}
          visible={visibleModalLock}
          onClose={() => setVisibleModalLock(false)}
          heading={"Manage License"}
        >
          <ManageLicenences tableData={rowData} />
        </Modal>
        <Modal
          // outerClassName={styles.outer}
          visible={visibleModalEdit}
          onClose={() => setVisibleModalEdit(false)}
          heading={"Edit Account"}
          outerCloseStop={outSideClick}
        >
          <Form
            data={rowData}
            isEdit={true}
            getList={getList}
            onClose={handleEditModal}
          />
        </Modal>
        <Modal
          outerClassName={styles.outerdelete}
          visible={visibleModalDelete}
          onClose={() => setVisibleModalDelete(false)}
          heading={"Delete Account"}
          children={<DeleteModal />}
        ></Modal>
      </Card>
    </>
  );
};

export default ManageAccounts;
