
import cn from 'classnames';
import React, { useState, useRef, useEffect } from 'react';
import { use100vh } from 'react-div-100vh';
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { LoginWithOtpActionSaga, resendOtpActionSaga } from '../../store/actions/LoginActions';
import Image from '../Image';
import Loader from '../loader/Loader';

import styles from "./OtpInput.module.sass";

const OtpInput = () => {
    const [otp, setOtp] = useState('');
    const [resendOtp, setResendOtp] = useState("")
    const inputRef = useRef([]);
    const heightWindow = use100vh();
    const dispatch = useDispatch()
    const naviTo = useNavigate();
    const emailId = sessionStorage.getItem("useremail")
    const branding = process.env.REACT_APP_IMAGE


    const handleResendOtp = () => {
        dispatch(resendOtpActionSaga({ callback: resendOtpData }))
    }

    const resendOtpData = (data) => {
        sessionStorage.removeItem("dataOtp")
        setResendOtp(data?.data?.data)


    }


    const submitLogin = () => {
        const model = {
            "otp": otp
        }
        dispatch(LoginWithOtpActionSaga({ model: model, callback: getLoginWithOtpData }))
    }

    const getLoginWithOtpData = (data) => {
        let resp = data?.data?.respcode
        if (resp === 200) {
            naviTo("/home");
            sessionStorage.removeItem("dataOtp") || sessionStorage.removeItem("resendToken") || sessionStorage.removeItem("reloginToken")
        }
        // else if (data?.data?.respcode === 315) {
        //   setVisibleModal(!visibleModal);
        // }
        else {
            toast.error(data?.data?.respdesc)
        }

    }

    const hideEmail = () => {
        const [username, domain] = emailId.split('@');
        const hiddenUsername = username.charAt(0) + '*'.repeat(username.length - 2) + username.charAt(username.length - 1);
        const hiddenEmail = " " + hiddenUsername + '@' + domain;
        return hiddenEmail;
    }



    const handleChange = (event, index) => {
        const value = event.target.value;
        // Check if input is a number and not more than 1 character long
        if (event.key == "Backspace") {
            // Remove character if backspace is pressed
            const newOtp = [...otp];
            newOtp[index] = '';
            setOtp(newOtp.join(''));
            // Move focus to previous input
            if (index > 0) {
                inputRef.current[index - 1].focus();
            }
        } else if (/^[0-9]{0,6}$/.test(value)) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp.join(''));
            // Move focus to next input
            if (index < 5 && value) {
                inputRef.current[index + 1].focus();
            }
        }
    };

    const handlePaste = (event) => {
        event.preventDefault();
        const pasteData = event.clipboardData.getData('text/plain').replace(/\D/g, '');
        setOtp(pasteData.substring(0, 6));
    };

    return (
        <>
            <Loader />
            <div
                className={cn(styles.login)}
                style={{ minHeight: heightWindow }}

            >
                <div className={styles.wrapper}>
                    <Link className={styles.logo} to="">
                        <Image
                            className={styles.pic}
                            src={branding === "SYN" ? "/images/logo.png" : "/images/trysakiLogo.png"}
                            srcDark={branding === "SYN" ? "/images/logo.png" : "/images/trysakiLogo.png"}
                            alt="image"
                        />
                    </Link>
                    <div className={styles.body}>
                        <div className={styles.note}>
                            {`Please enter the OTP sent to ${hideEmail()}`}
                        </div>
                        <div className={styles.otp_container}>
                            {[...Array(6)].map((_, index) => (
                                <input
                                    key={index}
                                    type="text"
                                    maxLength="1"
                                    value={otp[index] || ''}
                                    onChange={(event) => handleChange(event, index)}
                                    onPaste={handlePaste}
                                    onKeyDown={(e) => handleChange(e, index)}
                                    tabIndex="1"
                                    ref={(input) => (inputRef.current[index] = input)}
                                />
                            ))}
                        </div>
                        <button
                            className={cn("button", styles.button)}
                            onClick={submitLogin}
                        // disabled
                        >
                            Confirm
                        </button>
                    </div>
                    <h5 className={styles.fpassword} onClick={handleResendOtp}>
                        {" "}
                        Resend OTP
                    </h5>
                </div>
            </div>
        </>
    );
}

export default OtpInput;
