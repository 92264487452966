import { DASHBOARD_ADMIN_COUNT, DASHBOARD_SUPER_ADMIN_COUNT, DASHBOARD_USER_COUNT } from "../sagaActionConstants/DashboardCountConstants"

export const DashboardSuperAdminActionSaga = (model) => {
    return {
        type: DASHBOARD_SUPER_ADMIN_COUNT,
        payload: model
    }
}

export const DashboardAdminActionSaga = (model) => {
    return {
        type: DASHBOARD_ADMIN_COUNT,
        payload: model
    }
}

export const DashboardUserActionSaga = (model) => {
    return {
        type: DASHBOARD_USER_COUNT,
        payload: model
    }
}
