import * as PanReducerConstants from "../sagaActionConstants/sagaReducerActionConstants/PanReducerContants"

const initial = {
    login: {},
    loader: false,
    otpComponent: false,
    adminNames: [],
    userNames: []

}

const PanReducer = (state = initial, action) => {
    switch (action.type) {
        case PanReducerConstants.LOGIN_REDUCER:
            return { ...state, login: action.payload }
        case PanReducerConstants.LOADER_BEGIN:
            return { ...state, loader: action.payload }
        case PanReducerConstants.GET_OTP_COMPONENT:
            return { ...state, otpComponent: action.payload }
        case PanReducerConstants.GET_ADMIN_NAMES_REDUCER:
            return { ...state, adminNames: action.payload }
        case PanReducerConstants.GET_USER_NAMES_REDUCER:
            return { ...state, userNames: action.payload }
        default:
            return state;
    }

}


export default PanReducer