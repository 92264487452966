import { applyMiddleware, createStore } from "redux";
import createSagaMiddleware from 'redux-saga';

import rootReducer from "./reducers/index";
import rootSage from "./sagaWatchers/saga";
const sagaMiddleware = createSagaMiddleware();

const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(rootSage);

export default store;