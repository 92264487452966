import axios from "axios";
import { toast } from "react-hot-toast";
const Token = localStorage.getItem('data');
const instance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL
});

instance.defaults.headers.common['content-type'] = "application/json";
instance.defaults.headers.common['content-type'] = "multipart/form-data;";
instance.defaults.headers.common["Authorization"] = `Bearer ${Token}`

instance.interceptors.request.use(
    config => {
        const authkey = localStorage.getItem('data');
        const tokenData = sessionStorage.getItem("dataOtp");
        const resendToken = sessionStorage.getItem("resendToken")
        const reloginToken = sessionStorage.getItem("reloginToken")
        let updatedToken;
        if (tokenData || resendToken || reloginToken) {
            updatedToken = tokenData || resendToken || reloginToken;
        } else if (config?.state?.state) {
            updatedToken = config?.state?.state
        } else if (config?.token) {
            updatedToken = config?.token
        } else if (config?.tokenLogout) {
            updatedToken = config?.tokenLogout
        } else if (config?.state) {
            updatedToken = config?.state
        }
        else {
            updatedToken = authkey
        }
        config.headers['Authorization'] = 'Bearer ' + updatedToken;

        return config;
    },
    error => {
        console.log(error, "requestinstance")
        return Promise.reject(error);
    }
);


instance.interceptors.response.use(
    response => {
        if (response?.data?.respcode === 405
            || response?.data?.respcode === 316
            || response?.data?.respcode === 306
        ) {
            sessionStorage.clear();
            window.location.assign("/")
        }
        return response;
    },
    error => {
        toast.error(error?.response?.data?.respdesc)
        return Promise.reject(error);
    }
);


export default instance;