import { all } from "redux-saga/effects";
import CreditsWatcherSaga from "./CreditsWatcherSaga";
import DashboardCountWatcherSaga from "./DashboardCountWatcherSaga";
import LoginWatcherSaga from "./LoginWatcherSaga";
import PanWatcherSaga from "./PanWatcherSaga";
import TransactionWatcherSaga from "./TransactionWatcherSaga";
export default function* rootSage() {
  yield all([
    PanWatcherSaga(),
    LoginWatcherSaga(),
    TransactionWatcherSaga(),
    CreditsWatcherSaga(),
    DashboardCountWatcherSaga()
    
  ]);
}
