import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { transactionResponseSummaryAction } from "../../store/actions/TransactionsActions";
import styles from "./TxnResp.module.sass";
import confetti from "canvas-confetti";
import Success from "./Success";
import Modal from "../Modal";
import { toast } from "react-hot-toast";
import Loader from "../loader/Loader";

const TxnResp = () => {
  const dispatch = useDispatch();
  const naviTo = useNavigate();
  const params = useParams();
  const [txnRes, setTxnRes] = useState("");
  const [visibleModal, setVisibleModal] = useState(false);
  const [errorAxios, setErrorAxios] = useState(false)
  const txnStatus = txnRes?.data?.data?.txnstatus;
  const ackNumber = txnRes?.data?.data?.ackno;
  const errmg = txnRes?.data?.data?.errmsg;


  const outSideClick = () => {
    return true;
  };

  useEffect(() => {
    const model = {
      txnid: params?.id,
    };
    dispatch(
      transactionResponseSummaryAction({
        model: model,
        callback: getResponseData,
      })
    );
  }, []);

  const getResponseData = (data) => {
    // console.log(data, "resp data from nsdl")
    const catchError = data?.response?.data?.respcode
    if (data?.data?.respcode === 200) {
      setTxnRes(data);
      setVisibleModal(true);
    } else if (catchError === 500) {
      setErrorAxios(true)
    }
    else {
      toast.error(data?.data?.respdesc);
    }
  };

  const changeColor = () => {
    switch (txnStatus) {
      case 1:
        return ackNumber;
      default:
        return errmg;
    }
  };

  const handleCopy = () => {
    navigator.clipboard.writeText(ackNumber);
    toast.success("Acknowledgement Copied");
  };

  return (
    <>
      {/* <Confetti
                width={width}
                height={height}
            /> */}
      <Loader dispname="Fetching Data..." />
      {visibleModal && (
        <Modal
          outerClassName={styles.outer}
          visible={visibleModal}
          outerCloseStop={outSideClick}
          closebtn={styles.btn}
        >
          <Success
            spanTag={
              txnStatus === 1 ? (
                <span role="img" aria-label="firework">
                  🎉
                </span>
              ) : (
                <span role="img" aria-label="firework">
                  🤦‍♂️
                </span>
              )
            }
            heading={txnStatus === 1 ? "Success" : "Failed"}
            status={txnStatus === 1 ? "Acknowledgement No:" : ""}
            respData={changeColor()}
            // respData={txnStatus === 1 ? ackNumber : errmg}
            cusStyles={{
              fontSize: txnStatus === 1 ? "28px" : "",
              marginTop: txnStatus === 1 ? "-8px" : "",
            }}
            copyIcon={txnStatus === 1 ? true : false}
            btnText={txnStatus === 1 ? "Done" : "Okay"}
            func={() => naviTo("/home")}
            handleCopy={handleCopy}
          />
        </Modal>
      )}
      {errorAxios && (
        <Modal
          outerClassName={styles.outer}
          visible={errorAxios}
          outerCloseStop={outSideClick}
          closebtn={styles.btn}
        >
          <Success
            spanTag={
              <span role="img" aria-label="firework">
                🤦‍♂️
              </span>
            }
            heading="Internal Server Error"
            status="Kindly verify the current status of the transaction by reviewing the transaction history page"
            btnText="Proceed"
            func={() => naviTo("/transactions")}
          />
        </Modal>
      )}
    </>
  );
};

export default TxnResp;
