export const LOGIN_REDUCER = "LOGIN_REDUCER"

// loader

export const LOADER_BEGIN = "LOADER_BEGIN"

export const GET_ADMIN_NAMES_REDUCER = "GET_ADMIN_NAMES_REDUCER"
export const GET_USER_NAMES_REDUCER = "GET_USER_NAMES_REDUCER"


export const GET_OTP_COMPONENT = "GET_OTP_COMPONENT"