
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import Gleap from "gleap"
Gleap.initialize("S4QTJPN49IcP92HBUTmkiQVNNzBLO1cd")


if (process.env.REACT_APP_IMAGE === "") {
  var link = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement("link");
    link.rel = "icon";
    document.getElementsByTagName("head")[0].appendChild(link);
  }
  link.href = "/images/trysakiLogo.png";
  document.title = "Tryaski - Instant PAN Services";
} else {
  document.title = "Syntizen - Instant PAN Services";
}




ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
