export const LOGIN = "LOGIN"

export const LOGOUTALL = "LOGOUTALL"

export const LOGOUT = "LOGOUT"

export const LOGIN_WITH_OTP = "LOGIN_WITH_OTP"

export const RESET_PASSWORD = "RESET_PASSWORD"

export const FORGOT_PASSWORD = "FORGOT_PASSWORD"

export const CHANGE_PASSWORD = "CHANGE_PASSWORD"

export const RESEND_OTP = "RESEND_OTP"




// DROPDOWN APIs

export const GET_ACCOUNTS_NAME_LIST = "GET_ACCOUNTS_NAME_LIST"

export const GET_USERS_NAME_LIST = "GET_USERS_NAME_LIST"