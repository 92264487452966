import React from "react";
import cn from "classnames";
import styles from "./TextInput.module.sass";
import Icon from "../Icon";
import Tooltip from "../Tooltip";
import moment from "moment";

const TextInput = ({
  className,
  classLabel,
  classInput,
  label,
  icon,
  eye,
  copy,
  currency,
  tooltip,
  place,
  textError,
  classtextError,
  setShowPassword,
  showPassword,
  setShowPasswordone,
  showPasswordone,
  showPasswordtwo,
  setShowPasswordtwo,
  value,
  onChange,
  name,
  range,
  readonly,
  // required,
  ...props
}) => {
  // const elemnt = document.getElementById("saqib")
  // console.log(elemnt, "impout")
  return (
    <div
      className={cn(
        styles.field,
        { [styles.fieldIcon]: icon },
        { [styles.fieldCopy]: copy },
        { [styles.fieldEye]: eye },
        { [styles.fieldCurrency]: currency },
        className
      )}
    >
      {label && (
        <div className={cn(classLabel, styles.label)}>
          {label}{" "}
          {tooltip && (
            <Tooltip
              className={styles.tooltip}
              title={tooltip}
              icon="info"
              place={place ? place : "right"}
            />
          )}
        </div>
      )}
      <div className={styles.wrap}>
        <input className={cn(classInput, styles.input)} value={value} name={name} {...props} min={range == 'min' ? moment().format("YYYY-MM-DD") : null} max={range == 'max' ? moment().format("YYYY-MM-DD") : null} required onChange={onChange} readOnly={readonly} />
        {icon && (
          <div className={styles.icon}>
            <Icon name={icon} size="24" />{" "}
          </div>
        )}
        {copy && (
          <button className={styles.copy}>
            <Icon name="copy" size="24" />{" "}
          </button>
        )}
        {eye && (
          <button className={styles.eye} onClick={setShowPasswordone ? () => setShowPasswordone(!showPasswordone) : setShowPasswordtwo ? () => setShowPasswordtwo(!showPasswordtwo) : () => setShowPassword(!showPassword)}>
            <Icon name={showPasswordone || showPassword || showPasswordtwo ? "eyeOn" : "eyeOff"} size="18" />{" "}
          </button>
        )}

        {currency && <div className={styles.currency}>{currency}</div>}
      </div>
    </div >
  );
};

export default TextInput;
