import React, { useState, useEffect } from "react";
import Select from "react-select";

const SearchableDropdown = ({ options, setValue, selectedObj }) => {
  const [searchValue, setSearchValue] = useState("");
  const [toggleTheme, setToggleTheme] = useState(false);

  const handleChange = (selectedOption) => {
    setValue(selectedOption);
  };

  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchValue.toLowerCase())
  );

  useEffect(() => {
    window.addEventListener("storage", () => {
      const theme = localStorage.getItem("darkMode");
      if (theme) {
        setToggleTheme(theme);
      }
      //   setCurrentTheme(theme);
    });
  }, []);

  return (
    <Select
      styles={{
        control: (baseStyles, state) => ({
          ...baseStyles,
          borderRadius: "12px",
          width: "200px",
          height: "38px",
        }),
      }}
      value={selectedObj}
      options={filteredOptions}
      onChange={handleChange}
      onInputChange={(inputValue) => setSearchValue(inputValue)}
      isClearable
      placeholder="Search and select"
      className="my-select"
    />
  );
};

export default SearchableDropdown;
