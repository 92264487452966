import toast from "react-hot-toast";
import { put, takeLatest, call, takeEvery } from "redux-saga/effects";
import Axios from "../../service/axios.jsx";
import {
  loaderActionReq,
  loginSagaActionReducer,
} from "../actions/reducerActions/PanReducerActions.js";
// import { ACTION_NEW_PAN_SUBMIT, CREATE_ACCOUNT_REQ, CREATE_USER_ACCOUNT_REQ, CREATE_USER_REQ, EDIT_ADMIN_USERS, EDIT_USERS, GET_ACCOUNTS_LIST, GET_ACCOUNT_LIST, GET_USERS_LIST } from '../sagaActionConstants/PanConstants';
import {
  ACTION_NEW_PAN_SUBMIT,
  CREATE_ACCOUNT_REQ,
  CREATE_USER_ACCOUNT_REQ,
  //   CREATE_USER_REQ,
  DELETE_ACCOUNT_LIST,
  DELETE_USER_LIST,
  GET_ACCOUNTS_LIST,
  //   GET_ACCOUNT_LIST,
  GET_USERS_LIST,
  EDIT_ADMIN_USERS,
  EDIT_USERS,
  VERIFY_EMAIL,
  ACTION_PAN_CORRECTION_SUBMIT,
  ACTION_PAN_STATUS_SUBMIT,
} from "../sagaActionConstants/PanConstants";

// New Pan submit

const newPanSubmit = (model) => {
  const URL = "/panService/new-pan";
  return Axios.post(URL, model).then((res) => {
    return res;
  });
};
function* newPanSubmitSaga(action) {
  yield put(loaderActionReq(true));
  try {
    const resp = yield call(newPanSubmit, action?.payload?.model);
    if (resp?.data?.respcode === 200) {
      // toast.success(resp?.data?.respdesc);
      if (action?.payload?.callback) {
        action?.payload?.callback(resp);
        yield put(loaderActionReq(false));
      }
    } else {
      toast.error(resp?.data?.respdesc);
      yield put(loaderActionReq(false));
    }
  } catch (error) {
    // if (action?.payload?.callback) {
    //   action?.payload?.callback(error);
    //   yield put(loaderActionReq(false));
    // }
    if (error?.responce) {
      toast.error(error?.responce?.data?.respdesc);
    }
    yield put(loaderActionReq(false));
  } finally {
    yield put(loaderActionReq(false));
  }
}

// Pan Correction

const PanCorrection = (model) => {
  const URL = "/panService/pan-cr";
  return Axios.post(URL, model).then((res) => {
    return res;
  });
};
function* PanCorrectionSaga(action) {
  yield put(loaderActionReq(true));
  try {
    const resp = yield call(PanCorrection, action?.payload?.model);
    if (resp?.data?.respcode === 200) {
      // toast.success(resp?.data?.respdesc);
      if (action?.payload?.callback) {
        action?.payload?.callback(resp);
        yield put(loaderActionReq(false));
      }
    } else {
      toast.error(resp?.data?.respdesc);
      yield put(loaderActionReq(false));
    }
  } catch (error) {
    if (error?.responce) {
      toast.error(error?.responce?.data?.respdesc);
    }
    yield put(loaderActionReq(false));
  } finally {
    // yield put(actionReqResStatusLoaderSagaAction(false));
    yield put(loaderActionReq(false));
  }
}

// Pan status
const PanStatus = (model) => {
  const URL = "panService/pan-status";
  return Axios.post(URL, model).then((res) => {
    // console.log(res, "response");
    return res;
  });
};
function* PanStatusSaga(action) {
  yield put(loaderActionReq(true));
  try {
    const resp = yield call(PanStatus, action?.payload?.model);
    // if (resp?.data?.respcode === 200) {
    // toast.success(resp?.data?.respdesc);
    if (action?.payload?.callback) {
      action?.payload?.callback(resp);
      yield put(loaderActionReq(false));
    }
    // } else {
    //   toast.error(resp?.data?.respdesc);
    //   yield put(loaderActionReq(false));
    // }
  } catch (error) {
    // console.log(
    //   error?.response,
    //   "<><><><><><><><><><>?===============================>"
    // );
    if ([error?.responce?.statusText].length > 0) {
      toast.error(error?.responce?.statusText);
    } else {
      toast.error(error?.message);
    }
    yield put(loaderActionReq(false));
  } finally {
    // yield put(actionReqResStatusLoaderSagaAction(false));
    yield put(loaderActionReq(false));
  }
}

const createAdmin = (model) => {
  const URL = "account/create";
  return Axios.post(URL, model).then((res) => {
    return res;
  });
};
function* createAccountAdminSagaReq(action) {
  yield put(loaderActionReq(true));
  try {
    const resp = yield call(createAdmin, action?.payload?.model);
    if (resp?.data?.respcode === 200) {
      toast.success(resp?.data?.respdesc);
      if (action?.payload?.callback) {
        action?.payload?.callback(resp);
      }
    } else {
      toast.error(resp?.data?.data?.errors[0].msg || resp?.data?.respdesc);
      yield put(loaderActionReq(false));
    }
  } catch (error) {
    if (error?.responce) {
      toast.error(error?.responce?.data?.respdesc);
    }
  } finally {
    yield put(loaderActionReq(false));
  }
}

const createUser = (model) => {
  const URL = "account/user/create";
  return Axios.post(URL, model).then((res) => {
    return res;
  });
};
function* createAccountSagaReq(action) {
  yield put(loaderActionReq(true));
  try {
    const resp = yield call(createUser, action?.payload?.model);
    if (resp?.data?.respcode === 200) {
      toast.success(resp?.data?.respdesc);
      if (action?.payload?.callback) {
        action?.payload?.callback(resp);
      }
    } else {
      toast.error(resp?.data?.data?.errors[0].msg || resp?.data?.respdesc);
      yield put(loaderActionReq(false));
    }
  } catch (error) {
    // console.log(error?.responce)
    if (error?.responce) {
      toast.error(error?.responce?.data?.respdesc);
    }
  } finally {
    yield put(loaderActionReq(false));
  }
}

const GetAccountList = (model) => {
  const URL = "account/getadmin";
  return Axios.post(URL, model).then((res) => {
    return res;
  });
};
function* getAccountListSaga(action) {
  yield put(loaderActionReq(true));
  try {
    const resp = yield call(GetAccountList, action?.payload?.model);
    if (resp?.data?.respcode === 200) {
      if (action?.payload?.callback) {
        action?.payload?.callback(resp);
        yield put(loaderActionReq(false));
      }
    } else {
      toast.error(resp?.data?.respdesc);
      yield put(loaderActionReq(false));
    }
  } catch (error) {
    if (error?.responce) {
      // console.log(error?.responce?.data?.respdesc, "repr-------------->")
      toast.error(error?.responce?.data?.respdesc);
    }
  } finally {
    yield put(loaderActionReq(false));
  }
}

const GetUserList = (model) => {
  const URL = "account/getuser";
  return Axios.post(URL, model).then((res) => {
    return res;
  });
};
function* getUserListSaga(action) {
  yield put(loaderActionReq(true));
  try {
    const resp = yield call(GetUserList, action?.payload?.model);
    if (resp?.data?.respcode === 200) {
      if (action?.payload?.callback) {
        action?.payload?.callback(resp);
        yield put(loaderActionReq(false));
      }
    } else {
      toast.error(resp?.data?.respdesc);
      yield put(loaderActionReq(false));
    }
  } catch (error) {
    if (error?.responce) {
      toast.error(error?.responce?.data?.respdesc);
    }
  } finally {
    yield put(loaderActionReq(false));
  }
}

const editAdminUser = (model) => {
  const URL = "account/edit/admin/details";
  return Axios.post(URL, model).then((res) => {
    return res;
  });
};

function* editAdminUserSaga(action) {
  yield put(loaderActionReq(true));
  try {
    const resp = yield call(editAdminUser, action?.payload?.model);
    if (resp?.data?.respcode === 200) {
      toast.success(resp?.data?.respdesc);
      if (action?.payload?.callback) {
        action?.payload?.callback(resp);
      }
    } else {
      toast.error(resp?.data?.data?.errors[0].msg || resp?.data?.respdesc);
      yield put(loaderActionReq(false));
    }
  } catch (error) {
    if (error?.responce) {
      toast.error(error?.responce?.data?.respdesc);
    }
  } finally {
    yield put(loaderActionReq(false));
  }
}

const DeleteAccountList = (model) => {
  const URL = "account/delete/account";
  return Axios.post(URL, model).then((res) => {
    return res;
  });
};
function* deleteAccountListSaga(action) {
  yield put(loaderActionReq(true));
  try {
    const resp = yield call(DeleteAccountList, action?.payload?.payload);
    if (resp?.data?.respcode === 200) {
      toast.success(resp?.data?.respdesc);
      if (action?.payload?.callback) {
        action?.payload?.callback(resp);
      }
    } else {
      toast.error(resp?.data?.respdesc);
      yield put(loaderActionReq(false));
    }
  } catch (error) {
    if (error?.responce) {
      toast.error(error?.responce?.data?.respdesc);
    }
  } finally {
    yield put(loaderActionReq(false));
  }
}

const editUser = (model) => {
  const URL = "account/edit/user/details";
  return Axios.post(URL, model).then((res) => {
    return res;
  });
};
function* editUserSaga(action) {
  yield put(loaderActionReq(true));
  try {
    const resp = yield call(editUser, action?.payload?.model);
    if (resp?.data?.respcode === 200) {
      toast.success(resp?.data?.respdesc);
      if (action?.payload?.callback) {
        action?.payload?.callback(resp);
      }
    } else {
      toast.error(resp?.data?.data?.errors[0].msg || resp?.data?.respdesc);
      yield put(loaderActionReq(false));
    }
  } catch (error) {
    if (error?.responce) {
      toast.error(error?.responce?.data?.respdesc);
    }
  } finally {
    yield put(loaderActionReq(false));
  }
}

const DeleteUserList = (model) => {
  const URL = "account/delete/user";
  return Axios.post(URL, model).then((res) => {
    return res;
  });
};
function* deleteUserListSaga(action) {
  yield put(loaderActionReq(true));
  try {
    const resp = yield call(DeleteUserList, action?.payload?.payload);
    if (resp?.data?.respcode === 200) {
      toast.success(resp?.data?.respdesc);
      if (action?.payload?.callback) {
        action?.payload?.callback(resp);
      }
    } else {
      toast.error(resp?.data?.respdesc);
      yield put(loaderActionReq(false));
    }
  } catch (error) {
    if (error?.responce) {
      toast.error(error?.responce?.data?.respdesc);
    }
  } finally {
    yield put(loaderActionReq(false));
  }
}

// Verify Email Api

const verifyEmail = (model, token) => {
  const URL = "/account/verify";
  return Axios.post(URL, model, { token: token }).then((res) => {
    return res;
  });
};
function* verifyEmailSaga(action) {
  yield put(loaderActionReq(true));
  try {
    const resp = yield call(
      verifyEmail,
      action?.payload?.model,
      action?.payload?.token
    );
    // console.log(resp, "resp", new Date())
    // if (resp?.data?.respcode === 200) {
    if (action?.payload?.callback) {
      action?.payload?.callback(resp);
    }
    // }
  } catch (error) {
    if (error?.responce) {
      toast.error(error?.responce?.data?.respdesc);
    }
  } finally {
    yield put(loaderActionReq(false));
  }
}

export default function* PanWatcherSaga() {
  yield takeEvery(ACTION_NEW_PAN_SUBMIT, newPanSubmitSaga);
  yield takeEvery(ACTION_PAN_CORRECTION_SUBMIT, PanCorrectionSaga);
  yield takeEvery(ACTION_PAN_STATUS_SUBMIT, PanStatusSaga);
  yield takeEvery(CREATE_ACCOUNT_REQ, createAccountAdminSagaReq);
  yield takeEvery(CREATE_USER_ACCOUNT_REQ, createAccountSagaReq);
  yield takeEvery(GET_ACCOUNTS_LIST, getAccountListSaga);
  yield takeEvery(GET_USERS_LIST, getUserListSaga);
  yield takeEvery(EDIT_USERS, editUserSaga);
  yield takeEvery(EDIT_ADMIN_USERS, editAdminUserSaga);
  yield takeEvery(DELETE_ACCOUNT_LIST, deleteAccountListSaga);
  yield takeEvery(DELETE_USER_LIST, deleteUserListSaga);
  yield takeEvery(VERIFY_EMAIL, verifyEmailSaga);
}
