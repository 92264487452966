import React, { useState, useEffect } from "react";
import styles from "./Sidebar.module.sass";
import { Link, NavLink, useLocation } from "react-router-dom";
import cn from "classnames";
import Icon from "../Icon";
import Theme from "../Theme";
import Dropdown from "./Dropdown";
import Image from "../Image";
import Roles from "../../Constants/Roles";
import { superAdmin, admin, users } from "../../Constants/menuItems";


const Sidebar = ({ className, onClose }) => {
  const [visible, setVisible] = useState(false);
  const role = sessionStorage.getItem("role");
  const branding = process.env.REACT_APP_IMAGE;

  const renderMenuItems = (items) => {
    return items.map((x, index) =>
      x.url ? (
        <NavLink
          className={({ isActive }) =>
            isActive ? cn(styles.item, styles.active) : styles.item
          }
          to={x.url}
          key={index}
          exact
          onClick={onClose}
        >
          <Icon name={x.icon} size="24" />
          {x.title}
        </NavLink>
      ) : (
        <Dropdown
          className={styles.dropdown}
          visibleSidebar={visible}
          setValue={setVisible}
          key={index}
          item={x}
          onClose={onClose}
        />
      )
    );
  };
  

  return (
    <>
      <div
        className={cn(styles.sidebar, className, { [styles.active]: visible })}
      >
        <button className={styles.close} onClick={onClose}>
          <Icon name="close" size="24" />
        </button>
        <Link className={styles.logo} to="/home" onClick={onClose}>
          <Image
            className={styles.pic}
            src={
              branding === "SYN"
                ? "/images/logo.png"
                : "/images/trysakiLogo.png"
            }
            srcDark={
              branding === "SYN"
                ? "/images/logo.png"
                : "/images/trysakiLogo.png"
            }
            alt="image"
          />
        </Link>
        <div className={styles.menu}>
          {role === Roles.SA && renderMenuItems(superAdmin)}
          {role === Roles.AD && renderMenuItems(admin)}
          {role === Roles.USER && renderMenuItems(users)}
        </div>
        <button className={styles.toggle} onClick={() => setVisible(!visible)}>
          <Icon name="arrow-right" size="24" />
          <Icon name="close" size="24" />
        </button>
        <div className={styles.foot}>
          <Theme className={styles.theme} visibleSidebar={visible} />
        </div>
      </div>
      <div
        className={cn(styles.overlay, { [styles.active]: visible })}
        onClick={() => setVisible(false)}
      ></div>
    </>
  );
};

export default Sidebar;
