
import {
  ACTION_NEW_PAN_SUBMIT,
  CREATE_ACCOUNT_REQ,
  CREATE_USER_ACCOUNT_REQ,
  CREATE_USER_REQ,
  DELETE_ACCOUNT_LIST,
  DELETE_USER_LIST,
  GET_ACCOUNTS_LIST,
  GET_USERS_LIST,
  EDIT_ADMIN_USERS,
  EDIT_USERS,
  VERIFY_EMAIL,
  ACTION_PAN_CORRECTION_SUBMIT,
  ACTION_PAN_STATUS_SUBMIT,
} from "../sagaActionConstants/PanConstants";

export const newPanSubmitAction = (payload) => {
  return {
    type: ACTION_NEW_PAN_SUBMIT,
    payload: payload,
  };
};

export const PanCorrectionAction = (payload) => {
  return {
    type: ACTION_PAN_CORRECTION_SUBMIT,
    payload: payload,
  };
};

export const panStatusAction = (payload) => {
  return {
    type: ACTION_PAN_STATUS_SUBMIT,
    payload: payload,
  };
};


export const createAccountActoinSaga = (payload) => {
  return {
    type: CREATE_ACCOUNT_REQ,
    payload: payload,
  };
};

export const createUserAccountActoinSaga = (payload) => {
  return {
    type: CREATE_USER_ACCOUNT_REQ,
    payload: payload,
  };
};

export const getAccountsListActoinSaga = (payload) => {
  return {
    type: GET_ACCOUNTS_LIST,
    payload: payload,
  };
};

export const getUsersListActoinSaga = (payload) => {
  return {
    type: GET_USERS_LIST,
    payload: payload,
  };
};

export const editAdminUserActoinSaga = (payload) => {
  return {
    type: EDIT_ADMIN_USERS,
    payload: payload,
  };
};
export const deleteAccountListSaga = (payload) => {
  return {
    type: DELETE_ACCOUNT_LIST,
    payload: payload,
  };
};

export const editUserActoinSaga = (payload) => {
  return {
    type: EDIT_USERS,
    payload: payload,
  };
};

export const deleteUserListSaga = (payload) => {
  return {
    type: DELETE_USER_LIST,
    payload: payload,
  };
};


// verify email


export const verifyEmailSagaAction = (payload) => {
  return {
    type: VERIFY_EMAIL,
    payload: payload,
  };
};