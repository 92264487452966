import toast from 'react-hot-toast';
import { put, takeLatest, call, takeEvery } from 'redux-saga/effects';
import Axios from "../../service/axios.jsx"
import { loaderActionReq } from '../actions/reducerActions/PanReducerActions.js';
import { RECONCILIATION_SUMMARY_ACTION, TRANSACTION_RESPONSE_SUMMARY, TRANSACTION_SUMMARY, TRANSACTION_SUMMARY_ADMIN, TRANSACTION_SUMMARY_ADMIN_POST_API, TRANSACTION_SUMMARY_SUPER_ADMIN_POST_API, TRANSACTION_SUMMARY_USER, TRANSACTION_SUMMARY_USER_POST_API } from '../sagaActionConstants/TransactionsConstants.js';

//  Response
const transactionResponseSummary = (model) => {
    const URL = "transaction/last-transaction"
    return Axios.post(URL, model).then((res) => {
        return res
    })
};
function* transactionResponseSummarySaga(action) {
    yield put(loaderActionReq(true))

    try {
        const resp = yield call(transactionResponseSummary, action?.payload?.model)
        // if (resp?.data?.respcode === 200) {
        if (action?.payload?.callback) {
            action?.payload?.callback(resp)
            yield put(loaderActionReq(false))
        }
        // }
        // else {
        //     toast.error(resp?.data?.respdesc)
        // }
    } catch (error) {
        if (error) {
            if (action?.payload?.callback) {
                action?.payload?.callback(error)
                yield put(loaderActionReq(false))
            }
            // toast.error(error?.responce?.data?.respdesc)
            yield put(loaderActionReq(false))

        }
    } finally {
        yield put(loaderActionReq(false))
    }
}





const transactionSummary = (model) => {
    const URL = "transaction/super-admin"
    return Axios.get(URL, model).then((res) => {
        return res
    })
};
function* transactionSummarySaga(action) {
    yield put(loaderActionReq(true))

    try {
        const resp = yield call(transactionSummary, action?.payload?.model)
        if (resp?.data?.respcode === 200) {
            if (action?.payload?.callback) {
                action?.payload?.callback(resp)
                yield put(loaderActionReq(false))
            }
        }
        else {
            toast.error(resp?.data?.respdesc)
        }
    } catch (error) {
        if (error?.responce) {
            toast.error(error?.responce?.data?.respdesc)
            yield put(loaderActionReq(false))

        }
    } finally {
        yield put(loaderActionReq(false))
    }
}


const transactionSummaryAdmin = (model) => {
    const URL = "transaction/admin"
    return Axios.get(URL, model).then((res) => {
        return res
    })
};
function* transactionSummarySagaAdmin(action) {
    yield put(loaderActionReq(true))
    try {
        const resp = yield call(transactionSummaryAdmin, action?.payload?.model)
        if (resp?.data?.respcode === 200) {
            if (action?.payload?.callback) {
                action?.payload?.callback(resp)
                yield put(loaderActionReq(false))

            }
        }
        else {
            toast.error(resp?.data?.respdesc)
        }
    } catch (error) {
        if (error?.responce) {
            toast.error(error?.responce?.data?.respdesc)
            yield put(loaderActionReq(false))

        }
    } finally {
        yield put(loaderActionReq(false))
    }
}

const transactionSummaryUser = (model) => {
    const URL = "transaction/user"
    return Axios.get(URL, model).then((res) => {
        return res
    })
};
function* transactionSummarySagaUser(action) {
    yield put(loaderActionReq(true))
    try {
        const resp = yield call(transactionSummaryUser, action?.payload?.model)
        if (resp?.data?.respcode === 200) {
            if (action?.payload?.callback) {
                action?.payload?.callback(resp)
                yield put(loaderActionReq(false))

            }
        }
        else {
            toast.error(resp?.data?.respdesc)
        }
    } catch (error) {
        if (error?.responce) {
            toast.error(error?.responce?.data?.respdesc)
            yield put(loaderActionReq(false))

        }
    } finally {
        yield put(loaderActionReq(false))
    }
}


// Post APIs for Transaction history

const transactionSummarySuperAdminPostApi = (model) => {
    const URL = "transaction/super-admin"
    return Axios.post(URL, model).then((res) => {
        return res
    })
};
function* transactionSummarySagaSuperAdminPostApi(action) {
    yield put(loaderActionReq(true))
    try {
        const resp = yield call(transactionSummarySuperAdminPostApi, action?.payload?.model)
        if (resp?.data?.respcode === 200) {
            if (action?.payload?.callback) {
                action?.payload?.callback(resp?.data?.data)
                toast.success(resp?.data?.respdesc)
                yield put(loaderActionReq(false))
            }
        } else {
            toast.error(resp?.data?.respdesc)
        }
    } catch (error) {
        if (error?.responce) {
            toast.error(error?.responce?.data?.respdesc)
            yield put(loaderActionReq(false))

        }
    } finally {
        yield put(loaderActionReq(false))
    }
}

const transactionSummaryAdminPostApi = (model) => {
    const URL = "transaction/admin"
    return Axios.post(URL, model).then((res) => {
        return res
    })
};
function* transactionSummarySagaAdminPostApi(action) {
    yield put(loaderActionReq(true))
    try {
        const resp = yield call(transactionSummaryAdminPostApi, action?.payload?.model)
        if (resp?.data?.respcode === 200) {
            if (action?.payload?.callback) {
                action?.payload?.callback(resp?.data?.data)
                toast.success(resp?.data?.respdesc)
                yield put(loaderActionReq(false))
            }
        } else if (resp?.data?.respcode === 512) {
            toast.error(resp?.data?.respdesc)
        }
        else {
            toast.error(resp?.data?.respdesc)
        }
    } catch (error) {
        if (error?.responce) {
            // console.log(error?.responce, "admin")
            toast.error(error?.responce?.data?.respdesc)
            yield put(loaderActionReq(false))

        }
    } finally {
        yield put(loaderActionReq(false))
    }
}

const transactionSummaryUserPostApi = (model) => {
    const URL = "transaction/user"
    return Axios.post(URL, model).then((res) => {
        return res
    })
};
function* transactionSummarySagaUserPostApi(action) {
    yield put(loaderActionReq(true))
    try {
        const resp = yield call(transactionSummaryUserPostApi, action?.payload?.model)
        if (resp?.data?.respcode === 200) {
            if (action?.payload?.callback) {
                action?.payload?.callback(resp?.data?.data)
                toast.success(resp?.data?.respdesc)
                yield put(loaderActionReq(false))
            }
        } else {
            toast.error(resp?.data?.respdesc)
        }
    } catch (error) {
        // console.log(error?.responce)
        if (error?.responce) {
            toast.error(error?.responce?.data?.respdesc)
            yield put(loaderActionReq(false))

        }
    } finally {
        yield put(loaderActionReq(false))
    }
}


const reconciliationSummary = (model) => {
    const URL = "/transaction/reconciliation-transaction-history"
    return Axios.post(URL, model).then((res) => {
        return res
    })
};
function* reconciliationSummarySaga(action) {
    yield put(loaderActionReq(true))
    try {
        const resp = yield call(reconciliationSummary, action?.payload?.model)
        if (resp?.data?.respcode === 200) {
            if (action?.payload?.callback) {
                action?.payload?.callback(resp?.data?.data)
                toast.success(resp?.data?.respdesc)
                yield put(loaderActionReq(false))
            }
        } else {
            toast.error(resp?.data?.respdesc)
        }
    } catch (error) {
        // console.log(error?.responce)
        if (error?.responce) {
            toast.error(error?.responce?.data?.respdesc)
            yield put(loaderActionReq(false))

        }
    } finally {
        yield put(loaderActionReq(false))
    }
}


export default function* TransactionWatcherSaga() {
    yield takeEvery(TRANSACTION_SUMMARY, transactionSummarySaga)
    yield takeEvery(TRANSACTION_SUMMARY_ADMIN, transactionSummarySagaAdmin)
    yield takeEvery(TRANSACTION_SUMMARY_USER, transactionSummarySagaUser)
    yield takeEvery(RECONCILIATION_SUMMARY_ACTION, reconciliationSummarySaga)
    yield takeEvery(TRANSACTION_SUMMARY_SUPER_ADMIN_POST_API, transactionSummarySagaSuperAdminPostApi)
    yield takeEvery(TRANSACTION_SUMMARY_USER_POST_API, transactionSummarySagaUserPostApi)
    yield takeEvery(TRANSACTION_SUMMARY_ADMIN_POST_API, transactionSummarySagaAdminPostApi)
    yield takeEvery(TRANSACTION_RESPONSE_SUMMARY, transactionResponseSummarySaga)

}