import React, { useState } from "react";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./Dropdown.module.sass";
import Tooltip from "../Tooltip";

const Dropdown = ({
  className,
  classDropdownHead,
  classDropdownLabel,
  selectedObj,
  setValue,
  options,
  label,
  tooltip,
  small,
  upBody,
  choose,
  selectionDropDown,
  isreadOnly
}) => {
  const [visible, setVisible] = useState(false);
  const handleClick = (obj) => {
    setValue(obj);
    setVisible(false);
  };

  const handleClickOutside = () => {
    setVisible(false);
  };


  return (
    <>
      <OutsideClickHandler onOutsideClick={handleClickOutside} >
        {label && (
          <div className={cn(styles.label, classDropdownLabel)}>
            {label}{" "}
            {tooltip && (
              <Tooltip
                className={styles.tooltip}
                title={tooltip}
                icon="info"
                place="right"
              />
            )}
          </div>
        )}
        <div
          className={cn(
            styles.dropdown,
            className,
            { [styles.small]: small },
            {
              [styles.active]: visible,
            },
          )}
        >
          <div
            className={cn(styles.head, classDropdownHead)}
            onClick={() => setVisible(isreadOnly ? visible : !visible)}
          >
            <div className={cn(styles.selection)}>{selectedObj?.label}</div>
          </div>
          <div className={cn(styles.body, { [styles.bodyUp]: upBody })}>
            {options.map((x, index) => (
              <div
                className={cn(styles.option, {
                  [styles.selectioned]: x?.value === selectedObj?.value,
                })}
                onClick={() => handleClick(x, index)}
                key={index}
              >
                {x?.label}
              </div>
            ))}
          </div>
        </div>
      </OutsideClickHandler>
    </>
  );
};

export default Dropdown;
