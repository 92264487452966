import React, { useEffect, useRef } from "react";
import styles from "./Transactions.module.sass";
import TooltipGlobal from "../../TooltipGlobal";
import { useState } from "react";
import Card from "../../Card";
import cn from "classnames";
import DataTable, { createTheme } from "react-data-table-component";
import { useDispatch } from "react-redux";
import SearchTextInput from "../../SearchTextInput";
import ActionArea from "../Organizations/ActionArea";
import {
  reconciliationSummaryAction,
  transactionSummaryAction,
  transactionSummaryAdminAction,
  transactionSummaryAdminPostAction,
  transactionSummarySuperAdminPostAction,
  transactionSummaryUserAction,
  transactionSummaryUserPostAction,
} from "../../../store/actions/TransactionsActions";
import moment from "moment";
import Loader from "../../loader/Loader";
import DatePicker from "react-datepicker";
import NoTableData from "../../NoTableData";
import noDataImg from "../../../assests/Images/svgexport-1.svg";
import {
  getAccountsListActionSaga,
  getUsersListActionSaga,
} from "../../../store/actions/LoginActions";
import SearchableDropdown from "../../SearchableDropdown";
import Roles from "../../../Constants/Roles";
import { useLocation } from "react-router-dom";


const Transactions = () => {
  const dispatch = useDispatch();
  const location = useLocation()
  const [tran, setTran] = useState([]);
  const [adminNames, setAdminNames] = useState([]);
  const [userNames, setUserNames] = useState([]);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({
    value: "",
    label: "",
  });

  const [sortingUser, setSortingUser] = useState({
    value: "",
    label: "",
  });
  const pdfAreaRef = useRef(null);
  const role = sessionStorage.getItem("role");
  const [dateRange, setDateRange] = useState([null, null]);
  const [filteredTransactionData, setfilteredTransactionData] = useState([]);
  const [filteredReconciliationData, setFilteredReconciliationData] = useState([])
  const [startDate, endDate] = dateRange;
  const [reconciliationHistory, setReconciliationHistory] = useState([])

  // const clearStates = () => {
  //   setTran("")
  //   setAdminNames([])
  //   setUserNames([])
  //   setSearch("")
  //   setSorting({})
  //   setSortingUser({})
  //   setDateRange([])
  //   setfilteredTransactionData([])
  // }


  // useEffect(() => {
  //   return () => {
  //     clearStates()
  //   }
  // }, [])

  const changeName = () => {
    switch (location.pathname) {
      case '/transactions':
        return "Transactions History"
      default:
        return "Reconciliation History"
    }
  }



  useEffect(() => {
    switch (role) {
      case "SuperAdmin":
        return dispatch(
          getAccountsListActionSaga({ callback: getAccountsListData })
        );
      case "Admin":
        return dispatch(getUsersListActionSaga({ callback: getUsersListData }));
      default:
        return null;
    }
  }, []);

  const getAccountsListData = (data) => {
    if (data?.length > 0) {
      let optionListData =
        data?.map((item) => ({
          value: item?.account_id,
          label: item?.account_name,
          user_id: item?.admin_id
        })) || [];
      setAdminNames(optionListData);
      setSorting(optionListData[0]);
    } else {
      setAdminNames([]);
    }
  };
  const getUsersListData = (data) => {
    if (data?.length > 0) {
      let optionListData =
        data?.map((item) => ({
          value: item?.user_id,
          label: item?.user_name,
        })) || [];
      setUserNames(optionListData);
      setSortingUser(optionListData[0]);
    } else {
      setUserNames([]);
    }
  };


  const statuses = (types, status) => {
    if (types === "type") {
      if (status === 1) {
        return "New PAN";
      } else {
        return "PAN Correction";
      }
    } else if (types === "txn_status") {
      if (status === 0) {
        return "Failure";
      } else if (status === 1) {
        return "Success";
      } else {
        return "Pending"
      }
    }
  };

  const reconciliationStatuses = (type, reconStatus, errmsg, status) => {
    // console.log(type, reconStatus, errmsg, "ldskl");
    if (type === "type") {
      if (status === 1) {
        return "New PAN";
      } else {
        return "PAN Correction";
      }
    } else if (type === "reconciliationStatus") {
      if (reconStatus === 0) {
        return errmsg || "N/A";
      } else if (reconStatus === 1) {
        return "Success";
      } else {
        return "Pending"
      }
    }

  }

  const transactionSearch = (e) => {
    const searchText = e.target.value;
    setSearch(searchText);
    let filteredTransList = [];
    if (searchText) {
      filteredTransList = tran?.filter((tra) => {
        const serviceTypeMap = {
          1: 'NEW Pan',
          2: 'PAN Correction',
        };
        const txnStatus = {
          0: "Failure",
          1: "Success",
          2: "Pending"
        }
        const mappedServiceType = serviceTypeMap[tra?.servicetype];
        const mappedtxnStatus = txnStatus[tra?.txnstatus];
        return (
          tra?.username?.toLowerCase().includes(searchText.toLowerCase()) ||
          tra?.custname?.toLowerCase().includes(searchText.toLowerCase()) ||
          tra?.ackno?.toLowerCase().includes(searchText.toLowerCase()) ||
          (mappedServiceType &&
            mappedServiceType.toLowerCase().includes(searchText.toLowerCase())) ||
          (mappedtxnStatus &&
            mappedtxnStatus.toLowerCase().includes(searchText.toLowerCase()))
        );
      });
    } else {
      filteredTransList = [...tran];
    }
    setfilteredTransactionData([...filteredTransList]);
  };

  const reconciliationSearch = (e) => {
    const searchText = e.target.value;
    setSearch(searchText);
    let filteredTransList = [];
    if (searchText) {
      filteredTransList = reconciliationHistory?.filter((tra) => {
        const serviceTypeMap = {
          1: 'NEW Pan',
          2: 'PAN Correction',
        };
        const txnStatus = {
          0: "Failure",
          1: "Success",
          2: "Pending"
        }
        const mappedServiceType = serviceTypeMap[tra?.servicetype];
        const mappedtxnStatus = txnStatus[tra?.reconciliation];
        return (
          tra?.custname?.toLowerCase().includes(searchText.toLowerCase()) ||
          tra?.username?.toLowerCase().includes(searchText.toLowerCase()) ||
          tra?.txnid?.toLowerCase().includes(searchText.toLowerCase()) ||
          (mappedServiceType &&
            mappedServiceType.toLowerCase().includes(searchText.toLowerCase())) ||
          (mappedtxnStatus &&
            mappedtxnStatus.toLowerCase().includes(searchText.toLowerCase()))
        );
      });
    } else {
      filteredTransList = [...reconciliationHistory];
    }
    setFilteredReconciliationData([...filteredTransList]);
  };

  const renderSearchBar = () => {
    switch (location.pathname) {
      case "/transactions":
        return transactionSearch
      default:
        return reconciliationSearch
    }
  }


  const actionAreaFiles = () => {
    switch (location.pathname) {
      case "/transactions":
        return tran
      default:
        return reconciliationHistory
    }
  }



  useEffect(() => {
    if (location.pathname === "/transactions") {
      switch (role) {
        case "SuperAdmin":
          return dispatch(
            transactionSummaryAction({ callback: getTransactionSummaryData })
          );
        case "Admin":
          return dispatch(
            transactionSummaryAdminAction({
              callback: getTransactionSummaryDataAdmin,
            })
          );
        case "User":
          return dispatch(
            transactionSummaryUserAction({
              callback: getTransactionSummaryDataUser,
            })
          );
        default:
          return null;
      }
    } else {
      const model = {
        "accid": "",
        "userid": "",
        "startDate": "",
        "endDate": ""
      }
      dispatch(reconciliationSummaryAction({ model: model, callback: getReconciliationData }))
    }
  }, [role]);

  // SuperAdmin
  const getTransactionSummaryData = (data) => {
    if (data?.data?.data) {
      setTran(data?.data?.data);
      setfilteredTransactionData(data?.data?.data);
    } else {
      return;
    }
  };
  // Admin
  const getTransactionSummaryDataAdmin = (data) => {
    if (data?.data?.data) {
      setTran(data?.data?.data);
      setfilteredTransactionData(data?.data?.data);
    } else {
      return;
    }
  };
  // User
  const getTransactionSummaryDataUser = (data) => {
    if (data?.data?.data) {
      setTran(data?.data?.data);
      setfilteredTransactionData(data?.data?.data);
    } else {
      return;
    }
  };

  const handleName = (role) => {
    switch (role) {
      case "SuperAdmin":
        return "Account Name";
      case "Admin":
        return "User name";
      case "User":
        return "Customer name";
      default:
        return null;
    }
  };

  const handleRowData = (row) => {
    switch (role) {
      case "SuperAdmin":
        return row?.accid?.accname || row?.custname || "N/A";
      case "Admin":
        return row?.username || row?.custname || "N/A";
      case "User":
        return row?.custname || row?.username || "N/A";
      default:
        return null;
    }
  };

  const columns = {
    transaction: [

      {
        name: handleName(role),
        selector: (row) => handleRowData(row),
      },
      {
        name: "Transaction Type",
        selector: (row) => statuses("type", row.servicetype),
      },
      {
        name: "Transaction Status",
        selector: (row) => statuses("txn_status", row.txnstatus),
      },
      {
        name: "Transaction Date",
        selector: (row) => moment(row.createdAt).subtract(5, "hours").subtract(30, "minutes").format("MMMM Do YYYY, h:mm a"),
      },
      {
        name: "Acknowledgement Number",
        selector: (row) => row?.ackno || "N/A",
      },
    ],
    reconciliation: [
      {
        name: handleName(role),
        selector: (row) => handleRowData(row),
      },
      {
        name: "Transaction Type",
        selector: (row) => statuses("type", row.servicetype),
      },
      {
        name: "Transaction Status",
        selector: (row) => reconciliationStatuses("reconciliationStatus", row.reconciliation, row?.errmsg, row?.servicetype),
        wrap: true
      },
      {
        name: "Transaction Date",
        selector: (row) => moment(row.createdAt).subtract(5, "hours").subtract(30, "minutes").format("MMMM Do YYYY, h:mm a"),
      },
      {
        name: "Transaction ID",
        selector: (row) => row?.txnid || "N/A",
      },
    ],
  }

  createTheme("solarized", {
    text: {
      primary: "#6d757d",
      secondary: "#6d757d",
    },
    background: {
      default: "transparent",
    },
  });

  const customStylesTable = {
    rows: {
      style: {
        minHeight: "60px",
        fontSize: "14px",
        fontWeight: "600",
        fontFamily: "'Inter', sans-serif",
      },
    },
    // },
    headCells: {
      style: {
        fontSize: "12px",
        fontFamily: "'Inter', sans-serif",
        color: "#6F767E",
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        justifyContent: "center",
      },
    },
  };

  const checkValue = () => {
    if (role === Roles.SA || role === Roles.AD) {
      if (sorting?.value === "" && sortingUser?.value === "") {
        return true
      }
    }
    else {
      return false
    }
  }

  const reconciliationApi = () => {
    const model = {
      "accid": role === Roles.SA ? sorting?.value || "" : "",
      "userid": role === Roles.SA ? sorting?.user_id || "" : sorting?.value || "",
      "startDate": moment(startDate).format("YYYY-MM-DD"),
      "endDate": moment(endDate).format("YYYY-MM-DD")
    }
    dispatch(reconciliationSummaryAction({ model: model, callback: getReconciliationData }))
  }



  const getReconciliationData = (data) => {
    setReconciliationHistory(data)
    setFilteredReconciliationData(data)
  }


  const handleSubmitBtn = () => {
    if (location.pathname === "/transactions") {
      const modelSuperAdmin = {
        accid: sorting.value,
        fromdate: moment(startDate).format("YYYY-MM-DD"),
        todate: moment(endDate).format("YYYY-MM-DD"),
      };
      const modelAdmin = {
        userid: sortingUser.value,
        fromdate: moment(startDate).format("YYYY-MM-DD"),
        todate: moment(endDate).format("YYYY-MM-DD"),
      };
      const modelUser = {
        fromdate: moment(startDate).format("YYYY-MM-DD"),
        todate: moment(endDate).format("YYYY-MM-DD"),
      };
      switch (role) {
        case "SuperAdmin":
          return dispatch(
            transactionSummarySuperAdminPostAction({
              model: modelSuperAdmin,
              callback: getTransactionSummaryPostDataSuperAdmin,
            })
          );
        case "Admin":
          return dispatch(
            transactionSummaryAdminPostAction({
              model: modelAdmin,
              callback: getTransactionSummaryPostDataAdmin,
            })
          );
        case "User":
          return dispatch(
            transactionSummaryUserPostAction({
              model: modelUser,
              callback: getTransactionSummaryPostDataUser,
            })
          );
        default:
          return null;
      }
    } else {
      reconciliationApi()
    }
  };

  const getTransactionSummaryPostDataSuperAdmin = (data) => {
    if (data.length > 0) {
      // setTran(data)
      setfilteredTransactionData(data);
      setSorting(adminNames[0]);
      // setDateRange([])
    } else {
      setfilteredTransactionData([]);
      // setDateRange([])
    }
  };
  const getTransactionSummaryPostDataAdmin = (data) => {
    if (data.length > 0) {
      // setTran(data)
      setfilteredTransactionData(data);
      setSorting(userNames[0]);
      // setDateRange([])
      // toast.success(data?.data?.respdesc)
    } else {
      setfilteredTransactionData([]);
      // setDateRange([])
    }
  };
  const getTransactionSummaryPostDataUser = (data) => {
    if (data.length > 0) {
      setfilteredTransactionData(data);
    } else {
      setfilteredTransactionData([]);
    }
  };

  const renderTableColumns = () => {
    switch (location.pathname) {
      case "/transactions":
        return columns?.transaction
      default:
        return columns?.reconciliation
    }

  }

  const renderTableData = () => {
    switch (location.pathname) {
      case "/transactions":
        return filteredTransactionData
      default:
        return filteredReconciliationData
    }
  }


  return (
    <>
      <Loader />
      <div className={styles.section}>
        <Card
          className={cn(styles.card, styles.cus_card)}
          children={
            <div className={cn(styles.setup_dropd)}>
              <>
                {role === Roles.SA || role === Roles.AD ? (
                  <SearchableDropdown
                    className={cn(styles.cus_dropDown)}
                    classDropdownHead={styles.cus_dropdownHead}
                    selectedObj={role === Roles.SA ? sorting : sortingUser}
                    setValue={
                      role === Roles.SA ? setSorting : setSortingUser
                    }
                    options={role === Roles.SA ? adminNames : userNames}
                    small
                  />
                ) : null}
                <DatePicker
                  className={styles.datepick}
                  dateFormat="MMM dd yyyy"
                  placeholderText="Click to Select Date Range"
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  maxDate={new Date()}
                  peekNextMonth
                  showMonthDropdown
                  showYearDropdown
                  dropdownMode="select"
                  onChange={(update) => {
                    setDateRange(update);
                  }}
                />
                <button
                  type="button"
                  className={cn("button-square", {
                    [styles.disab]: checkValue()
                  })}
                  onClick={handleSubmitBtn}
                  disabled={checkValue()}
                >
                  Submit
                </button>
              </>
            </div>
          }
        />
        <Card
          className={styles.card}
          classCardHead={styles.head}
          title={changeName()}
          classTitle={cn("title-purple", styles.title)}
          head={
            <>
              <SearchTextInput
                className={styles.form}
                value={search}
                placeholder="Search"
                type="text"
                name="search"
                icon="search"
                onChange={renderSearchBar()}
                autoComplete="off"
              />
              <ActionArea
                className={styles.sorting}
                headers={renderTableColumns()}
                data={actionAreaFiles()}
                elementRef={pdfAreaRef}
                isAdd={false}
              // onClickAdd={onClickAdd}
              />
            </>
          }
        >
          <div ref={pdfAreaRef}>
            {/* <Table headers={headers} data={data} pageSize={5} /> */}
            {renderTableData().length > 0 ? (
              <DataTable
                columns={renderTableColumns()}
                data={renderTableData()}
                pagination
                theme="solarized"
                customStyles={customStylesTable}
              />
            ) : (
              <NoTableData
                image={noDataImg}
                heading="No Records Found"
                semiheading="There are no transactions"
              />
            )}
          </div>
        </Card>
        <TooltipGlobal />
      </div>
    </>
  );
};

export default Transactions;
