import toast from "react-hot-toast";
import { put, takeLatest, call, takeEvery } from "redux-saga/effects";
import { CHANGE_PASSWORD, FORGOT_PASSWORD, GET_ACCOUNTS_LIST, GET_ACCOUNTS_NAME_LIST, GET_USERS_LIST, GET_USERS_NAME_LIST, LOGIN, LOGIN_WITH_OTP, LOGOUT, LOGOUTALL, RESEND_OTP, RESET_PASSWORD } from "../sagaActionConstants/LoginConstants";
import Axios from "../../service/axios.jsx";
import {
  getAdminNameReducer,
  loaderActionReq,
  loginSagaActionReducer,
} from "../actions/reducerActions/PanReducerActions";


const Login = (model) => {
  const URL = "account/user/login";
  return Axios.post(URL, model).then((res) => {
    return res;
  });
};

function* LoginSaga(action) {
  yield put(loaderActionReq(true));
  try {
    const resp = yield call(Login, action?.payload?.model);

    // if (resp?.data?.respcode === 200) {
    yield put(loginSagaActionReducer(resp));
    sessionStorage.setItem("dataOtp", resp?.data?.data);
    if (action?.payload?.callback) {
      action?.payload?.callback(resp);
      yield put(loaderActionReq(false));
    }
    // }
    // else {
    //   toast.error(resp?.data?.respdesc);
    // }
  } catch (error) {
    if (error?.response) {
      toast.error(error?.response?.data?.respdesc);
      console.log(
        error?.response?.data?.respdesc,
        "error?.response?.data?.respdesc"
      );
      yield put(loaderActionReq(false));
    }
  } finally {
    yield put(loaderActionReq(false));
  }
}

// Login-otp

const LoginWithOtp = (model) => {
  const URL = "account/login-otp";
  return Axios.post(URL, model).then((res) => {
    return res;
  });
};

function* LoginWithOtpSaga(action) {
  yield put(loaderActionReq(true));
  try {

    const resp = yield call(LoginWithOtp, action?.payload?.model);
    // if (resp?.data?.respcode === 200) {
    yield put(loginSagaActionReducer(resp));
    sessionStorage.setItem("data", resp?.data?.data?.token);
    localStorage.setItem("data", resp?.data?.data?.token);
    sessionStorage.setItem("role", resp?.data?.data?.role);
    sessionStorage.setItem("username", resp?.data?.data?.userDetails?.userName)
    if (action?.payload?.callback) {
      action?.payload?.callback(resp);
      yield put(loaderActionReq(false));
    }
    // }
    // else {
    //   toast.error(resp?.data?.respdesc);
    // }
  } catch (error) {
    if (error?.response) {
      toast.error(error?.response?.data?.respdesc);
      console.log(
        error?.response?.data?.respdesc,
        "error?.response?.data?.respdesc"
      );
      yield put(loaderActionReq(false));
    }
  } finally {
    yield put(loaderActionReq(false));
  }
}

const LogoutAll = (model) => {
  const URL = "account/relogin";
  return Axios.post(URL, model).then((res) => {
    return res;
  });
};
function* LogoutAllSaga(action) {
  yield put(loaderActionReq(true));
  try {
    const resp = yield call(LogoutAll, action?.payload?.model);
    if (resp?.data?.respcode === 200) {
      sessionStorage.setItem("reloginToken" , resp?.data?.data)
      sessionStorage.removeItem("dataOtp")
      // toast.success(resp?.data?.respdesc);
      if (action?.payload?.callback) {
        action?.payload?.callback(resp);
        yield put(loaderActionReq(false));
      }
    }
    else {
      toast.error(resp?.data?.respdesc)
    }
  } catch (error) {
    if (error?.response) {
      // toast.error(error?.response?.data?.respdesc);
      console.log(
        error?.response?.data?.respdesc,
        "error?.response?.data?.respdesc"
      );
      yield put(loaderActionReq(false));
    }
  } finally {
    yield put(loaderActionReq(false));
  }
}

const Logout = (model, token) => {
  const URL = "account/logout";
  return Axios.post(URL, model, { tokenLogout: token }).then((res) => {
    return res;
  });
};

function* LogoutSaga(action) {
  yield put(loaderActionReq(true));
  try {
    const resp = yield call(Logout, action?.payload?.model, action?.payload?.token);
    if (resp?.data?.respcode === 200 || resp?.data?.respcode === 500) {
      toast.success(`Logout ${resp?.data?.respdesc}fully`);
      yield put(loaderActionReq(false));
      // if (action?.payload?.callback) {
      //   action?.payload?.callback(resp);
      // }
    }
    else {
      // toast.error(resp?.data?.respdesc)
    }
  } catch (error) {
    if (error?.response) {
      // toast.error(error?.response?.data?.respdesc);
      console.log(
        error?.response?.data?.respdesc,
        "error?.response?.data?.respdesc"
      );
      yield put(loaderActionReq(false));
    }
  } finally {
    yield put(loaderActionReq(false));
  }
}



// Reset Password
const resetPassword = (model, state) => {
  const URL = "account/set-new-password";
  return Axios.post(URL, model, { state: state }).then((res) => {
    return res;
  });
};

function* resetPasswordSaga(action) {
  yield put(loaderActionReq(true));
  try {
    const resp = yield call(resetPassword, action?.payload?.model, action?.payload?.state);
    // if (resp?.data?.respcode === 200) {
    yield put(loginSagaActionReducer(resp));
    if (action?.payload?.callback) {
      action?.payload?.callback(resp);
      yield put(loaderActionReq(false));
    }
  } catch (error) {
    if (error?.response) {
      toast.error(error?.response?.data?.respdesc);
      console.log(
        error?.response?.data?.respdesc,
        "error?.response?.data?.respdesc"
      );
      yield put(loaderActionReq(false));
    }
  } finally {
    yield put(loaderActionReq(false));
  }
}

const changePassword = (model, state) => {
  const URL = "account/reset-password";
  return Axios.post(URL, model).then((res) => {
    return res;
  });
};

function* changePasswordSaga(action) {
  yield put(loaderActionReq(true));
  try {
    const resp = yield call(changePassword, action?.payload?.model);
    // if (resp?.data?.respcode === 200) {
    yield put(loginSagaActionReducer(resp));
    if (action?.payload?.callback) {
      action?.payload?.callback(resp);
      yield put(loaderActionReq(false));
    }
  } catch (error) {
    if (error?.response) {
      toast.error(error?.response?.data?.respdesc);
      console.log(
        error?.response?.data?.respdesc,
        "error?.response?.data?.respdesc"
      );
      yield put(loaderActionReq(false));
    }
  } finally {
    yield put(loaderActionReq(false));
  }
}


// forgot password
const forgotPassword = (model) => {
  const URL = "account/forget-password";
  return Axios.post(URL, model).then((res) => {
    return res;
  });
};

function* forgotPasswordSaga(action) {
  yield put(loaderActionReq(true));
  try {
    const resp = yield call(forgotPassword, action?.payload?.model);
    // if (resp?.data?.respcode === 200) {
    // toast.success(resp?.data?.respdesc);
    if (action?.payload?.callback) {
      action?.payload?.callback(resp);
      yield put(loaderActionReq(false));
    }
    // }
    // else {
    //   toast.error(resp?.data?.respdesc)
    // }
  } catch (error) {
    if (error?.response) {
      toast.error(error?.response?.data?.respdesc);
      console.log(
        error?.response?.data?.respdesc,
        "error?.response?.data?.respdesc"
      );
      yield put(loaderActionReq(false));
    }
  } finally {
    yield put(loaderActionReq(false));
  }
}

// Resend OTP
const resendOtp = (model) => {
  const URL = "/account/resend-otp";
  return Axios.post(URL, model).then((res) => {
    return res;
  });
};

function* resendOtpSaga(action) {
  yield put(loaderActionReq(true));
  try {
    const resp = yield call(resendOtp, action?.payload?.model);
    // toast.success(resp?.data?.respdesc);
    if (resp?.data?.respcode === 200) {
      sessionStorage.setItem("resendToken", resp?.data?.data);
      if (action?.payload?.callback) {
        action?.payload?.callback(resp);
        yield put(loaderActionReq(false));
      }
      // }
      // else {
      //   toast.error(resp?.data?.respdesc)
    }
  } catch (error) {
    if (error?.response) {
      toast.error(error?.response?.data?.respdesc);
      console.log(
        error?.response?.data?.respdesc,
        "error?.response?.data?.respdesc"
      );
      yield put(loaderActionReq(false));
    }
  } finally {
    yield put(loaderActionReq(false));
  }
}


// DropDown APIs

const getAllAccountList = (model) => {
  const URL = "account/get/acc/name";
  return Axios.post(URL, model).then((res) => {
    return res;
  });
};

function* getAllAccountListSaga(action) {
  yield put(loaderActionReq(true));
  try {
    const resp = yield call(getAllAccountList, action?.payload?.model);
    yield put(getAdminNameReducer(resp?.data?.data))
    if (resp?.data?.respcode === 200) {
      // toast.success(resp?.data?.respdesc);
      if (action?.payload?.callback) {
        action?.payload?.callback(resp?.data?.data);
        yield put(loaderActionReq(false));
      }
    }
    else {
      action?.payload?.callback([]);
      toast.error(resp?.data?.respdesc)
    }
  } catch (error) {
    if (error?.response) {
      toast.error(error?.response?.data?.respdesc);
      console.log(
        error?.response?.data?.respdesc,
        "error?.response?.data?.respdesc"
      );
      yield put(loaderActionReq(false));
    }
  } finally {
    yield put(loaderActionReq(false));
  }
}

const getAllUsersList = (model) => {
  const URL = "account/get/user/name";
  return Axios.post(URL, model).then((res) => {
    return res;
  });
};

function* getAllUsersListSaga(action) {
  yield put(loaderActionReq(true));
  try {
    const resp = yield call(getAllUsersList, action?.payload?.model);
    if (resp?.data?.respcode === 200) {
      // toast.success(resp?.data?.respdesc);
      if (action?.payload?.callback) {
        action?.payload?.callback(resp?.data?.data);
        yield put(loaderActionReq(false));
      }
    }
    else {
      action?.payload?.callback([]);
      toast.error(resp?.data?.respdesc)
    }
  } catch (error) {
    action?.payload?.callback([]);
    if (error?.response) {
      toast.error(error?.response?.data?.respdesc);
      console.log(
        error?.response?.data?.respdesc,
        "error?.response?.data?.respdesc"
      );
      yield put(loaderActionReq(false));
    }
  } finally {
    yield put(loaderActionReq(false));
  }
}




export default function* LoginWatcherSaga() {
  yield takeEvery(LOGIN, LoginSaga);
  yield takeEvery(LOGIN_WITH_OTP, LoginWithOtpSaga);
  yield takeEvery(LOGOUTALL, LogoutAllSaga);
  yield takeEvery(LOGOUT, LogoutSaga);
  yield takeEvery(RESET_PASSWORD, resetPasswordSaga);
  yield takeEvery(FORGOT_PASSWORD, forgotPasswordSaga);
  yield takeEvery(GET_ACCOUNTS_NAME_LIST, getAllAccountListSaga);
  yield takeEvery(GET_USERS_NAME_LIST, getAllUsersListSaga);
  yield takeEvery(CHANGE_PASSWORD, changePasswordSaga);
  yield takeEvery(RESEND_OTP, resendOtpSaga);

  // yield takeEvery(ACTION_HIT_API , testingformSaga)
}
