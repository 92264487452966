import React from 'react'
// import Tiles from '../Tile/Tiles'
import cn from "classnames";
import styles from "./Dashboard.module.sass"


const Dashboard = () => {

    const nav = [
        {
            title: "Total Transactions",
            counter: "10",
            icon: "edit",
            color: "#B1E5FC",
        },
        {
            title: "Total Success Count",
            counter: "0",
            icon: "activity",
            color: "#CABDFF",
        },
        {
            title: "Total Failure Count",
            counter: "2",
            icon: "activity",
            color: "#CABDFF",
        },
    ];


    return (
        <>
            <div className={cn(styles.tile)}>
                <div className={cn(styles.tileBody)}>
                    <p>Dashboard</p>
                </div>
            </div>
        </>
    )
}

export default Dashboard