import React, { useState } from "react";
import cn from "classnames";
import styles from "./SignInForm.module.sass";
import { use100vh } from "react-div-100vh";
import { Link, useLocation, useNavigate } from "react-router-dom";
import TextInput from "../../TextInput";
import Image from "../../Image";
import { validateEmail } from "../../../Utils/Validations";
import { useDispatch } from "react-redux";
import {
  LoginActionSaga,
  LogoutAllActionSaga,
} from "../../../store/actions/LoginActions";
import { toast } from "react-hot-toast";
import Loader from "../../loader/Loader";
import Modal from "../../Modal";
// import { getOtpComponent } from "../../../store/actions/reducerActions/PanReducerActions";

const SignIn = ({ minHeightpan, className, noimg }) => {
  const heightWindow = use100vh();
  const dispatch = useDispatch();
  const naviTo = useNavigate();
  const [loginModel, setLoginModel] = useState({});
  const [loginModelErr, setLoginModelErr] = useState({});
  const location = useLocation();
  const [visibleModal, setVisibleModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false)
  const [reloginToken, setReloginToken] = useState("")
  const branding = process.env.REACT_APP_IMAGE



  if (location.pathname === "/" && !reloginToken) {
    sessionStorage.clear();
    localStorage.clear()
  }

  const handleLogin = (e) => {
    const { value, name } = e.target;
    if (name === "email") {
      const err = validateEmail(value);
      setLoginModelErr((prevState) => ({ ...prevState, [name]: err }));
    }
    setLoginModel((prevState) => ({ ...prevState, [name]: value }));
  };

  const logoutAllDevices = () => {
    dispatch(LogoutAllActionSaga({ model: "", callback: getLogout }));
    setVisibleModal(!visibleModal);
  };
  const getLogout = (data) => {
    if (data?.data?.respcode === 200) {
      toast('OTP has been successfully sent to your email.', {
        duration: 4000,
        position: 'top-right',

        // Styling
        style: {
          minWidth: '150px',
        },
        className: '',

        // Custom Icon
        icon: "✅",

        // Change colors of success/error/loading icon
        iconTheme: {
          primary: '#000',
          secondary: '#fff',
        },

        // Aria
        ariaProps: {
          role: 'status',
          'aria-live': 'polite',
        },
      });
      sessionStorage.setItem("useremail", loginModel.email);
      naviTo("/otp");
    }
  };

  const submitLogin = () => {
    const model = {
      useremail: loginModel.email,
      userpwd: loginModel.password,
    };
    let isTrue = Object.values(model).some(
      (v) => v === "" || v === undefined || v === null
    );
    let isErrTrue = Object.values(loginModelErr).every(
      (v) => v === "" || v === undefined || v === null
    );
    if (isTrue || !isErrTrue) {
      toast.error("please check all the inputs");
    } else {
      // dispatch(LoginActionSaga({ model: JSON.stringify(model), callback: getDataOfLogin }))
      dispatch(LoginActionSaga({ model: model, callback: getDataOfLogin }));
    }
  };



  const getDataOfLogin = (data) => {
    let respData = data?.data
    if (respData.respcode === 200) {
      sessionStorage.setItem("useremail", loginModel.email);
      toast('OTP has been successfully sent to your email.', {
        duration: 4000,
        position: 'top-right',

        // Styling
        style: {
          minWidth: '150px',
        },
        className: '',

        // Custom Icon
        icon: "✅",

        // Change colors of success/error/loading icon
        iconTheme: {
          primary: '#000',
          secondary: '#fff',
        },

        // Aria
        ariaProps: {
          role: 'status',
          'aria-live': 'polite',
        },
      });
      naviTo("/otp");
    }
    else if (data?.data?.respcode === 315) {
      setReloginToken(data?.data?.data)
      setVisibleModal(!visibleModal);
    }
    else {
      toast.error(respData.respdesc)
    }
  };


  // session content 
  const SessionContent = () => {
    return (
      <>
        <div className="d-flex flex-column">
          <h5 className="text-center">It seems like you are currently logged in to another session or browser.
            Are you certain that you want to clear all other sessions?
          </h5>
          <div className="d-flex justify-content-center align-items-center gap-3 mt-4">
            <button
              className={cn("button", styles.button)}
              onClick={logoutAllDevices}
            >
              Continue
            </button>
            <button
              onClick={() => setVisibleModal(!visibleModal)}
              className={cn("button", styles.button)}
            >
              Cancel
            </button>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <Loader />
      <div
        className={cn(styles.login, className)}
        style={{ minHeight: !minHeightpan ? heightWindow : null }}
      >
        <div className={styles.wrapper}>
          {!noimg && (
            <Link className={styles.logo} to="/">
              <Image
                className={styles.pic}
                src={branding === "SYN" ? "/images/logo.png" : "/images/trysakiLogo.png"}
                srcDark={branding === "SYN" ? "/images/logo.png" : "/images/trysakiLogo.png"}
                alt="image"
              />
            </Link>
          )}
          <div className={cn("hh2", styles.title1)}>Sign in</div>
          <div className={styles.body}>
            <TextInput
              className={styles.field}
              name="email"
              type="email"
              placeholder="Email ID"
              required
              icon="mail"
              onChange={handleLogin}
            />
            <p className={loginModelErr.email ? styles.loginModelErr : ""}>{loginModelErr.email}</p>
            <TextInput
              className={`${styles.field} password`}
              name="password"
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              required
              icon="lock"
              eye="eye"
              onChange={handleLogin}
              setShowPassword={setShowPassword}
              showPassword={showPassword}
            />
            <p>{loginModelErr.password}</p>
            <Link className={styles.fpassword} to="/forgot-password">
              Forgot Password?
            </Link>
            <button
              className={cn("button", styles.button)}
              onClick={submitLogin}
            >
              Sign in
            </button>
            {/* </Link> */}
            {!noimg && (
              <div className={styles.note}>
                Powered by {branding === "SYN" ? "Syntizen" : "Tryaski"} Technologies Pvt Ltd.
                {/* <h6>Powered by Syntizen Technologies Pvt Ltd.</h6> */}
              </div>
            )}
          </div>
        </div>
      </div>
      {
        visibleModal && (
          <Modal
            outerClassName={styles.sessionPopup}
            visible={visibleModal}
            style={{
              height: "40vh",
              boxShadow:
                "0px 0px 14px -4px rgba(0, 0, 0, 0.05), 0px 32px 48px -8px rgba(0, 0, 0, 0.1)",
            }}
            id="loginmodal"
            onClose={() => setVisibleModal(false)}
            // heading="Already Logged in Another Session/Browser. Are you sure want to clear all the other sessions?"
            closebtn={styles.sessionPopupBtn}
            children={<SessionContent />}
          >
            {/* <button
              style={{ marginLeft: "28%" }}
              className={cn("button", styles.button)}
              onClick={logoutAllDevices}
            >
              Yes
            </button>
            <button
              onClick={() => setVisibleModal(!visibleModal)}
              style={{ marginLeft: "10px" }}
              className={cn("button", styles.button)}
            >
              No
            </button> */}
          </Modal>
        )
      }
    </>
  );
};

export default SignIn;
