import toast from "react-hot-toast";
import { put, takeLatest, call, takeEvery } from "redux-saga/effects";
import Axios from "../../service/axios.jsx";
import {
  loaderActionReq,
} from "../actions/reducerActions/PanReducerActions";
import { DASHBOARD_ADMIN_COUNT, DASHBOARD_SUPER_ADMIN_COUNT, DASHBOARD_USER_COUNT } from "../sagaActionConstants/DashboardCountConstants.js";


const DashboardSuperAdmin = (model) => {
  const URL = "dashboard";
  return Axios.post(URL, model).then((res) => {
    return res;
  });
};

function* DashboardSuperAdminSaga(action) {
  yield put(loaderActionReq(true));
  try {
    const resp = yield call(DashboardSuperAdmin, action?.payload?.model);
    if (resp?.data?.respcode === 200) {
      if (action?.payload?.callback) {
        action?.payload?.callback(resp?.data?.data);
        yield put(loaderActionReq(false));
      }
    }
    else {
      toast.error(resp?.data?.respdesc);
    }
  } catch (error) {
    if (error?.response) {
      toast.error(error?.response?.data?.respdesc);
      console.log(
        error?.response?.data?.respdesc,
        "error?.response?.data?.respdesc"
      );
      yield put(loaderActionReq(false));
    }
  } finally {
    yield put(loaderActionReq(false));
  }
}

const DashboardAdmin = (model) => {
  const URL = "dashboard/admin";
  return Axios.post(URL, model).then((res) => {
    return res;
  });
};

function* DashboardAdminSaga(action) {
  yield put(loaderActionReq(true));
  try {
    const resp = yield call(DashboardAdmin, action?.payload?.model);
    // if (resp?.data?.respcode === 200) {
    if (action?.payload?.callback) {
      action?.payload?.callback(resp);
      yield put(loaderActionReq(false));
    }
    // }
    else {
      toast.error(resp?.data?.respdesc);
    }
  } catch (error) {
    if (error?.response) {
      toast.error(error?.response?.data?.respdesc);
      console.log(
        error?.response?.data?.respdesc,
        "error?.response?.data?.respdesc"
      );
      yield put(loaderActionReq(false));
    }
  } finally {
    yield put(loaderActionReq(false));
  }
}

const DashboardUser = (model) => {
  const URL = "dashboard/user";
  return Axios.post(URL, model).then((res) => {
    return res;
  });
};

function* DashboardUserSaga(action) {
  yield put(loaderActionReq(true));
  try {
    const resp = yield call(DashboardUser, action?.payload?.model);
    // if (resp?.data?.respcode === 200) {
    if (action?.payload?.callback) {
      action?.payload?.callback(resp);
      yield put(loaderActionReq(false));
    }
    // }
    else {
      toast.error(resp?.data?.respdesc);
    }
  } catch (error) {
    if (error?.response) {
      toast.error(error?.response?.data?.respdesc);
      console.log(
        error?.response?.data?.respdesc,
        "error?.response?.data?.respdesc"
      );
      yield put(loaderActionReq(false));
    }
  } finally {
    yield put(loaderActionReq(false));
  }
}

export default function* DashboardCountWatcherSaga() {
  yield takeEvery(DASHBOARD_SUPER_ADMIN_COUNT, DashboardSuperAdminSaga);
  yield takeEvery(DASHBOARD_ADMIN_COUNT, DashboardAdminSaga);
  yield takeEvery(DASHBOARD_USER_COUNT, DashboardUserSaga)
  // yield takeEvery(ACTION_HIT_API , testingformSaga)
}