import cn from "classnames";
import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getAccountsListActionSaga,
  getUsersListActionSaga,
} from "../../../../store/actions/LoginActions";
import Card from "../../../Card";
import Dropdown from "../../../Dropdown";
import SearchableDropdown from "../../../SearchableDropdown";
import styles from "./AddCredit.module.sass";
import Roles from "../../../../Constants/Roles";

const AddCredit = ({ click, adminNames, userNames }) => {
  const role = sessionStorage.getItem("role");
  const [accountNames, setAccountNames] = useState({ value: "", label: "" });
  const [optionList, setOptionList] = useState([]);
  const [accountNamesUser, setAccountNamesUser] = useState({
    value: "",
    label: "",
  });
  const [optionListUser, setOptionListUser] = useState([]);

  // const adminNamesList = useSelector((state) => state?.PanReducer?.adminNames)
  // console.log(adminNamesList, "sjlkfd");

  useEffect(() => {
    getUserDropdownList();
  }, []);

  const getUserDropdownList = () => {
    let optionListData =
      adminNames?.map((item) => ({
        value: item?.account_id,
        label: item?.account_name,
      })) || [];
    setAccountNames(optionListData[0]);
    setOptionList(optionListData);
    let optionListDataUser =
      userNames?.map((item) => ({
        value: item?.user_id,
        label: item?.user_name,
      })) || [];
    setAccountNamesUser(optionListDataUser[0]);
    setOptionListUser(optionListDataUser);
  };

  return (
    <>
      <Card
        className={cn(styles.card)}
        title="Update Credits"
        classTitle="title-purple"
      >
        <div className={styles.Updatecredit}>
          <SearchableDropdown
            options={role === Roles.SA ? optionList : optionListUser}
            selectedObj={
              role === Roles.SA ? accountNames : accountNamesUser
            }
            setValue={
              role === Roles.SA ? setAccountNames : setAccountNamesUser
            }
          />

          <button className={cn("button", styles.button)} onClick={click}>
            Update
          </button>
        </div>
      </Card>
    </>
  );
};

export default AddCredit;
