import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import "react-datepicker/dist/react-datepicker.css";
import "./styles/app.sass";
import Page from "./components/Page";
import Home from "./components/screens/Home";
import SignIn from "./components/screens/SignIn";
import Transactions from "./components/screens/Transactions";
import Reconciliation from "./components/screens/Transactions/Reconciliation";
import Pan from "./components/screens/Pan";
import About from "./components/screens/About";
import ManageOrganizations from "./components/screens/Organizations/ManageOrganizations";
import ManageAccounts from "./components/screens/Organizations/ManageAccounts";
import JoinasAdmin from "./components/JoinAdmin/JoinasAdmin";
import { Provider } from "react-redux";
import store from "./store/store";
import { Toaster } from "react-hot-toast";
import Dashboard from "./components/Home/Dashboard";
// import GetCredits from "./components/screens/Credits/GetCredit";
import Verify from "./components/Verify/Verify";
import TxnResp from "./components/TxnResp/TxnResp";
import { useEffect, useState } from "react";
import OtpInput from "./components/EnterOtp/OtpInput";
import ForgotPassword from "./components/screens/ForgotPassword/ForgotPassword";
import ChangePassword from "./components/screens/ChangePassword";
import SetNewPassword from "./components/screens/SetNewPassword";
import ResetPassword from "./components/screens/ResetPassword";
import AddCredit from "./components/screens/Credits/AddCredit/AddCredit";
import GetCredits from "./components/screens/Credits/GetCredit/GetCredits"
import PageNotFound from "./components/PageNotFound/PageNotFound";

function App() {
  sessionStorage.setItem("check", true)
  const role = sessionStorage.getItem("role")
  const [hideRoute, setHideRoute] = useState(false)
  // const isAuthenticated = true
  // useEffect(() => {
  //   // Checking if the token exists in sessionStorage
  //   const token = sessionStorage.getItem('check');

  //   if (!token) {
  //     // Token does not exist, redirect or show an error message
  //     // For example:
  //     window.location.href = '/'; // Redirect to login page
  //   }
  // }, []);

  const hide = () => {
    switch (role) {
      case "SuperAdmin":
        return setHideRoute(false)
      default:
        return setHideRoute(true)
    }
  }

  useEffect(() => {
    hide()
  }, [])



  const pageName = (role) => {
    switch (role) {
      case "SuperAdmin":
        return "Accounts Management"
      case "Admin":
        return "Users Management"
      default:
        return null
    }
  }


  return (
    <>
      <Toaster
        containerStyle={{
          zIndex: "9999999",
        }}
        toastOptions={{
          duration: 1200,
        }}
      />
      <Provider store={store}>
        <Router>
          <Routes>
            <Route
              exact
              path="/"
              element={
                <SignIn />
              }
            />
            <Route
              exact
              path="/otp"
              element={
                <OtpInput />
              }
            />
            <Route
              exact
              path="/forgot-password"
              element={
                <ForgotPassword />
              }
            />
            <Route
              exact
              path="/new-password"
              element={
                <SetNewPassword />
              }
            />
            <Route
              exact
              path="/reset-password/:token"
              element={
                <ResetPassword />
              }
            />
            <Route
              exact
              path="/change-password"
              element={
                <Page title="">
                  <ChangePassword />
                </Page>
              }
            />
            <Route
              exact
              path="/verify/:token"
              element={
                <Verify />
              }
            />
            <Route
              exact
              path="/transactions"
              element={
                <Page title="Transaction Summary">
                  <Transactions />
                </Page>
              }
            />
            <Route
              exact
              path="/reconciliation"
              element={
                <Page title="Reconciliation Summary">
                  <Reconciliation />
                </Page>
              }
            />
            <Route
              exact
              path="/pan/new"
              element={
                <Page title="New Pan Transactions">
                  <Pan type="new" />
                </Page>
              }
            />
            <Route
              exact
              path="/pan/correction"
              element={
                <Page title="Pan Correction">
                  <Pan type="correction" />
                </Page>
              }
            />
            <Route
              exact
              path="/pan/status"
              element={
                <Page title="PAN Check Status">
                  <Pan type="status" />
                </Page>
              }
            />
            <Route
              exact
              path="/accounts/organizations"
              element={
                <Page title="Organization Management">
                  <ManageOrganizations />
                </Page>
              }
            />
            <Route
              exact
              path="/accounts/list"
              element={
                <Page title={pageName(role)}>
                  <ManageAccounts />
                </Page>
              }
            />
            {/* <Route
              exact
              path="/about"
              element={
                <Page title="">
                  <About />
                </Page>
              }
            /> */}

            {hideRoute &&
              <Route exact path="/txn/:id" element={<TxnResp />} />
            }
            <Route exact path="/home"
              element={
                <Page title="Home">
                  <Home />
                </Page>
              }
            />
            <Route exact path="/admin-registration"
              element={
                <Page title="Join as Admin">
                  <JoinasAdmin />
                </Page>
              }
            />
            <Route exact path="/dashboard"
              element={
                <Page title="">
                  <Dashboard />
                </Page>
              }
            />
            <Route exact path="/creditHistory"
              element={
                <Page title="Credit Management">
                  <GetCredits />
                </Page>
              }
            />
            <Route exact path="/addcredits"
              element={
                <Page title="Update Credits">
                  <AddCredit />
                </Page>
              }
            />
            <Route path="*"
              element={
                <PageNotFound />
              }
            />

          </Routes>
        </Router>
      </Provider>
    </>
  );
}

export default App;
