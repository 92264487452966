export const ADD_CREDITS = "ADD_CREDITS"

export const GET_CREDITS = "GET_CREDITS"

export const GENERATE_SLK = "GENERATE_SLK"

export const ACTIVATE_SLK = "ACTIVATE_SLK"

export const DEACTIVATE_SLK = "DEACTIVATE_SLK"

export const TRANSFER_CREDITS = "TRANSFER_CREDITS"

export const SHOW_ADMIN_CREDITS = "SHOW_ADMIN_CREDITS"

export const SUPER_ADMIN_TRANSFER_CREDITS = "SUPER_ADMIN_TRANSFER_CREDITS"

export const GET_SLK_DETAILS = "GET_SLK_DETAILS"

export const SLK_SERVICES = "SLK_SERVICES"

