import { combineReducers } from 'redux';
import PanReducer from './PanReducer';


const rootReducer = combineReducers({
    // DashboardReducer: DashboardReducer,
    // GlobalRecuder: GlobalRecuder
    PanReducer: PanReducer
})

export default rootReducer;