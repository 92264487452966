import { CHANGE_PASSWORD, FORGOT_PASSWORD, GET_ACCOUNTS_NAME_LIST, GET_USERS_NAME_LIST, LOGIN, LOGIN_WITH_OTP, LOGOUT, LOGOUTALL, RESEND_OTP, RESET_PASSWORD } from "../sagaActionConstants/LoginConstants"

export const LoginActionSaga = (model) => {
    return {
        type: LOGIN,
        payload: model
    }
}
export const LogoutAllActionSaga = (model) => {
    return {
        type: LOGOUTALL,
        payload: model
    }
}

export const LogoutActionSaga = (model) => {
    return {
        type: LOGOUT,
        payload: model
    }
}

export const LoginWithOtpActionSaga = (model) => {
    return {
        type: LOGIN_WITH_OTP,
        payload: model
    }
}

export const resetPasswordActionSaga = (model) => {
    return {
        type: RESET_PASSWORD,
        payload: model
    }
}

export const changePasswordActionSaga = (model) => {
    return {
        type: CHANGE_PASSWORD,
        payload: model
    }
}


export const forgotPasswordActionSaga = (model) => {
    return {
        type: FORGOT_PASSWORD,
        payload: model
    }
}

export const resendOtpActionSaga = (model) => {
    return {
        type: RESEND_OTP,
        payload: model
    }
}


export const getAccountsListActionSaga = (model) => {
    return {
        type: GET_ACCOUNTS_NAME_LIST,
        payload: model
    }
}

export const getUsersListActionSaga = (model) => {
    return {
        type: GET_USERS_NAME_LIST,
        payload: model
    }
}