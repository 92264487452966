export function validateEmail(value) {
  let error;
  if (!value || value === undefined || value.toString().trim() === "") {
    error = "Required";
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,3}$/i.test(value)) {
    error = "Invalid Email Address";
  }
  return error;
}

export function validateIsRequired(value) {
  let error;
  if (value === null || value === undefined || value.toString().trim() === "") {
    error = "Required";
  }

  return error;
}

export function validateIsNumeric(value) {
  const numericPattern = new RegExp(/^[0-9]*$/);
  const Num_Valid = "012345";
  const Unvalid = Num_Valid.includes(value.charAt());
  let error;
  if (!numericPattern.test(value)) {
    error = "Invalid Number";
  }
  if (value.length >= 11 || Unvalid) {
    error = "Please enter valid mobile number";
  }
  return error;
}

export function minOrMaxLength(value, minOrMax, length) {
  let error;

  if (
    value !== undefined &&
    minOrMax === "minlength" &&
    value.length < length
  ) {
    error =
      "Minimum Length " +
      length
        .toString()
        .split("")
        .reduce((prevValue, currentValue) => {
          return prevValue + "" + currentValue;
        }, "");
  } else if (
    value !== undefined &&
    minOrMax === "maxlength" &&
    value.length > length
  ) {
    error =
      "Maximum Length " +
      length
        .toString()
        .split("")
        .reduce((prevValue, currentValue) => {
          return prevValue + "" + currentValue;
        }, "");
  }

  return error;
}

export function validateIsNumAndAlp(value) {
  let error;
  const numericPattern = new RegExp(
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/
  );
  if (!numericPattern.test(value)) {
    error =
      "Password should have one upper-case, one lower-case, a digit and a non-alphanumeric character";
  } else {
    error = "";
  }

  return error;
}

export function validatePhoneNumber(value) {
  const numericPattern = new RegExp(/^[0-9]*$/);
  const Num_Valid = "012345";
  const Unvalid = Num_Valid.includes(value.charAt());
  let error;
  if (!numericPattern.test(value)) {
    error = "Invalid Number";
  }
  if (value.length > 10 || Unvalid) {
    error = "Please enter valid mobile number";
  }
  return error;
}

export function validatePassword(value) {
  if (!value || value === undefined || value.toString().trim() === "") {
    return "Required";
  }
  if (value.length < 8) {
    return "Must contain 8 characters";
  } else if (!/[\W_]/.test(value)) {
    return "Must contain 1 special character";
  } else if (!/[A-Z]/.test(value)) {
    return "Must contain 1 Capital letter";
  }
  return "";
}

export function validatealphaNumeric(value) {
  let error;
  if (!value || value == undefined || value.toString().trim() === "") {
    error = "Required";
  } else if (!/^[a-zA-Z ]*$/.test(value)) {
    error = "Please enter only alphabets";
  }
  return error;
}

export const validateInput = (name, value, resetModel, setResetModelErr) => {
  switch (name) {
    case "password":
      if (!value) {
        setResetModelErr((prevState) => ({
          ...prevState,
          password: "Required",
        }));
      } else if (value.length < 8) {
        setResetModelErr((prevState) => ({
          ...prevState,
          password: "Password must be at least 8 characters",
        }));
      } else if (value === resetModel.oldpassword) {
        setResetModelErr((prevState) => ({
          ...prevState,
          password: "New password must not be same as old password",
        }));
      } else {
        setResetModelErr((prevState) => ({
          ...prevState,
          password: "",
        }));
      }
      break;
    case "confirmpassword":
      if (!value) {
        setResetModelErr((prevState) => ({
          ...prevState,
          confirmpassword: "Required",
        }));
      } else if (value !== resetModel.password) {
        setResetModelErr((prevState) => ({
          ...prevState,
          confirmpassword: "Passwords do not match",
        }));
      } else {
        setResetModelErr((prevState) => ({
          ...prevState,
          confirmpassword: "",
        }));
      }
      //   case "currentpassword":
      //   if (!resetModel.currentpassword) {
      //     setResetModelErr((prevState) => ({
      //       ...prevState,
      //       password: "Required",
      //     }));
      //   }
      break;
    default:
      break;
  }
};
