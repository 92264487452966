import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { verifyEmailSagaAction } from '../../store/actions/PanActions';
import { loaderActionReq } from '../../store/actions/reducerActions/PanReducerActions';
import Card from '../Card';
import Loader from '../loader/Loader';
import styles from "./Verify.module.sass"


const Verify = () => {
    const params = useParams();
    const dispatch = useDispatch()
    const verifyToken = params?.token
    const [verifiedModal, setVerifiedModal] = useState(false)
    const naviTo = useNavigate();

    useEffect(() => {
        dispatch(verifyEmailSagaAction({ model: "", callback: redirectReset, token: verifyToken }))
    }, [])

    const redirectReset = (data) => {
        const respcode = data?.data?.respcode
        const resetToken = data?.data?.data
        if (respcode === 200) {
            naviTo("/new-password", { state: resetToken })
        } else if (respcode === 319)
            return setVerifiedModal(true)
        else {
            return toast.error("something went wrong")
        }
    }

    return (
        <>
            <Loader />
            {verifiedModal &&
                <>
                    <div className={styles.verify}>
                        <h2 className={styles.verify_acc}>Your Account has been Verified</h2>
                    </div>
                </>

            }
        </>
    )
}

export default Verify