import React, { useEffect, useState } from "react";
import cn from "classnames";
import TextInput from "../../../../TextInput";
import styles from "./ManageLicence.module.sass";
import { useDispatch } from "react-redux";
import { activateSLKActionSaga, deactivateSLKActionSaga, generateSLKActionSaga, slkServicesActionSaga } from "../../../../../store/actions/CreditsAction";
import { toast } from "react-hot-toast";
import moment from "moment";
import NoTableData from "../../../../NoTableData";
import noDataImg from "../../../../../assests/Images/svgexport-1.svg";
import DataTable from "react-data-table-component";
import Icon from "../../../../Icon";

const ManageLicenences = ({ tableData }) => {
  const today = new Date().toISOString().split('T')[0];
  const dispatch = useDispatch()
  const [updateModel, setUpdateModel] = useState("")
  const [updateErr, setUpdateErr] = useState("")
  const [inputValue, setInputValue] = useState('');
  const [generateSlkDetails, setGenerateSlkDetails] = useState("")
  const [slkCopy, setSlkCopy] = useState("")
  const [checkStatus, setCheckStatus] = useState("")


  useEffect(() => {
    slkService()
  }, [])

  function epoch(date) {
    return Date.parse(date);
  }

  const handlevalidityChange = (e) => {
    const { value, name } = e.target
    const datetime = moment().format("YYYY-MM-DD");
    const timestamp = epoch(datetime);
    setInputValue(value);
    if (name === "validity") {
      if (epoch(value) < timestamp) {
        const isError = "Past date should not be Selected";
        setUpdateErr((prevState) => ({ ...prevState, [name]: isError }));
        setUpdateModel((prevState) => ({ ...prevState, [name]: value }));
      } else {
        setUpdateModel((prevState) => ({ ...prevState, [name]: value }));
        setUpdateErr((prevState) => ({ ...prevState, [name]: "" }));
      }
    }
    setUpdateModel((prevState) => ({ ...prevState, [name]: value }))
  }

  const generateslkAction = () => {
    const model = {
      "license_validity": updateModel?.validity,
      "remarks": "",
      "acc_id": tableData?.account_id
    }
    dispatch(generateSLKActionSaga({ model: model, callback: getGenerateSlkDataNope }))
  }

  const getGenerateSlkDataNope = (data) => {
    if (data?.data?.respcode === 200) {
      setSlkCopy(data?.data?.data?.license_key)
      slkService()
    }
  }


  const slkService = () => {
    const model = {
      "acc_id": tableData?.account_id
    }
    dispatch(slkServicesActionSaga({ model: model, callback: getGenerateSlkData }))
  }

  const getGenerateSlkData = (data) => {
    if (data?.data?.respcode === 200) {
      setGenerateSlkDetails(data?.data?.data)
      setCheckStatus(data?.data?.data[0].access_type)
      setSlkCopy(data?.data?.data[0].license_key)
    }
  }


  const date1 = moment(new Date());
  const date2 = moment(updateModel?.validity)
  const diffInDays = date2.diff(date1, 'days');



  const activateSLK = () => {
    const model = {
      "acc_id": tableData?.account_id
    }
    switch (checkStatus) {
      case 0:
        return dispatch(activateSLKActionSaga({ model: model, callback: getActivateSlkData }))
      default:
        return dispatch(deactivateSLKActionSaga({ model: model, callback: getActivateSlkData }))
    }
    // dispatch(activateSLKActionSaga({ model: model, callback: getActivateSlkData }))
  }
  const getActivateSlkData = (data) => {
    toast.success(data?.data?.respdesc)
    slkService()
  }



  const handleCopy = () => {
    navigator.clipboard.writeText(slkCopy);
    toast.success("SLK Copied")
  }

  // deactivateSLKActionSaga



  const columns = [
    {
      name: "Licence Key",
      selector: (row) =>
      (
        <p className="fw-bold">{row.license_key || "N/A"}
          <span style={{ marginLeft: "8px", cursor: "pointer" }} onClick={handleCopy}><Icon name="copy" size="20" /></span>
        </p>
      ),
      width: "220px"
    },
    {
      name: "Validity",
      selector: (row) => moment(row.license_validity).format("ll") || "N/A",
    },
    {
      name: "Remaining Days",
      selector: () => diffInDays || "N/A",
      textAlign: "center"
    },
    {
      name: "Generated Date",
      selector: (row) => moment(row.generated_on).format("ll") || "N/A",
    },
    {
      name: "Status",
      selector: (row) => row.access_type === 1 ? <span className="status-green">Active</span> : <span className="status-red">Inactive</span> || "N/A",
    },
    // {
    //   name: "Action",
    //   selector: () => <span className="ml-6" style={{ cursor: "pointer", textDecoration: "underline" }}>Copy SLK</span>,
    // },
  ];


  return (
    <div className={styles.container}>
      <div className={styles.row}>
        <div className={styles.col}>
          <div className={styles.rowField}>
            <TextInput
              className={cn(styles.field, "mb-0")}
              label="Validity"
              name="validity"
              type="date"
              tooltip="This field is required"
              required
              range="min"
              onChange={handlevalidityChange}
            />
            <p className={styles.createErr}>{updateErr.validity}</p>
          </div>
          <div className={styles.buttonContainer}>
            <button className={cn("button", styles.button)} onClick={generateslkAction} disabled={inputValue.length < 1}>
              Generate Licence
            </button>
          </div>
          <div className={styles.rowField} style={{ marginTop: 30 }}>
            Current Licence Details
          </div>
          {/* <div className={styles.wrapper}>
            <div className={styles.table}>
              <div className={styles.rowTable}>
                {headers.map((header) => (
                  <div className={styles.colTable} key={header.label}>
                    {header.label}
                  </div>
                ))}
              </div>
              {data.map((x, index) => (
                <div className={styles.rowTable} key={index}>
                  <div className={styles.colTable}>{x.licenceKey}</div>
                  <div className={styles.colTable}>{x.validity}</div>
                  <div className={styles.colTable}>{x.remainingDays}</div>
                  <div className={styles.colTable}>{x.generatedDate}</div>
                  <div className={styles.colTable}>{x.access_type}</div>
                </div>
              ))}
            </div>
          </div> */}
          {generateSlkDetails.length > 0 ? (
            <>
              <DataTable
                columns={columns}
                data={generateSlkDetails}
                theme="solarized"
              />
              <div className={cn("mt-3", styles.buttonContainer)}>
                {<button className={cn("button", styles.button)} onClick={activateSLK}>
                  {checkStatus === 0 ? "Activate" : "Deactivate"}
                </button>}
              </div>
            </>
          ) : (
            <NoTableData
              image={noDataImg}
              heading="No SLK Generated"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ManageLicenences;
