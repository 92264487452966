import React from "react";
import styles from "./NoTableData.module.sass";

const NoTableData = ({image , children , heading , semiheading}) => {
  return (
    <div className={styles.img_container}>
      {image && <img src={image} alt="Image" />}
      <h3>{heading}</h3>
      <p>{semiheading}</p>
      {children}
    </div>
  );
};

export default NoTableData