// Pan submit 
export const ACTION_NEW_PAN_SUBMIT = "NEW_PAN_SUBMIT"

export const ACTION_PAN_CORRECTION_SUBMIT = "ACTION_PAN_CORRECTION_SUBMIT"

export const ACTION_PAN_STATUS_SUBMIT = "ACTION_PAN_STATUS_SUBMIT"

export const CREATE_ACCOUNT_REQ = "CREATE_ACCOUNT_REQ"

export const CREATE_USER_ACCOUNT_REQ = "CREATE_USER_ACCOUNT_REQ"

export const GET_ACCOUNTS_LIST = "GET_ACCOUNT_LIST"

export const GET_USERS_LIST = "GET_USERS_LIST"

export const EDIT_USERS = "EDIT_USERS"

export const EDIT_ADMIN_USERS = "EDIT_ADMIN_USERS"

export const DELETE_ACCOUNT_LIST = "DELETE_ACCOUNT_LIST"

export const DELETE_USER_LIST = "DELETE_USER_LIST"

export const VERIFY_EMAIL = "VERIFY_EMAIL"

