import {
    RECONCILIATION_SUMMARY_ACTION,
    TRANSACTION_RESPONSE_SUMMARY,
    TRANSACTION_SUMMARY,
    TRANSACTION_SUMMARY_ADMIN,
    TRANSACTION_SUMMARY_ADMIN_POST_API,
    TRANSACTION_SUMMARY_SUPER_ADMIN_POST_API,
    TRANSACTION_SUMMARY_USER,
    TRANSACTION_SUMMARY_USER_POST_API
} from "../sagaActionConstants/TransactionsConstants"



export const transactionSummaryAction = (payload) => {
    return {

        type: TRANSACTION_SUMMARY,
        payload: payload
    }
}

export const transactionSummaryAdminAction = (payload) => {
    return {

        type: TRANSACTION_SUMMARY_ADMIN,
        payload: payload
    }
}

export const transactionSummaryUserAction = (payload) => {
    return {

        type: TRANSACTION_SUMMARY_USER,
        payload: payload
    }
}
export const reconciliationSummaryAction = (payload) => {
    return {

        type: RECONCILIATION_SUMMARY_ACTION,
        payload: payload
    }
}

// Post APi transaction summary

export const transactionSummarySuperAdminPostAction = (payload) => {
    return {

        type: TRANSACTION_SUMMARY_SUPER_ADMIN_POST_API,
        payload: payload
    }
}

export const transactionSummaryAdminPostAction = (payload) => {
    return {

        type: TRANSACTION_SUMMARY_ADMIN_POST_API,
        payload: payload
    }
}

export const transactionSummaryUserPostAction = (payload) => {
    return {

        type: TRANSACTION_SUMMARY_USER_POST_API,
        payload: payload
    }
}


export const transactionResponseSummaryAction = (payload) => {
    return {

        type: TRANSACTION_RESPONSE_SUMMARY,
        payload: payload
    }
}