import React, { useState } from "react";
import cn from "classnames";
import styles from "./Header.module.sass";
import Icon from "../Icon";
import Search from "./Search";
import User from "./User";
import { Link } from "react-router-dom";

const Header = ({ onOpen, className }) => {
  const [visible, setVisible] = useState(false);
  const handleClick = () => {
    onOpen();
    setVisible(false);
  };

  return (
    <header className={cn(styles.header, className)}>
      <button className={styles.burger} onClick={() => handleClick()}></button>
      <>
        <div className={styles.control} onClick={() => setVisible(false)}>
          <User className={styles.user} />
        </div>
      </>
      {/* {menu && <div className={cn([styles.control, styles.cus_menu])} onClick={() => setVisible(false)}>
        {headerMenu.map((item, index) => {
          return (
            <Link className={cn("button", styles.button , styles.menuBtn)} to={item?.url} key={index}>
              <span style={{ fontSize: "12px" }}>{item?.title}</span>
            </Link>
          )
        })}
      </div>} */}
    </header>
  );
};

export default Header;
