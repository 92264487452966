export const TRANSACTION_SUMMARY = "TRANSACTION_SUMMARY"

export const TRANSACTION_SUMMARY_ADMIN = "TRANSACTION_SUMMARY_ADMIN"

export const TRANSACTION_SUMMARY_USER = "TRANSACTION_SUMMARY_USER"

export const RECONCILIATION_SUMMARY_ACTION = "RECONCILIATION_SUMMARY_ACTION"

export const TRANSACTION_SUMMARY_USER_POST_API = "TRANSACTION_SUMMARY_USER_POST_API"

export const TRANSACTION_SUMMARY_ADMIN_POST_API = "TRANSACTION_SUMMARY_ADMIN_POST_API"

export const TRANSACTION_SUMMARY_SUPER_ADMIN_POST_API = "TRANSACTION_SUMMARY_SUPER_ADMIN_POST_API"


export const TRANSACTION_RESPONSE_SUMMARY = "TRANSACTION_RESPONSE_SUMMARY"




