import React, { useState } from "react";
import cn from "classnames";
import styles from "../SignIn/./SignInForm.module.sass";
import { use100vh } from "react-div-100vh";
import { Link, useLocation, useNavigate } from "react-router-dom";
import TextInput from "../../TextInput";
import Image from "../../Image";
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import Loader from "../../loader/Loader";
// import SuccessModal from "../../SuccessModal/SuccessModal";
import Modal from "../../Modal";
import Icon from "../../Icon";
import { validateInput } from "../../../Utils/Validations";
import { changePasswordActionSaga } from "../../../store/actions/LoginActions";
// import Modal from "../../Modal";

const ChangePassword = ({ minHeightpan, className, noimg, navigate , closeModal }) => {
  const heightWindow = use100vh();
  const dispatch = useDispatch();
  const naviTo = useNavigate();
  const [changeModel, setChangeModel] = useState({});
  const [changeModelErr, setChangeModelErr] = useState({
    oldpassword: "",
    password: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordone, setShowPasswordone] = useState(false);
  const [showPasswordtwo, setShowPasswordtwo] = useState(false);
  // const branding = process.env.REACT_APP_IMAGE

  const handleChange = (e) => {
    const { value, name } = e.target;
    setChangeModel((prevState) => ({ ...prevState, [name]: value }));
    validateInput(name, value, changeModel, setChangeModelErr);
  };

  const submitChange = () => {
    const model = {
      oldPassword: changeModel.oldpassword,
      newPassword: changeModel.password,
    };
    console.log(model , "modellll");
    let isTrue = Object.values(model).some(
      (v) => v === "" || v === undefined || v === null
    );
    let isErrTrue = Object.values(changeModelErr).every(
      (v) => v === "" || v === undefined || v === null
    );
    if (isTrue || !isErrTrue) {
      toast.error("please check all the inputs");
    } else {
      // return setOnSuccess(true)
      dispatch(changePasswordActionSaga({ model: model, callback: getDataOfChangePassword }))
    }
  };

  const getDataOfChangePassword = (data) => {
    let respData = data?.data
    if (respData.respcode === 200) {
      toast.success(respData?.respdesc)
      // naviTo("/");
      closeModal()
    }
    else {
      toast.error(respData.respdesc)
    }
  };

  return (
    <>
      <Loader />

      <div
        className={cn(styles.login, className)}
        // style={{ minHeight: !minHeightpan ? 380 : null }}
      >
        <div className={cn("mt-6", styles.wrapper)}>
          <div className={cn("h4 mb-4", styles.title1)} style={{fontSize:"30px"}}>Change Password</div>
          <div className={styles.body}>
            <TextInput
              className={styles.fieldforgot}
              name="oldpassword"
              type={showPassword ? "text" : "password"}
              placeholder="Current Password"
              required
              icon="lock"
              eye="eye"
              onChange={handleChange}
              setShowPassword={setShowPassword}
              showPassword={showPassword}
              autocomplete="off"
            />
            <div className={styles.copy}>
            </div>
            {/* <p>{changeModelErr.password}</p> */}
            <TextInput
              className={styles.fieldforgot}
              name="password"
              type={showPasswordone ? "text" : "password"}
              placeholder="New Password"
              required
              icon="lock"
              eye="eye"
              onChange={handleChange}
              setShowPasswordone={setShowPasswordone}
              showPasswordone={showPasswordone}
            /><div className={styles.copy}>
            </div>
            <p className={changeModelErr.password ? styles.loginModelErr : ""}>{changeModelErr.password}</p>
            <TextInput
              className={styles.fieldforgot}
              name="confirmpassword"
              type={showPasswordtwo ? "text" : "password"}
              placeholder="Confirm Password"
              required
              icon="lock"
              eye="eye"
              onChange={handleChange}
              setShowPasswordtwo={setShowPasswordtwo}
              showPasswordtwo={showPasswordtwo}
            /><div className={styles.copy}>
            </div>
            <p className={changeModelErr.confirmpassword ? styles.loginModelErr : ""}>{changeModelErr.confirmpassword}</p>
            <button
              className={cn("button mt-3", styles.button)}
              onClick={submitChange}
            >
              <span style={{fontSize:"14px"}}>Submit</span>
            </button>
          </div>
          {/* <div className={styles.backbtn}>
            <Link
              className={cn("button-stroke button-small", styles.button)}
              to="/home"
            >
              <Icon name="arrow-left" size="24" />
              <span>Back</span>
            </Link>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default ChangePassword;
