import React from 'react'
import { useSelector } from 'react-redux'
import styles from "./Loader.module.sass"

const Loader = ({dispname}) => {

    const loading = useSelector((state) => state?.PanReducer?.loader)
    return (
        <>
            {loading &&
                <div className={styles.loader_bg}>
                    {/* <div className={`${loading ? "loader" : null}`}>
                    </div> */}
                    <div className={styles.ldsring}>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <p className={styles.loader_name}>{dispname}</p>
                </div>
            }

        </>
    )
}

export default Loader