import { ACTIVATE_SLK, ADD_CREDITS, DEACTIVATE_SLK, GENERATE_SLK, GET_CREDITS, GET_SLK_DETAILS, SHOW_ADMIN_CREDITS, SLK_SERVICES, SUPER_ADMIN_TRANSFER_CREDITS, TRANSFER_CREDITS } from "../sagaActionConstants/CreditsConstants"



export const AddCreditsActionSaga = (payload) => {
    return {
        type: ADD_CREDITS,
        payload: payload
    }
}
export const getCreditsActionSaga = (payload) => {
    return {
        type: GET_CREDITS,
        payload: payload
    }
}

export const generateSLKActionSaga = (payload) => {
    return {
        type: GENERATE_SLK,
        payload: payload
    }
}

export const activateSLKActionSaga = (payload) => {
    return {
        type: ACTIVATE_SLK,
        payload: payload
    }
}

export const deactivateSLKActionSaga = (payload) => {
    return {
        type: DEACTIVATE_SLK,
        payload: payload
    }
}

export const transferCreditsActionSaga = (payload) => {
    return {
        type: TRANSFER_CREDITS,
        payload: payload
    }
}
export const showAdminCreditsActionSaga = (payload) => {
    return {
        type: SHOW_ADMIN_CREDITS,
        payload: payload
    }
}
export const superAdminTransferCreditsActionSaga = (payload) => {
    return {
        type: SUPER_ADMIN_TRANSFER_CREDITS,
        payload: payload
    }
}
export const getSLKDetailsActionSaga = (payload) => {
    return {
        type: GET_SLK_DETAILS,
        payload: payload
    }
}
export const slkServicesActionSaga = (payload) => {
    return {
        type: SLK_SERVICES,
        payload: payload
    }
}
