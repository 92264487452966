import React, { useEffect, useRef, useState } from "react";
import Card from "../../../Card";
import ExportArea from "../../Organizations/ActionArea";
import SearchTextInput from "../../../SearchTextInput";
import cn from "classnames";
import styles from "./GetCredits.module.sass";
import DataTable, { createTheme } from "react-data-table-component";
import { useDispatch } from "react-redux";
import { getCreditsActionSaga, showAdminCreditsActionSaga, superAdminTransferCreditsActionSaga, transferCreditsActionSaga } from "../../../../store/actions/CreditsAction";
// import AddCredit from "../AddCredit/AddCredit";
import Modal from "../../../Modal";
import TextInput from "../../../TextInput";
import Icon from "../../../Icon";
import {
  getAccountsListActionSaga,
  getUsersListActionSaga,
} from "../../../../store/actions/LoginActions";
import NoTableData from "../../../NoTableData";
import noDataImg from "../../../../assests/Images/svgexport-1.svg";
import Loader from "../../../loader/Loader";
import moment from "moment";
import SearchableDropdown from "../../../SearchableDropdown";
import { toast } from "react-hot-toast";
import Roles from "../../../../Constants/Roles";

createTheme("solarized", {
  text: {
    primary: "#6d757d",
    secondary: "#6d757d",
  },
  background: {
    default: "transparent",
  },
});

const GetCredits = () => {
  const role = sessionStorage.getItem("role");
  const [search, setSearch] = useState("");
  const pdfAreaRef = useRef(null);
  const dispatch = useDispatch();
  const [creditHistory, setCreditHistory] = useState("");
  const [updateCreditsPopup, setUpdateCreditsPopup] = useState(false);
  const [superAdminCreditModal, setSuperAdminCreditModal] = useState(false);
  const [adminCreditModal, setAdminCreditModal] = useState(false);
  const [showCredits, setShowCredits] = useState(false);
  const [filteredCreditData, setfilteredCreditData] = useState([]);
  const [creditUsage, setCreditUsage] = useState(true);
  const [tranferValue, setTranferValue] = useState("")
  const [creditValue, setCreditValue] = useState("")
  const [adminTotalBalance, setAdminTotalBalance] = useState([])
  const [type, setType] = useState("")
  const [disabled, setDisabled] = useState()
  const [optionListData, setOptionListData] = useState([]);
  const [selectedOption, setSelectedOption] = useState({ value: "", label: "" });
  // const [adminNames, setAdminNames] = useState({ value: "", label: "" });
  // const [userNames, setUserNames] = useState({ value: "", label: "" });
  // const [optionList, setOptionList] = useState([]);
  // const [optionListUser, setOptionListUser] = useState([]);

  const creditSearch = (e) => {
    const searchText = e.target.value;
    setSearch(searchText);
    if (searchText) {
      const filteredAdminCreditList = creditHistory?.filter(
        (credit) =>
          credit?.from_user?.toLowerCase().includes(searchText.toLowerCase()) ||
          credit?.to_user?.toLowerCase().includes(searchText.toLowerCase()) ||
          credit?.credits.toString().includes(searchText) ||
          credit?.type?.toLowerCase().includes(searchText.toLowerCase()) ||
          credit?.available_credits?.toString()?.includes(searchText) ||
          moment(credit?.expiry_date)
            .format("lll")
            .toLowerCase()
            .includes(searchText.toLowerCase())
      );
      setfilteredCreditData([...filteredAdminCreditList]);
    } else {
      setfilteredCreditData([...creditHistory]);
    }
  };


  useEffect(() => {
    switch (role) {
      case "SuperAdmin":
        return dispatch(
          getAccountsListActionSaga({ callback: checkNames })
        );
      case "Admin":
        return (
          dispatch(getUsersListActionSaga({ callback: checkNames }))
        )
      default:
        return null;
    }
  }, []);

  const checkNames = (data) => {
    if (role === Roles.SA) {
      getOptionListData(data, "SuperAdmin");
    } else if (role === Roles.AD) {
      getOptionListData(data, "Admin");
    }
  }


  const getTotalBalanceOfAdmin = (type) => {
    const model =
    {
      "txn_type": type === "add" ? 2 : 3,
      "receiver_id": tranferValue
    }
    dispatch(showAdminCreditsActionSaga({ model: model, callback: getAdminCreditsBalance }))
  }


  const getAdminCreditsBalance = (data) => {
    setAdminTotalBalance([data])
  }


  const getOptionListData = (data, role) => {
    if (data.length > 0) {
      let optionList = [];
      if (role === Roles.SA) {
        optionList =
          data?.map((item) => ({
            value: {
              accound_id: item?.account_id,
              user_id: item?.admin_id
            },
            label: item?.account_name,
          })) || [];
      } else if (role === Roles.AD) {
        optionList =
          data?.map((item) => ({
            value: item?.user_id,
            label: item?.user_name,
          })) || [];
      } else {
        return null;
      }
      setOptionListData(optionList);
      setSelectedOption(optionList[0]);
    } else {
      return null;
    }
  };

  const modal = () => {
    switch (role) {
      case "SuperAdmin":
        return setSuperAdminCreditModal(true);
      case "Admin":
        return setAdminCreditModal(true);
      default:
        return null;
    }
  };

  useEffect(() => {
    modal();
    getTranferList()
  }, [role]);




  const getTranferList = () => {
    dispatch(getCreditsActionSaga({ callback: getCreditsData }));
  }
  const getCreditsData = (data) => {
    if (data?.data?.respcode === 200) {
      setCreditHistory(data?.data?.data);
      setfilteredCreditData(data?.data?.data);
    } else {
      return
    }
  };

  const availableCredits = () => {
    switch (role) {
      case "Admin":
        return "Available Credits of Admin"
      case "User":
        return "Available Credits of User"
      default:
        return "Available Credits of Admin"
    }
  }

  const columns = [
    {
      name: "From",
      selector: (row) => row.from_user || "N/A",
    },
    {
      name: "To",
      selector: (row) => row.to_user || "N/A",
    },
    {
      name: 'Transaction Date',
      selector: (row) => moment(row.transaction_date).subtract(5, "hours").subtract(30, "minutes").format("MMMM Do YYYY, h:mm a") || "N/A",
      wrap: true
    },
    {
      name: "Credits",
      selector: (row) => row.credits || "N/A",
    },
    {
      name: "Credit Type",
      selector: (row) => row.txn_type || "N/A",
    },
    {
      name: "Expiry Date",
      selector: (row) => moment(row.expiry_date).subtract(5, "hours").subtract(30, "minutes").format("MMMM Do YYYY, h:mm a") || "N/A",
      wrap: true
    },
    {
      name: availableCredits(),
      selector: (row) => row.available_credits >= 0 ? row.available_credits : "N/A",
    },
  ];

  const customStyles = {
    rows: {
      style: {
        minHeight: "60px",
        fontSize: "14px",
        fontWeight: "600",
        fontFamily: "'Inter', sans-serif",
      },
    },
    // },
    headCells: {
      style: {
        fontSize: "12px",
        fontFamily: "'Inter', sans-serif",
        color: "#6F767E",
        justifyContent: "center",
      },
    },
    cells: {
      style: {
        paddingLeft: "8px", // override the cell padding for data cells
        paddingRight: "8px",
        justifyContent: "center",
      },
    },
  };



  const handlePopUp = (transferId) => {
    setTranferValue(transferId)
    setUpdateCreditsPopup(true);
  };

  const addCredits = (type) => {
    getTotalBalanceOfAdmin(type)
    setType(type)
    setCreditUsage(true);
    setShowCredits(true);
    setUpdateCreditsPopup(false)
  };

  const removeCredits = (type) => {
    getTotalBalanceOfAdmin(type)
    setType(type)
    setCreditUsage(false);
    setShowCredits(true);
    setUpdateCreditsPopup(false)
  };

  const handleCreditsBalance = (e) => {
    const { value, name } = e.target
    if (value.length > 0) {
      setCreditValue((prevState) => ({ ...prevState, [name]: value }))
    }
    else {
      toast.error("Please enter valid credits")
      setCreditValue("")
    }
  }


  const handletransfer = () => {
    const { credits: credValue } = creditValue || {};
    const { accound_id, user_id } = tranferValue || {};
    let model;
    if (!credValue || parseInt(credValue) <= 0) {
      toast.error(`Please enter minimum credit value`)
      return;
    }
    if (type === "add") {
      model = {
        "accid": "",
        "credit": credValue ? parseInt(credValue) : 0,
        "txn_type": 2,
        "receiver_id": tranferValue
      };
      dispatch(transferCreditsActionSaga({ model, callback: getTransferResp }));
    } else if (type === "deduct") {
      const minusCredits = "-" + credValue;
      model = {
        "accid": "",
        "credit": credValue ? parseInt(minusCredits) : 0,
        "txn_type": 3,
        "receiver_id": tranferValue
      }
      dispatch(transferCreditsActionSaga({ model, callback: getTransferResp }));
    } else {
      model = {
        "accid": accound_id,
        "credit": credValue ? parseInt(credValue) : 0,
        "txn_type": 1,
        "receiver_id": user_id
      };
      dispatch(superAdminTransferCreditsActionSaga({ model, callback: getSuperAdminTransferResp }));
    }
  };

  const getSuperAdminTransferResp = (data) => {
    if (data?.data?.respcode === 200) {
      toast.success(`Credits has been transfer ${data?.data?.respdesc}fully`)
      setUpdateCreditsPopup(false)
      getTranferList()
    } else {
      // toast.error(data?.data?.data?.errors[0].msg)
      toast(data?.data?.data?.errors[0].msg, {
        duration: 4000,
        position: 'top-center',
        style: {
          backgroundColor: "#e5002061",
          textTransform: "capitalize",
          fontSize: "14px"
        },
        icon: '❌',
      }
      )
      setUpdateCreditsPopup(false)
      setCreditValue("")
    }
  }

  const getTransferResp = (data) => {
    if (data?.data?.respcode === 200) {
      // toast.success(data?.data?.respdesc)
      toast.success(`Credits has been transfer ${data?.data?.respdesc}fully`)
      setShowCredits(false)
      getTranferList()
    } else {
      toast.error(`something went wrong : ${data?.data?.respdesc}`)
      setShowCredits(false)
    }
  }

  // const handleName = () => {
  //   // switch (role) {
  //   //   case "SuperAdmin":
  //   //     return adminNames?.value
  //   //   case "Admin":
  //   //     return userNames?.value
  //   //   default:
  //   //     return null
  //   // }
  //   switch (role) {
  //     case "SuperAdmin":
  //       return selectedOption?.value
  //     case "Admin":
  //       return selectedOption?.value
  //     default:
  //       return null
  //   }
  // }

  const checkValue = () => {
    if (selectedOption?.value === "" || selectedOption?.value?.accound_id === "") {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    checkValue()
  }, [selectedOption])




  return (
    <>
      <Loader />
      {role === Roles.SA || role === Roles.AD ?
        <Card
          className={cn(styles.card)}
          title="Update Credits"
          classTitle="title-purple"
        >
          <div className={styles.Updatecredit}>
            <SearchableDropdown
              // options={role === Roles.SA ? optionList : optionListUser}
              // selectedObj={
              //   role === Roles.SA ? adminNames : userNames
              // }
              // setValue={
              //   role === Roles.SA ? setAdminNames : setUserNames
              // }
              options={optionListData}
              selectedObj={
                selectedOption
              }
              setValue={
                setSelectedOption
              }
            />
            <button className={cn("button", styles.button, {
              [styles.disabled]: checkValue(),
            })}
              onClick={() => handlePopUp(selectedOption?.value)}
              disabled={checkValue()}
            >
              Update
            </button>
          </div>
        </Card>
        :
        null
      }
      <Card
        className={cn("mt-3", styles.card)}
        classCardHead={styles.head}
        title="Credit History"
        classTitle={cn("title-purple", styles.title)}
        head={
          <>
            <SearchTextInput
              className={styles.form}
              value={search}
              placeholder="Search"
              type="text"
              name="search"
              icon="search"
              onChange={creditSearch}
            />
            <ExportArea
              className={styles.sorting}
              headers={columns}
              data={creditHistory}
              elementRef={pdfAreaRef}
            />
          </>
        }
      >
        <div ref={pdfAreaRef}>
          {/* <Table headers={headers} data={data} pageSize={5} /> */}
          {creditHistory.length > 0 ? (
            <DataTable
              columns={columns}
              data={filteredCreditData}
              pagination
              customStyles={customStyles}
              theme="solarized"
            />
          ) : (
            <>
              <Loader />
              <NoTableData
                image={noDataImg}
                heading="No Records Found"
                semiheading={
                  "There are no Credit Transaction History at the moment"
                }
              />
            </>
          )}
        </div>
      </Card>
      {updateCreditsPopup && (
        <>
          {superAdminCreditModal && (
            <Modal
              outerClassName={styles.emailSuccess}
              visible={superAdminCreditModal}
              onClose={() => setUpdateCreditsPopup(false)}
              heading="Add Credits"
              children={
                <>
                  <div className={styles.sacredits}>
                    <TextInput
                      className={styles.fieldforgot}
                      name="credits"
                      type="number"
                      placeholder="Credits"
                      required
                      onChange={handleCreditsBalance}

                    // icon="mail"
                    />
                    <button className={cn("button")} onClick={handletransfer}>Submit</button>
                  </div>
                </>
              }
            />
          )}
          {adminCreditModal && (
            <Modal
              outerClassName={styles.emailSuccess}
              visible={adminCreditModal}
              onClose={() => setUpdateCreditsPopup(false)}
              heading="How do you want to update?"
              children={
                <>
                  <div className={styles.adcredits}>
                    <button
                      className={cn("button")}
                      onClick={() => addCredits("add")}
                    >
                      Add
                    </button>
                    <button
                      className={cn("button")}
                      onClick={() => removeCredits("deduct")}
                    >
                      Deduct
                    </button>
                  </div>
                </>
              }
            />
          )}
        </>
      )}
      {showCredits && (
        <Modal
          outerClassName={styles.emailSuccess}
          visible={showCredits}
          onClose={() => setShowCredits(false)}
          heading={creditUsage ? "Add Credits to User" : "Deduct Credits from User"}
          children={
            <>
              <div className={styles.list}>
                {adminTotalBalance.map((x, index) => (
                  <div className={styles.item} key={index}>
                    <div
                      className={styles.icon}
                      style={{ backgroundColor: x.color }}
                    >
                      <Icon name={x.icon} size="24" />
                    </div>
                    <div className={styles.details}>
                      <div className={styles.label}>{type === "add" ? "Current Credits of Admin" : "Current Credits of User"}</div>
                      <div className={styles.counter}>{x.value}</div>
                    </div>
                  </div>
                ))}
              </div>
              <div className={styles.sacredits}>
                <TextInput
                  className={styles.fieldforgot}
                  name="credits"
                  type="number"
                  placeholder={creditUsage ? "Add Credits" : "Deduct Credits"}
                  required
                  onChange={handleCreditsBalance}
                // icon="mail"
                />
                <button className={cn("button")} onClick={handletransfer}>Submit</button>
              </div>
            </>
          }
        />
      )}
    </>
  );
};

export default GetCredits;
