import React from "react";
import styles from "./Success.module.sass";
import cn from "classnames";
import Icon from "../Icon";


// Note: Add the Below span tag for spanTag props and change the img with your custom image
{/* <span role="img" aria-label="firework">
// 🎉
</span> */}

const Success = ({ status, heading, respData, func, btnText, spanTag, cusStyles, copyIcon , handleCopy }) => {

    return (
        <>
            <div className={styles.success}>
                <div className={styles.icon}>
                    {spanTag}
                </div>
                <div className={cn("h3", styles.price)}>{heading}</div>
                <div className={styles.info}>{status}</div>
                <div className={styles.text} style={cusStyles}>
                    {respData}
                    {copyIcon &&
                        <span onClick={handleCopy}>
                            <Icon name="copy" size="30" fill="#6F767E" />
                        </span>
                    }
                </div>
                <button className={cn("button", styles.button)} onClick={func}>{btnText}</button>
            </div>
        </>
    );
};

export default Success;
