import React from 'react'

const PageNotFound = () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <div className='d-flex flex-column'>
                {/* <h1 className='text-center'>Error 404</h1> */}
                <h2>Page Not Found</h2>
                <a href='/' className='text-center'>Back</a>
            </div>
        </div>
    )
}

export default PageNotFound
