import React from "react";
import jsPDF from "jspdf";
import "jspdf-autotable";
// import cn from "classnames";

const PdfLink = ({ data, action }) => {
  const branding = process.env.REACT_APP_IMAGE
  const filename = `${branding === "SYN" ? "Syntizen" : "Tryaski"} Instant PAN Services.pdf`;

  const generatePdf = () => {
    const doc = new jsPDF();

    // Define function to map admin_status and account_type values
    const mapValues = (key, value) => {
      if (key === "admin_status" ||key === "user_status") {
        return value === 1 ? "Active" : "Inactive";
      }
      if (key === "account_type") {
        return value === 1 ? "API" : "Portal";
      }
      if (key === "txnstatus") {
        return value === 1 ? "Success" : "Failure";
      }
      if (key === "servicetype") {
        return value === 1 ? "New Pan" : "Pan Correction";
      }
      return value;
    };

    // Extract column names from remaining keys in first object in data array
    const columns = Object.keys(data[0])
      .filter(
        (key) =>
          key !== "createdAt" &&
          key !== "uptatedAt" &&
          key !== "account_id" &&
          key !== "admin_id" &&
          key !== "user_id" &&
          key !== "state" &&
          key !== "city" &&
          key !== "address" &&
          key !== "admin_verified" &&
          key !== "pincode"
      )
      .map((key) => {
        if (key === "accid") {
          return "Account Details";
        }
        if (key === "_id") {
          return "Transaction ID";
        }
        if (key === "servicetype") {
          return "Transaction Type";
        }
        if (key === "txnstatus") {
          return "Transaction Status";
        }
        if (key === "PanTransaction") {
          return "Acknowledgement Number";
        }
        return key;
      });

    // Modify admin_status and account_type values in each object in data array
    const modifiedData = data.map((row) => {
      const modifiedRow = {};
      Object.entries(row).forEach(([key, value]) => {
        if (key === "accid") {
          modifiedRow["Account Details"] = value ? value : "N/A";
        } else if (key === "_id") {
          modifiedRow["Transaction ID"] = value ? value : "N/A";
        } else if (key === "servicetype") {
          modifiedRow["Transaction Type"] = mapValues(key, value);
        } else if (key === "txnstatus") {
          modifiedRow["Transaction Status"] = mapValues(key, value);
        } else if (key === "PanTransaction") {
          modifiedRow["Acknowledgement Number"] =
            typeof value === "object" && value !== null
              ? Object.keys(value).join(", ") || "N/A"
              : value
              ? value
              : "N/A";
        } else {
          modifiedRow[key] = mapValues(key, value) || "N/A";
        }
        modifiedRow[key] = modifiedRow[key] || "N/A";
      });
      return modifiedRow;
    });

    // Add table
    doc.autoTable({
      head: [columns],
      body: modifiedData.map((row) => {
        // Convert object values to an array ordered by the column order
        return columns.map((column) => {
          let value = row[column];
          if (typeof value === "object" && value !== null) {
            // Format nested object as key-value pairs
            value = Object.entries(value)
              .map(([key, val]) => `${key}: ${val}`)
              .join(", ");
          }
          return value;
        });
      }),
      startY: 10,
      styles: {
        cellPadding: 2,
        cellWidth: "auto",
        valign: "middle",
        halign: "center",
        fontStyle: "normal",
        fontSize: 7,
        overflow: "linebreak",
        minCellHeight: 10,
        lineWidth: 0.5,
      },
    });

    // Save PDF file
    doc.save(filename);
  };

  return (
    <img
      src={action.svg}
      onClick={generatePdf}
      data-for={action.name}
      data-tip={action.tooltip}
      style={{ cursor: "pointer" }}
      alt="pdf"
    />
  );
};

export default PdfLink;
