import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./User.module.sass";
import Icon from "../../Icon";
import { useDispatch } from "react-redux";
import { LogoutActionSaga } from "../../../store/actions/LoginActions";
import ChangePassword from "../../screens/ChangePassword";
import Modal from "../../Modal";


const User = ({ className }) => {

  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const token = sessionStorage.getItem("data");
  const userName = sessionStorage.getItem("username")
  const role = sessionStorage.getItem("role")
  const [changePasswordModal, setChangePasswordModal] = useState(false);

  const location = useLocation();
  const items = [
    {
      menu: [
        {
          title: "Change Password",
          url: location.pathname,
          // state: true,
        },
        {
          title: "Log out",
          url: "/",
        },
      ],
    },
  ];

  const handleLogOut = (link) => {
    setVisible(false);
    if (link.url === location.pathname) {
      openModal();
      return;
    }
    switch (link.url) {
      case "/":
        sessionStorage.clear();
        localStorage.clear();
        return dispatch(LogoutActionSaga({ model: "", token }));
      default:
        return null;
    }
  };

  const openModal = () => {
    setChangePasswordModal(true);
  };

  // const getLogoutData = (data) => {
  //   // console.log(data, "daya Logit==out");
  // }

  return (
    <>
      <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
        <div
          className={cn(styles.user, className, { [styles.active]: visible })}
        >
          <div className={styles.profile}>
            <p className={cn(styles.profileName)}>{userName}</p>
            <p className={cn(styles.profileRole)}>{role}</p>
          </div>
          <button className={styles.head} onClick={() => setVisible(!visible)}>
            <img src="images/content/avatar-1.jpg" alt="Avatar" />
          </button>
          <div className={styles.body}>
            {items.map((item, index) => (
              <div className={styles.menu} key={index}>
                {item.menu.map((x, index) =>
                  x.url ? (
                    <NavLink
                      // className={cn(styles.item, { [styles.color]: x.color })}
                      // activeClassName={styles.active}
                      className={({ isActive }) =>
                        isActive
                          ? cn(styles.item, { [styles.color]: x.color })
                          : cn(styles.item)
                      }
                      to={x.url}
                      onClick={() => handleLogOut(x)}
                      key={index}
                    >
                      {x.icon && <Icon name={x.icon} size="24" />}
                      {x.title}
                    </NavLink>
                  ) : (
                    <button
                      className={styles.item}
                      onClick={() => setVisible(false)}
                      key={index}
                    >
                      {x.title}
                    </button>
                  )
                )}
              </div>
            ))}
          </div>
        </div>
      </OutsideClickHandler>
      {changePasswordModal && (
        <Modal
          outerClassName={styles.changePassWidth}
          visible={changePasswordModal}
          onClose={() => setChangePasswordModal(false)}
          // heading="Changed Password"
          children={<ChangePassword
            closeModal={() => setChangePasswordModal(false)} />}
        />
      )}
    </>
  );
};

export default User;
