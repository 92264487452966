import React, { useEffect, useState } from "react";
import cn from "classnames";
import TooltipGlobal from "../../TooltipGlobal";
import Card from "../../Card";
import TextInput from "../../TextInput";
import Dropdown from "../../Dropdown";
import styles from "./Pan.module.sass";
import { useDispatch } from "react-redux";
import moment from "moment";
import { AES } from "crypto-js";

import {
  newPanSubmitAction,
  PanCorrectionAction,
  panStatusAction,
} from "../../../store/actions/PanActions";
import {
  validateEmail,
  validateIsRequired,
} from "../../../Utils/Validations";
import { toast } from "react-hot-toast";
import Loader from "../../loader/Loader";
import $ from "jquery";
import { loaderActionReq } from "../../../store/actions/reducerActions/PanReducerActions";
import axios from "axios";
import Success from "../../TxnResp/Success";
import Modal from "../../Modal";
import { useLocation, useNavigate } from "react-router-dom";

// type can be new or correction or status
const PanForm = ({ type }) => {
  var CryptoJS = require("crypto-js");
  const dispatch = useDispatch();
  const naviTo = useNavigate();
  const location = useLocation();
  const [nsdlApiChecked, setNsdlApiChecked] = useState(true);
  const [nsdlApiCheckedErr, setNsdlApiCheckedErr] = useState(false);
  const [dropdown, setDropDown] = useState({ value: "", label: "" });
  const [gender, setGender] = useState([]);
  const [userData, updateUserData] = useState({ gender: dropdown?.value });
  const [panStatus, setPanStatus] = useState("");
  const [panModelErr, setPanModelErr] = useState({});
  const [panDetails, setPanDetails] = useState("");
  const [panStatusShow, setPanStatusShow] = useState(false);

  useEffect(() => {
    checkNsdlApi();
    if (location.pathname === "/pan/correction") {
      updateUserData({
        fullname: "",
        dob: "",
        emailId: "",
        // gender: ''
      });
    } else if (location.pathname === "/pan/new") {
      updateUserData({
        fullname: "",
        dob: "",
        emailId: "",
        // gender: ''
      });
    } else if (location.pathname === "/pan/status") {
      setPanStatusShow(false);
    }
  }, [location]);

  const genders = [
    {
      gender: "Male",
      value: "M",
    },
    {
      gender: "Female",
      value: "F",
    },
  ];


  useEffect(() => {
    getUserDropdownList();
  }, []);

  const getUserDropdownList = () => {
    let optionListData =
      genders?.map((item) => ({
        value: item?.value,
        label: item?.gender,
      })) || [];
    let singleDropDownvalue = optionListData[0];
    setGender(optionListData);
    setDropDown(singleDropDownvalue);
    updateUserData((prevState) => ({
      ...prevState,
      gender: singleDropDownvalue?.value,
    }));
  };


  const handleChange = (e) => {
    const { value, name } = e.target;
    if (name === "emailId") {
      const err = validateEmail(value);
      setPanModelErr((prevState) => ({ ...prevState, [name]: err }));
    } else if (name === "fullname") {
      // const err = validatealphaNumeric(value);
      var regex = /^[a-zA-Z ]*$/;
      if (!regex.test(value)) {
        setPanModelErr((prevState) => ({ ...prevState, [name]: "Please enter only alphabets" }));
        return;
      } else {
        setPanModelErr((prevState) => ({ ...prevState, [name]: false }));
      }
    } else {
      const isEmpty = validateIsRequired(value);
      setPanModelErr((prevState) => ({ ...prevState, [name]: isEmpty }));
    }
    updateUserData((prevState) => ({ ...prevState, [name]: value }));
  };

  const handlePanStatusChange = (e) => {
    const { value, name } = e.target;
    setPanStatus((prevState) => ({ ...prevState, [name]: value }));
  };

  // const decryptedText = AES.decrypt(ciphertext, password).toString(enc.Utf8);

  const handleChangeStatusAcc = (data) => {
    const { value, label } = data;
    setDropDown({ value: value, label: label })
    updateUserData((prevState) => ({ ...prevState, gender: value }));
  };

  const checkNsdlApi = () => {
    dispatch(loaderActionReq(true));
    axios
      .post(
        "https://test.assisted-service.egov-nsdl.com/SpringBootFormHandling/newPanReq",
        {}
      )
      .then(function (response) {
        if (response) {
          setNsdlApiChecked(true);
          dispatch(loaderActionReq(false));
        }
      })
      .catch(function (error) {
        const data = error?.code;
        if (data) {
          setNsdlApiCheckedErr(true);
          dispatch(loaderActionReq(false));
        }
      });
  };
  // checkNsdlApi();

  const handleSubmitPan = (type) => {
    const payload = { ...userData };
    const modelDob = moment(payload?.dob).format("DD-MM-YYYY");
    const modelToEncrypt = {
      slk: "",
      // slk: "SANTHOSH-SLK",
      rrn: Math.floor(Date.now() / 1000),
      fullName: payload?.fullname,
      dob: modelDob,
      emailId: payload?.emailId,
      gender: payload?.gender,
      env: "2",
    };
    const password = process.env.REACT_APP_ENCRYPT_PASSWORD;
    const ciphertext = AES.encrypt(
      JSON.stringify(modelToEncrypt),
      password
    ).toString();
    const model = {
      data: ciphertext,
    };
    const modelToEncryptPanCr = {
      // slk: "SANTHOSH-SLK1",
      slk: "",
      rrn: Math.floor(Date.now() / 1000),
      fullName: payload?.fullname,
      dob: modelDob,
      emailId: payload?.emailId,
      gender: payload?.gender,
      env: "2",
    };
    const ciphertext2 = AES.encrypt(
      JSON.stringify(modelToEncryptPanCr),
      password
    ).toString();
    const modelPanCr = {
      data: ciphertext2,
    };
    let isTrue = Object.values(modelToEncrypt).some(
      (v) => v === undefined || v === null
    );
    let isErrTrue = Object.values(panModelErr).some(
      (v) => v === undefined || v === null
    );

    if (isTrue || !isErrTrue) {
      toast.error("please check all the inputs");
    } else {
      if (nsdlApiChecked) {
        switch (type) {
          case "correction":
            return dispatch(
              PanCorrectionAction({
                model: modelPanCr,
                callback: getPanCorrectionData,
              })
            );
          case "new":
            return dispatch(
              newPanSubmitAction({ model: model, callback: submitNewpan })
            );
          default:
            return null;
        }
      } else {
        return null;
      }
    }
  };

  const handlePanStatusSubmit = () => {
    const modelToEncryptPanstatus = {
      slk: "",
      rrn: Math.floor(Date.now() / 1000),
      ackno: panStatus?.acknowledgementNumber,
      env: "2"
    };
    const password = process.env.REACT_APP_ENCRYPT_PASSWORD;
    const ciphertext = AES.encrypt(
      JSON.stringify(modelToEncryptPanstatus),
      password
    ).toString();
    const model = {
      data: ciphertext,
    };
    // console.log(model, "panstatus")
    dispatch(
      panStatusAction({
        model: model,
        callback: getPanStatusData,
      })
    );
  };

  const submitNewpan = (data) => {
    const newPanDec = data?.data?.data;
    const password = process.env.REACT_APP_ENCRYPT_PASSWORD;
    var bytes = CryptoJS.AES.decrypt(newPanDec, password);
    var panDecryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    toast.success(data?.data?.respdesc);
    newPanRedirect(
      panDecryptedData?.SendURL,
      panDecryptedData?.dataAndSignature
    );

    // }
  };

  const getPanCorrectionData = (data) => {
    const newPanDec = data?.data?.data;
    const password = process.env.REACT_APP_ENCRYPT_PASSWORD;
    var bytes = CryptoJS.AES.decrypt(newPanDec, password);
    var panDecryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    toast.success(data?.data?.respdesc);
    panCorrection(
      panDecryptedData?.SendURL,
      panDecryptedData?.dataAndSignature
    );
    // updateUserData({})
  };

  const getPanStatusData = (data) => {
    const detailsOfPanStatus = data?.data?.data
    if (data?.data?.respcode === 200) {
      const password = process.env.REACT_APP_ENCRYPT_PASSWORD;
      var bytes = CryptoJS.AES.decrypt(detailsOfPanStatus, password);
      var panStatusDecryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      setPanDetails([panStatusDecryptedData]);
      setPanStatusShow(true);
    } else {
      toast.error(data?.data?.respdesc);
    }
    setPanStatus({
      acknowledgementNumber: "",
    });
  };

  function newPanRedirect(path, parameters) {
    // setIsApiCallSuccess(true);
    var form = $("<form></form>");
    form.attr("method", "post");
    form.attr("action", path);
    var field = $("<input></input>");
    var key = "req";
    var value = parameters;
    field.attr("type", "hidden");
    field.attr("name", key);
    field.attr("value", value);
    form.append(field);
    $(document.body).append(form);
    form.submit();
  }

  function panCorrection(path, parameters) {
    var form = $("<form></form>");
    form.attr("method", "post");
    form.attr("action", path);
    var field = $("<input></input>");
    var key = "req";
    var value = parameters;
    field.attr("type", "hidden");
    field.attr("name", key);
    field.attr("value", value);
    form.append(field);
    $(document.body).append(form);
    form.submit();
  }

  const headers = [
    {
      label: "Txn ID",
      key: "txnid",
    },
    {
      label: "RRN No",
      key: "rrn",
    },
    {
      label: "PAN Status",
      key: "panstatus",
    },
    {
      label: "PAN Number",
      key: "panno",
    },
    {
      label: "Acknowledgement Number",
      key: "ackno",
    },
  ];

  return (
    <>
      {location.pathname === "/pan/status" ? (
        <>
          <Loader dispname={nsdlApiChecked === false ? "Checking Server" : "Fetching Pan Status"} />
          <div className={styles.row}>
            <div className={styles.col}>
              <Card
                className={styles.card}
                title="Add Details"
                classTitle="title-green"
              >
                <div className={styles.rowField}>
                  <TextInput
                    className={styles.field}
                    label="Acknowledgement Number *"
                    name="acknowledgementNumber"
                    type="number"
                    tooltip="This field is required"
                    required
                    value={panStatus?.acknowledgementNumber}
                    onChange={handlePanStatusChange}
                  />
                </div>
                <div className={styles.buttonContainer}>
                  <button
                    className={cn("button", styles.button)}
                    onClick={handlePanStatusSubmit}
                  >
                    Submit
                  </button>
                </div>
              </Card>
            </div>
            <TooltipGlobal />
          </div>
        </>
      ) : (
        <>
          <Loader dispname={nsdlApiChecked === false ? "Checking Server" : ""} />
          <div className={styles.row}>
            <div className={styles.col}>
              <Card
                className={styles.card}
                title="Add Details"
                classTitle="title-green"
              >
                <div className={styles.rowField}>
                  <TextInput
                    className={styles.field}
                    label="Full name as per Aadhaar"
                    name="fullname"
                    type="text"
                    tooltip="This field is required"
                    value={userData.fullname}
                    required
                    onChange={handleChange}
                  />
                  <p className={styles.error}>{panModelErr.fullname}</p>
                </div>
                <div className={styles.rowField}>
                  <TextInput
                    className={styles.field}
                    label="Date of Birth"
                    tooltip="This field is required"
                    name="dob"
                    type="date"
                    range="max"
                    value={userData?.dob}
                    onChange={handleChange}
                  />
                  <p className={styles.error}>{panModelErr.dob}</p>
                </div>
                <div className={styles.rowField}>
                  <TextInput
                    className={styles.field}
                    label="Email ID"
                    name="emailId"
                    type="email"
                    tooltip="This field is required"
                    required
                    value={userData?.emailId}
                    onChange={handleChange}
                  />
                  <p className={styles.error}>{panModelErr.emailId}</p>
                </div>
                <div className={styles.rowField}>
                  <Dropdown
                    className={styles.field}
                    label="Gender"
                    tooltip="This field is required"
                    selectedObj={dropdown}
                    options={gender}
                    // value={userData?.gender}
                    name="gender"
                    setValue={handleChangeStatusAcc}
                  />
                  <p className={styles.error}>{panModelErr.gender}</p>
                </div>
                <div className={styles.buttonContainer}>
                  <button
                    className={cn("button", styles.button)}
                    onClick={() => handleSubmitPan(type)}
                  >
                    Submit
                  </button>
                </div>
              </Card>
            </div>
          </div>
          <TooltipGlobal />
        </>
      )}
      {nsdlApiCheckedErr && (
        <>
          <Modal
            outerClassName={styles.emailSuccess}
            visible={nsdlApiCheckedErr}
            onClose={() => naviTo("/home")}
          >
            <Success
              spanTag={
                <span role="img" aria-label="firework">
                  🤦‍♂️
                </span>
              }
              status="NSDL server is down, Please try after sometime"
              func={() => naviTo("/home")}
              btnText="OK"
            />
          </Modal>
        </>
      )}
      {panStatusShow && location.pathname === "/pan/status" && (
        <>
          <Card
            className={cn("mt-3", styles.card)}
            title="Checked PAN Status"
            classTitle="title-green"
          >
            <div className={styles.container}>
              <div className={styles.row}>
                <div className={styles.col}>
                  <div className={styles.wrapper}>
                    <div className={styles.table}>
                      <div className={styles.rowTable}>
                        {headers.map((header) => (
                          <div className={styles.colTable} key={header.label}>
                            {header.label}
                          </div>
                        ))}
                      </div>
                      {panDetails?.map((x, index) => {
                        return (
                          <div className={styles.rowTable} key={index}>
                            <div className={styles.colTable}>
                              {x?.txnid || "N/A"}
                            </div>
                            <div className={styles.colTable}>
                              {x?.rrn || "N/A"}
                            </div>
                            <div className={styles.colTable}>
                              {x?.panstatus || "N/A"}
                            </div>
                            <div className={styles.colTable}>
                              {x?.panno || "N/A"}
                            </div>
                            <div className={styles.colTable}>
                              {x?.ackno || "N/A"}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </>
      )}
    </>
  );
};

export default PanForm;
