import React, { useState } from "react";
import cn from "classnames";
import styles from "../SignIn/./SignInForm.module.sass";
import { use100vh } from "react-div-100vh";
import { Link, useLocation, useNavigate } from "react-router-dom";
import TextInput from "../../TextInput";
import Image from "../../Image";
import { useDispatch } from "react-redux";
import { toast } from "react-hot-toast";
import Loader from "../../loader/Loader";
import { forgotPasswordActionSaga } from "../../../store/actions/LoginActions";
import Icon from "../../Icon";
import Modal from "../../Modal";
import SuccessModal from "../../SuccessModal/SuccessModal";

const ForgotPassword = ({ minHeightpan, className, noimg, navigate }) => {
  const heightWindow = use100vh();
  const dispatch = useDispatch();
  const naviTo = useNavigate();
  const [forgotModel, setForgotModel] = useState({});
  const [forgotModelErr, setForgotModelErr] = useState({});
  const [onSuccess, setOnSuccess] = useState(false)
  const branding = process.env.REACT_APP_IMAGE

  const handleLogin = (e) => {
    const { value, name } = e.target;
    setForgotModel((prevState) => ({ ...prevState, [name]: value }));
  };

  const submitLogin = () => {
    const model = {
      email: forgotModel.email,
    };
    let isTrue = Object.values(model).some(
      (v) => v === "" || v === undefined || v === null
    );
    let isErrTrue = Object.values(forgotModelErr).every(
      (v) => v === "" || v === undefined || v === null
    );
    if (isTrue || !isErrTrue) {
      toast.error("Please enter Email ID");
    } else {
      // return setOnSuccess(true)
      dispatch(forgotPasswordActionSaga({ model: model, callback: getDataOfForgot }))
    }
  };

  const getDataOfForgot = (data) => {
    let respData = data?.data;
    if (respData.respcode === 200) {
      return setOnSuccess(true)
    }
    else {
      toast.error(respData?.data?.errors[0]?.msg || respData?.respdesc);
    }
  };

  return (
    <>
      <Loader />
      <div
        className={cn(styles.login, className)}
        style={{ minHeight: !minHeightpan ? heightWindow : null }}
      >
        <div className={styles.wrapper}>
          {!noimg && (
            <Link className={styles.logo} to="/">
              <Image
                className={styles.pic}
                src={branding === "SYN" ? "/images/logo.png" : "/images/trysakiLogo.png"}
                srcDark={branding === "SYN" ? "/images/logo.png" : "/images/trysakiLogo.png"}
                alt="image"
              />
            </Link>
          )}
          <div className={cn("h2forgot", styles.titleForgot)}>Forgot your Password?</div>
          <p className={styles.title2}>Please enter the email you use to sign in</p>
          <div className={styles.body}>
            <TextInput
              className={styles.fieldforgot}
              name="email"
              type="email"
              placeholder="Email ID"
              required
              icon="mail"
              onChange={handleLogin}
            />
            <div className={styles.copy}></div>
            <p>{forgotModelErr.email}</p>
            <button
              className={cn("button", styles.button)}
              onClick={submitLogin}
            >
              Send Link
            </button>
            {/* </Link> */}
            {!noimg && (
              <div className={styles.note}>
                Powered by {branding === "SYN" ? "Syntizen" : "Tryaski"} Technologies Pvt Ltd.
              </div>
            )}
          </div>
          <div className={styles.backbtn}>
            <Link
              className={cn("button-stroke button-small", styles.button)}
              to="/"
            >
              <Icon name="arrow-left" size="24" />
              <span>Back</span>
            </Link>
          </div>
        </div>
      </div>
      {onSuccess && (
        <>
          <Modal
            outerClassName={styles.emailSuccess}
            visible={onSuccess}
            onClose={() => setOnSuccess(false)}
            heading="Link Sent"
            children={
              <SuccessModal
                click={() => naviTo("/")}
                text="Password reset link has been sent to your email address"
              />
            }
          />
        </>
      )}
    </>
  );
};

export default ForgotPassword;
