import { GET_ADMIN_NAMES_REDUCER, GET_OTP_COMPONENT, GET_USER_NAMES_REDUCER, LOADER_BEGIN, LOGIN_REDUCER } from "../../sagaActionConstants/sagaReducerActionConstants/PanReducerContants"

export const loginSagaActionReducer = (payload) => {
    return {
        type: LOGIN_REDUCER,
        payload: payload
    }
}

export const loaderActionReq = (payload) => {
    return {
        type: LOADER_BEGIN,
        payload: payload
    }
}

export const getOtpComponent = (payload) => {
    return {
        type: GET_OTP_COMPONENT,
        payload: payload
    }
}

export const getAdminNameReducer = (payload) => {
    return {
        type: GET_ADMIN_NAMES_REDUCER,
        payload: payload
    }
}

export const getUserNameReducer = (payload) => {
    return {
        type: GET_USER_NAMES_REDUCER,
        payload: payload
    }
}

