import React, { useState } from "react";
import cn from "classnames";
import styles from "../SignIn/./SignInForm.module.sass";
import { use100vh } from "react-div-100vh";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import TextInput from "../../TextInput";
import Image from "../../Image";
import { useDispatch } from "react-redux";
// import {
//   ResetActionSaga,
// } from "../../../store/actions/ResetActions";
import { toast } from "react-hot-toast";
import Loader from "../../loader/Loader";
import { resetPasswordActionSaga } from "../../../store/actions/LoginActions";
import Icon from "../../Icon";
import Modal from "../../Modal";
import SuccessModal from "../../SuccessModal/SuccessModal";
import { validateInput } from "../../../Utils/Validations";
// import Modal from "../../Modal";

const ResetPassword = ({ minHeightpan, className, noimg, navigate }) => {
  const heightWindow = use100vh();
  const dispatch = useDispatch();
  const naviTo = useNavigate();
  const params = useParams()
  const [setModel, setSetModel] = useState({});
  const [setModelErr, setSetModelErr] = useState({
    password: "",
    confirmPassword: "",
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordone, setShowPasswordone] = useState(false);
  const [onSuccess, setOnSuccess] = useState(false)
  const branding = process.env.REACT_APP_IMAGE


  const handleLogin = (e) => {
    const { value, name } = e.target;
    setSetModel((prevState) => ({ ...prevState, [name]: value }));
    validateInput(name, value, setModel, setSetModelErr);
  };

  const submitLogin = () => {
    const model = {
      newPassword: setModel.password,
    };
    let isTrue = Object.values(model).some(
      (v) => v === "" || v === undefined || v === null
    );
    let isErrTrue = Object.values(setModelErr).every(
      (v) => v === "" || v === undefined || v === null
    );
    if (isTrue || !isErrTrue) {
      toast.error("please check all the inputs");
    } else {
      // return setOnSuccess(true)
      dispatch(resetPasswordActionSaga({ model: model, callback: getDataOfSetPassword, state: params?.token }))
    }
  };

  const getDataOfSetPassword = (data) => {
    let respData = data?.data
    if (respData.respcode === 200) {
      return setOnSuccess(true)
    }
    else {
      toast.error(respData.respdesc)
    }
  };

  return (
    <>
      <Loader />
      <div
        className={cn(styles.login, className)}
        style={{ minHeight: !minHeightpan ? heightWindow : null }}
      >
        <div className={styles.wrapper}>
          {!noimg && (
            <Link className={styles.logo} to="/">
              <Image
                className={styles.pic}
                src={branding === "SYN" ? "/images/logo.png" : "/images/trysakiLogo.png"}
                srcDark={branding === "SYN" ? "/images/logo.png" : "/images/trysakiLogo.png"}
                alt="image"
              />
            </Link>
          )}
          <div className={cn("hh2", styles.title1)}>Reset Password</div>
          <div className={styles.body}>

            <TextInput
              className={styles.fieldforgot}
              name="password"
              type={showPassword ? "text" : "password"}
              placeholder="Password"
              required
              icon="lock"
              eye="eye"
              onChange={handleLogin}
              setShowPassword={setShowPassword}
              showPassword={showPassword}
            />
            <div className={styles.copy}>
            </div>
            <p className={setModelErr.password ? styles.loginModelErr : ""}>{setModelErr.password}</p>
            <TextInput
              className={styles.fieldforgot}
              name="confirmpassword"
              type={showPasswordone ? "text" : "password"}
              placeholder="Confirm Password"
              required
              icon="lock"
              eye="eye"
              onChange={handleLogin}
              setShowPasswordone={setShowPasswordone}
              showPasswordone={showPasswordone}
            />
            <div className={styles.copy}>
            </div>
            <p className={setModelErr.confirmpassword ? styles.loginModelErr : ""}>{setModelErr.confirmpassword}</p>
            <button
              className={cn("button", styles.button)}
              onClick={submitLogin}
            >
              Reset Password
            </button>
            {/* </Link> */}
            {!noimg && (
              <div className={styles.note}>
                Powered by {branding === "SYN" ? "Syntizen" : "Tryaski"} Technologies Pvt Ltd.
              </div>
            )}
          </div>
          {/* <div className={styles.backbtn}>
            <Link
              className={cn("button-stroke button-small", styles.button)}
              to="/"
            >
              <Icon name="arrow-left" size="24" />
              <span>Back</span>
            </Link>
          </div> */}
        </div>
      </div>
      {onSuccess && (
        <>
          <Modal
            outerClassName={styles.emailSuccess}
            visible={onSuccess}
            onClose={() => setOnSuccess(false)}
            heading="Password Changed"
            children={
              <SuccessModal
                click={() => naviTo("/")}
                text="Your password has been reset successfully."
              />
            }
          />
        </>
      )}
    </>
  );
};

export default ResetPassword;
