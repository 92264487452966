export const superAdmin = [
    {
        title: "Home",
        icon: "home",
        url: "/home",
    },
    {
        title: "Manage Accounts",
        slug: "accounts",
        icon: "design",
        url: "/accounts/list",
    },
    {
        title: "Credit Management ",
        icon: "bar-chart",
        url: "/creditHistory"
    },
    {
        title: "Transaction Summary",
        slug: "activity",
        icon: "activity",
        dropdown: [
            {
                title: "Transaction History",
                url: "/transactions",
            },
            {
                title: "Reconciliation history",
                url: "/reconciliation",
            },
        ],
    },
    // {
    //   title: "Reports",
    //   slug: "activity",
    //   icon: "info",
    //   dropdown: [
    //     {
    //       title: "Daily report",
    //       url: "",
    //     },
    //     {
    //       title: "Monthly report",
    //       url: "",
    //     },
    //   ],
    // },
];

export const admin = [
    {
        title: "Home",
        icon: "home",
        url: "/home",
    },
    {
        title: "Manage Users",
        slug: "accounts",
        icon: "design",
        url: "/accounts/list",
    },
    {
        title: "Apply PAN",
        slug: "pan",
        icon: "list",
        add: false,
        dropdown: [
            {
                title: "New PAN",
                url: "/pan/new",
            },
            {
                title: "PAN Correction",
                url: "/pan/correction",
            },
            {
                title: "PAN Status",
                url: "/pan/status",
            },
        ],
    },
    {
        title: "Credit Management ",
        // slug: "accounts",
        icon: "bar-chart",
        url: "/creditHistory",
    },
    {
        title: "Transaction Summary",
        slug: "activity",
        icon: "activity",
        dropdown: [
            {
                title: "Transaction History",
                url: "/transactions",
            },
            {
                title: "Reconciliation history",
                url: "/reconciliation",
            },
        ],
    },
    // {
    //   title: "Reports",
    //   slug: "activity",
    //   icon: "info",
    //   dropdown: [
    //     {
    //       title: "Daily report",
    //       url: "/dailyreport",
    //     },
    //     {
    //       title: "Monthly report",
    //       url: "/monthlyreport",
    //     },
    //   ],
    // },
    // {
    //   title: "About",
    //   icon: "info",
    //   url: "/about",
    // },
];

export const users = [
    {
        title: "Home",
        icon: "home",
        url: "/home",
    },
    {
        title: "Apply PAN",
        slug: "pan",
        icon: "bar-chart",
        add: false,
        dropdown: [
            {
                title: "New PAN",
                url: "/pan/new",
            },
            {
                title: "PAN Correction",
                url: "/pan/correction",
            },
            {
                title: "PAN Status",
                url: "/pan/status",
            },
        ],
    },
    {
        title: "Credit Management ",
        // slug: "accounts",
        icon: "bar-chart",
        url: "/creditHistory",
    },
    {
        title: "Transaction Summary",
        slug: "activity",
        icon: "activity",
        dropdown: [
            {
                title: "Transaction History",
                url: "/transactions",
            },
            {
                title: "Reconciliation history",
                url: "/reconciliation",
            },
        ],
    },
    // {
    //   title: "Reports",
    //   slug: "activity",
    //   icon: "info",
    //   dropdown: [
    //     {
    //       title: "Daily report",
    //       url: "/transactions",
    //     },
    //     {
    //       title: "Monthly report",
    //       url: "/transactions",
    //     },
    //   ],
    // },
    // {
    //   title: "About",
    //   icon: "info",
    //   url: "/about",
    // },
];

