import toast from 'react-hot-toast';
import { put, takeLatest, call, takeEvery } from 'redux-saga/effects';
import Axios from "../../service/axios.jsx"
import { loaderActionReq } from '../actions/reducerActions/PanReducerActions.js';
import { ACTIVATE_SLK, ADD_CREDITS, DEACTIVATE_SLK, GENERATE_SLK, GET_CREDITS, GET_SLK_DETAILS, SHOW_ADMIN_CREDITS, SLK_SERVICES, SUPER_ADMIN_TRANSFER_CREDITS, TRANSFER_CREDITS } from "../sagaActionConstants/CreditsConstants";



const AddCredits = (model) => {
    const URL = "credits/addCredits"
    return Axios.post(URL, model).then((res) => {
        return res
    })
};

function* AddCreditsSaga(action) {
    yield put(loaderActionReq(true))
    try {
        const resp = yield call(AddCredits, action?.payload?.model)
        if (resp?.data?.respcode === 200) {
            if (action?.payload?.callback) {
                action?.payload?.callback(resp)
                yield put(loaderActionReq(false))
            }
        }
        else {
            toast.error(resp?.data?.respdesc)
        }
    } catch (error) {
        if (error?.response) {
            toast.error(error?.response?.data?.respdesc);
            yield put(loaderActionReq(false))
        }
    } finally {
        yield put(loaderActionReq(false))

    }
}

const getCredits = () => {
    const URL = "credits/transfer_history"
    return Axios.get(URL).then((res) => {
        return res
    })
};

function* getCreditsSaga(action) {
    yield put(loaderActionReq(true))
    try {
        const resp = yield call(getCredits, action?.payload?.model)
        if (resp?.data?.respcode === 200) {
            if (action?.payload?.callback) {
                action?.payload?.callback(resp)
                yield put(loaderActionReq(false))
            }
        } else {
            toast.error(resp?.data?.respdesc)
        }
        // if (resp?.data?.respcode === 200) {
        //     if (action?.payload?.callback) {
        //         action?.payload?.callback(resp)
        //         yield put(loaderActionReq(false))
        //     }
        // }
    } catch (error) {
        if (error?.response) {
            toast.error(error?.response?.data?.respdesc);
            yield put(loaderActionReq(false))
        }
    } finally {
        yield put(loaderActionReq(false))

    }
}



const generateSLK = (model) => {
    const URL = "credits/generate_slk"
    return Axios.post(URL, model).then((res) => {
        return res
    })
};

function* generateSLKSaga(action) {
    yield put(loaderActionReq(true))
    try {
        const resp = yield call(generateSLK, action?.payload?.model)
        if (resp?.data?.respcode === 200) {
            if (action?.payload?.callback) {
                action?.payload?.callback(resp)
                yield put(loaderActionReq(false))
            }
        }
    } catch (error) {
        if (error?.response) {
            toast.error(error?.response?.data?.respdesc);
            yield put(loaderActionReq(false))
        }
    } finally {
        yield put(loaderActionReq(false))

    }
}
const activateSLK = (model) => {
    const URL = "credits/activate_slk"
    return Axios.post(URL, model).then((res) => {
        return res
    })
};

function* activateSLKSaga(action) {
    yield put(loaderActionReq(true))
    try {
        const resp = yield call(activateSLK, action?.payload?.model)
        if (resp?.data?.respcode === 200) {
            if (action?.payload?.callback) {
                action?.payload?.callback(resp)
                yield put(loaderActionReq(false))
            }
        } else {
            toast.error(resp?.data?.respdesc)
        }
    } catch (error) {
        if (error?.response) {
            toast.error(error?.response?.data?.respdesc);
            yield put(loaderActionReq(false))
        }
    } finally {
        yield put(loaderActionReq(false))

    }
}
const deactivateSLK = (model) => {
    const URL = "credits/deactivate_slk"
    return Axios.post(URL, model).then((res) => {
        return res
    })
};

function* deactivateSLKSaga(action) {
    yield put(loaderActionReq(true))
    try {
        const resp = yield call(deactivateSLK, action?.payload?.model)
        if (resp?.data?.respcode === 200) {
            if (action?.payload?.callback) {
                action?.payload?.callback(resp)
                yield put(loaderActionReq(false))
            }
        } else {
            toast.error(resp?.data?.respdesc)
        }
    } catch (error) {
        if (error?.response) {
            toast.error(error?.response?.data?.respdesc);
            yield put(loaderActionReq(false))
        }
    } finally {
        yield put(loaderActionReq(false))

    }
}


const transferCredits = (model) => {
    const URL = "credits/update/user_credits"
    return Axios.post(URL, model).then((res) => {
        return res
    })
};

function* transferCreditsSaga(action) {
    yield put(loaderActionReq(true))
    try {
        const resp = yield call(transferCredits, action?.payload?.model)
        if (resp?.data?.respcode === 200) {
            if (action?.payload?.callback) {
                action?.payload?.callback(resp)
                yield put(loaderActionReq(false))
            }
        } else {
            toast.error(resp?.data?.respdesc)
        }
    } catch (error) {
        if (error?.response) {
            toast.error(error?.response?.data?.respdesc);
            yield put(loaderActionReq(false))
        }
    } finally {
        yield put(loaderActionReq(false))

    }
}
const superAdminTransferCredits = (model) => {
    const URL = "credits/admin/add_credits"
    return Axios.post(URL, model).then((res) => {
        return res
    })
};

function* superAdminTransferCreditsSaga(action) {
    yield put(loaderActionReq(true))
    try {
        const resp = yield call(superAdminTransferCredits, action?.payload?.model)
        // if (resp?.data?.respcode === 200) {
        if (action?.payload?.callback) {
            action?.payload?.callback(resp)
            yield put(loaderActionReq(false))
        }
        // } else {
        //     toast.error(resp?.data?.respdesc)
        // }
    } catch (error) {
        if (error?.response) {
            toast.error(error?.response?.data?.respdesc);
            yield put(loaderActionReq(false))
        }
    } finally {
        yield put(loaderActionReq(false))

    }
}


const showAdminCredits = (model) => {
    const URL = "credits/total-balance"
    return Axios.post(URL, model).then((res) => {
        return res
    })
};

function* showAdminCreditsSaga(action) {
    yield put(loaderActionReq(true))
    try {
        const resp = yield call(showAdminCredits, action?.payload?.model)
        if (resp?.data?.respcode === 200) {
            if (action?.payload?.callback) {
                action?.payload?.callback(resp?.data?.data)
                yield put(loaderActionReq(false))
            }
        } else {
            toast.error(resp?.data?.respdesc)
        }
    } catch (error) {
        if (error?.response) {
            toast.error(error?.response?.data?.respdesc);
            yield put(loaderActionReq(false))
        }
    } finally {
        yield put(loaderActionReq(false))

    }
}


const getSLkDetails = (model) => {
    const URL = "credits/get_slk"
    return Axios.get(URL, model).then((res) => {
        return res
    })
};

function* getSLkDetailsSaga(action) {
    yield put(loaderActionReq(true))
    try {
        const resp = yield call(getSLkDetails, action?.payload?.model)
        if (resp?.data?.respcode === 200) {
            if (action?.payload?.callback) {
                action?.payload?.callback(resp)
                yield put(loaderActionReq(false))
            }
        } else {
            toast.error(resp?.data?.respdesc)
        }
    } catch (error) {
        if (error?.response) {
            toast.error(error?.response?.data?.respdesc);
            yield put(loaderActionReq(false))
        }
    } finally {
        yield put(loaderActionReq(false))

    }
}
const slkServices = (model) => {
    const URL = "credits/slk_service"
    return Axios.post(URL, model).then((res) => {
        return res
    })
};

function* slkServicesSaga(action) {
    yield put(loaderActionReq(true))
    try {
        const resp = yield call(slkServices, action?.payload?.model)
        // if (resp?.data?.respcode === 200) {
        if (action?.payload?.callback) {
            action?.payload?.callback(resp)
            yield put(loaderActionReq(false))
        }
        // } else {
        //     toast.error(resp?.data?.respdesc)
        // }
    } catch (error) {
        if (error?.response) {
            toast.error(error?.response?.data?.respdesc);
            yield put(loaderActionReq(false))
        }
    } finally {
        yield put(loaderActionReq(false))

    }
}


export default function* CreditsWatcherSaga() {
    yield takeEvery(ADD_CREDITS, AddCreditsSaga)
    yield takeEvery(GET_CREDITS, getCreditsSaga)
    yield takeEvery(GENERATE_SLK, generateSLKSaga)
    yield takeEvery(ACTIVATE_SLK, activateSLKSaga)
    yield takeEvery(DEACTIVATE_SLK, deactivateSLKSaga)
    yield takeEvery(TRANSFER_CREDITS, transferCreditsSaga)
    yield takeEvery(SHOW_ADMIN_CREDITS, showAdminCreditsSaga)
    yield takeEvery(SUPER_ADMIN_TRANSFER_CREDITS, superAdminTransferCreditsSaga)
    yield takeEvery(GET_SLK_DETAILS, getSLkDetailsSaga)
    yield takeEvery(SLK_SERVICES, slkServicesSaga)

}